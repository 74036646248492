import React from 'react';
import { Text,View } from "react-native";
import { TouchableOpacity } from "react-native";
import { COLORS } from '../../../../constants/colorPallete';

const QuadRings=(props)=>{
    let width=props?.size;
    let height=props?.size;
    return(
        <View>
            <View style={{transform:[{rotate:'28deg'},{scaleX:2}],width,height,borderRadius:props?.size/2,borderWidth:0.5,borderColor:COLORS.orange,justifyContent:'center',alignItems:'center'}}/>           
           <View style={{transform:[{rotate:'18deg'},{scaleX:2}],width,height,position:'absolute',zIndex:-1,borderRadius:props?.size/2,borderWidth:0.5,borderColor:COLORS.LIGHTPINK1,justifyContent:'center',alignItems:'center'}}/>
           <View style={{transform:[{rotate:'-28deg'},{scaleX:2}],width,height,position:'absolute',borderRadius:props?.size/2,borderWidth:0.5,borderColor:COLORS.LIGHTPINK1,justifyContent:'center',alignItems:'center'}}/>           
           <View style={{transform:[{rotate:'-18deg'},{scaleX:2}],width,height,position:'absolute',zIndex:-1,borderRadius:props?.size/2,borderWidth:0.5,borderColor:COLORS.orange,justifyContent:'center',alignItems:'center'}}/>
        </View>
    )
}
export default QuadRings;