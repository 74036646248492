import React, { Fragment, useEffect, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import CustomPopUp from "./../../commonComponents/customPopUp/customPopUp";

import Modal from "react-native-modal";
import { navigateAction } from "../../helpers/navigationHelpers";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";
import { LogoutApi } from "../../redux/actions/auth";
import { LinearGradient } from "expo-linear-gradient";
import getPlatformType from "./../../helpers/getPlatform";
import { COLORS } from "../../constants/colorPallete.js";
import { DrawerIcon, DrawerMenu, DrawerWrapper, MenuItem, SubMenuItem } from "./drawerStyle";
import SocialIcons from "../../commonComponents/footerComponentLanding/socialIcons";
import { useTheme } from "@emotion/react";
import { ODSIcon } from "../../../openbrix-design-system/ui/atoms/ODSIcon";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import Portal from "../../../openbrix-design-system/ui/atoms/ODSModal/potral";

const layoutType = getPlatformType();
const CustomDrawerModal = (props) => {
  const [popUpIsVisible, setpopUpIsVisible] = useState(false);
  const [deletePopUpIsVisible, setDeletePopUpIsVisible] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);

  const [tdsRoute, setTdsRoute] = useState("tdsLocation");
  const { colors } = useTheme();

  const {
    data,
    borderColor,
    navigation,
    token,
    isLanding,
    profile,
  } = props;
  const tdsLocationSelected = useSelector((state) => state.appReducer?.tdsLocationSelected);

  useEffect(() => {
    if (profile && profile?.data) {
      if (profile?.data?.tdkToken?.user_id) {
        setTdsRoute("Custodial Tenancies");
      } else {
        setTdsRoute(tdsLocationSelected ? tdsLocationSelected : "tdsLocation");
      }
    } else {
      setTdsRoute(tdsLocationSelected ? tdsLocationSelected : "tdsLocation");
    }
  }, [profile]);
  console.log("profile?.data",profile)
  return (
    <Portal>
      <Modal
        isVisible={props.drawerState}
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInTiming={1000}
        animationOutTiming={1000}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() => props.setDrawerState(false)}
        style={{
          margin: 0,
          width: 332,
          marginLeft: 'auto'
        }}
      >
        <View
          style={{ flex: 1 }}
        // onPress={() => props.setDrawerState(false)}
        >
          <DrawerWrapper>
            <LinearGradient
              colors={borderColor ? [borderColor, borderColor] : token ? ['#fff', '#fff'] : ["#FBB1E6", COLORS.orange]}
              style={{
                width: 332,
                minHeight: "100%",
                paddingBottom: 50,
              }}
              start={[0, 0.3]}
              end={[1, 0.7]}
            >
              <DrawerIcon>
                <TouchableOpacity onPress={() => props.setDrawerState(false)}>
                  <ODSIcon icon="TB-Close" size={32} color={'#3F4D5A'} />
                </TouchableOpacity>
              </DrawerIcon>
              {isLanding ? (
                <DrawerMenu>
                  <MenuItem
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "Sign in");
                    }}
                  >
                    Login
                  </MenuItem>
                  <MenuItem
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "landingSearch");
                    }}
                  >
                    Home
                  </MenuItem>
                  <MenuItem
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "landingTlyfe");
                    }}
                  >
                    tlyfe
                  </MenuItem>
                  <MenuItem
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "landingOpenflo");
                    }}
                  >
                    Openflo
                  </MenuItem>
                  {layoutType === "web" ? null : <Fragment>
                    <MenuItem
                      onPress={() => {
                        props.setDrawerState(false);
                        navigateAction(navigation, "About Us");
                      }}
                    >
                      About us
                    </MenuItem>
                    <MenuItem
                      onPress={() => {
                        props.setDrawerState(false);
                        navigateAction(navigation, "MediaHub");
                      }}
                    >
                      Info Hub
                    </MenuItem>
                    <MenuItem
                      onPress={() => {
                        props.setDrawerState(false);
                        navigateAction(navigation, "Legal Bits");
                      }}
                    >
                      T &amp; C's
                    </MenuItem>
                    <MenuItem
                      onPress={() => {
                        props.setDrawerState(false);
                        navigateAction(navigation, "Charity");
                      }}
                    >
                      Charity
                    </MenuItem>
                  </Fragment>}
                </DrawerMenu>
              ) : token ? (
                <DrawerMenu>
                  <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD' }} />
                  <TouchableOpacity onPress={() => setShowDashboard(!showDashboard)} style={{ marginVertical: 24, paddingHorizontal: 24, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSIcon icon='TB-House' size={24} color={colors.elements.color} />
                      <ODSText type="md-bold" marginLeft={12} color={colors.text.primary}>My Dashboard</ODSText>
                    </View>
                    <ODSIcon icon={showDashboard ? "TB-Caret-Up" : "TB-Caret-Down"} size={22} color={'#3F4D5A'} />
                  </TouchableOpacity>
                  {showDashboard &&
                    <View style={{ gap: 24, paddingHorizontal: 24, }}>
                      <TouchableOpacity
                        onPress={() => {
                          props.setDrawerState(false);
                          navigateAction(navigation, "PreQualification");
                        }}
                      >
                        <ODSText type="md-bold" color={colors.text.primary}>Rent Ready</ODSText>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          props.setDrawerState(false);
                          navigateAction(navigation, "Document Share Center");
                        }}
                      >
                        <ODSText type="md-bold" color={colors.text.primary}>Doc & Share Centre</ODSText>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          props.setDrawerState(false);
                          navigateAction(navigation, "Credit Builder");
                        }}
                      >
                        <ODSText type="md-bold" color={colors.text.primary}>Credit Builder</ODSText>
                      </TouchableOpacity>
                      <TouchableOpacity

                        onPress={() => {
                          props.setDrawerState(false);
                          navigateAction(navigation, tdsRoute);
                        }}
                      >
                        <ODSText type="md-bold" color={colors.text.primary}>Tenancy Deposit</ODSText>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{ marginBottom: 24 }}
                        onPress={() => {
                          props.setDrawerState(false);
                          navigateAction(navigation, "Marketplace");
                        }}
                      >
                        <ODSText type="md-bold" color={colors.text.primary}>Marketplace</ODSText>
                      </TouchableOpacity>
                    </View>}
                  <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD' }} />
                  <TouchableOpacity onPress={() => {
                    props.setDrawerState(false);
                    navigateAction(navigation, "MediaHub")
                  }} style={{ marginVertical: 24, paddingHorizontal: 24 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSIcon icon='TB-Info-Outline' size={24} color={colors.elements.color} />
                      <ODSText type="md-bold" marginLeft={12} color={colors.text.primary}>Info Hub</ODSText>
                    </View>
                  </TouchableOpacity>
              { profile?.isActiveOpenflow ?  <>
                  <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD' }} />
                  <TouchableOpacity
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "My Tenancy Home")
                    }}
                    style={{ marginVertical: 24, paddingHorizontal: 24 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSIcon icon='TB-Move-In' size={24} color={colors.elements.color} />
                      <ODSText type="md-bold" marginLeft={12} color={colors.text.primary}>My Tenancy</ODSText>
                    </View>
                  </TouchableOpacity>
                  </>:null}
                 
                  <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD' }} />
                  <TouchableOpacity
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "landingSearch")
                    }}
                    style={{ marginVertical: 24, paddingHorizontal: 24 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSIcon icon='TB-Search' size={24} color={colors.elements.color} />
                      <ODSText type="md-bold" marginLeft={12} color={colors.text.primary}>Property Search</ODSText>
                    </View>
                  </TouchableOpacity>
                  <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD' }} />
                  <TouchableOpacity onPress={() => {
                    props.setDrawerState(false);
                    navigateAction(navigation, "My Account")
                  }} style={{ marginVertical: 24, paddingHorizontal: 24 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSIcon icon='TB-User-Circle' size={24} color={colors.elements.color} />
                      <ODSText type="md-bold" marginLeft={12} color={colors.text.primary}>My Account</ODSText>
                    </View>
                  </TouchableOpacity>
                  <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD' }} />
                  <TouchableOpacity onPress={() => {
                    setpopUpIsVisible(true);
                  }} style={{ marginVertical: 24, paddingHorizontal: 24 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSIcon icon='TB-Logout' size={24} color={colors.feedback.error[500]} />
                      <ODSText type="md-bold" marginLeft={12} color={colors.feedback.error[500]}>Logout</ODSText>
                    </View>
                  </TouchableOpacity>
                  <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD', }} />

                  <TouchableOpacity
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, 'Contact Us')
                    }}
                    style={{ marginVertical: 8,marginTop:20}}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSText type="sm" marginLeft={12} color={colors.text.primary}>
                        Contact Us
                      </ODSText>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, 'Legal Bits',{to:"policy"})
                    }}
                    style={{ marginVertical: 8, }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSText type="sm" marginLeft={12} color={colors.text.primary}>
                        Privacy Policy
                      </ODSText>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, 'Legal Bits',{to:"t/c"})
                    }}
                    style={{ marginVertical: 8,}}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <ODSText type="sm" marginLeft={12} color={colors.text.primary}>
                        T&C
                      </ODSText>
                    </View>
                  </TouchableOpacity>
                </DrawerMenu>
              ) : (
                <DrawerMenu>
                  <MenuItem
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "tlyfe - powered by OpenBrix");
                    }}
                  >
                    Search
                  </MenuItem>
                  <MenuItem
                    onPress={() => {
                      props.setDrawerState(false);
                      navigateAction(navigation, "Sign in");
                    }}
                  >
                    Register/Login
                  </MenuItem>
                </DrawerMenu>
              )}
            </LinearGradient>
          </DrawerWrapper>
        </View>
        <CustomPopUp
          loading={popUpIsVisible}
          primaryButtonText={"Sure"}
          secondaryButtonText={"cancel"}
          primaryText={"Logout?"}
          secondaryText={" Are you sure you want to logout?"}
          onPrimaryButtonPress={() => {
            props.setDrawerState(false);
            setpopUpIsVisible(false);
            props.LogoutApi();
            navigateAction(navigation, "Sign in");
          }}
          onSecondaryButtonPress={() => {
            setpopUpIsVisible(false);
          }}
        />
        <CustomPopUp
          loading={deletePopUpIsVisible}
          primaryButtonText={"Sure"}
          secondaryButtonText={"cancel"}
          primaryText={"Delete Account?"}
          secondaryText={" Are you sure you want to delete your account?"}
          onPrimaryButtonPress={() => {
            props.setDrawerState(false);
            setDeletePopUpIsVisible(false);
            props.LogoutApi(true);
            navigateAction(navigation, "Sign in");
          }}
          onSecondaryButtonPress={() => {
            setDeletePopUpIsVisible(false);
          }}
        />

      </Modal>
    </Portal>
  );
};
const mapStateToProps = (state) => ({
  token: idx(state, (_) => _.appReducer.loginData.data.token),
  profile: idx(state, (_) => _.authReducer.profile.data),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      LogoutApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomDrawerModal);
