import React from "react";
import styled from "styled-components/native";
import getScreenType from "../../../../openbrix-design-system/utils/getScreenType";

const layoutType = getScreenType();
let Container,Left,Right,Center;
if (layoutType == "phone") {
    Container = styled.View`
    width: 100%;
    bottom:0;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding-horizontal:20px;
    padding-vertical:40px;

    flex-wrap:wrap;
    
    `;
    Left=styled.View`
    width:100%;
    `;
    Center=styled.View`
    width:100%;
    margin-top:40px;
    `;
    Right=styled.View`
    width:100%;
    margin-top:40px;
    `;
} else if (layoutType == "web") {

Container = styled.View`
width: 100%;
bottom:0;
flex-direction:row;
justify-content:space-evenly;
// align-items:center;
padding:40px;
flex-wrap:wrap;
`;
Left=styled.View`
width:366px;
`;
Center=styled.View`
width:251px;
`;
Right=styled.View`
width:535px;
`;

} else {
Container = styled.View`
width: 100%;
bottom:0;
flex-direction:row;
justify-content:space-between;
align-items:center;
padding:40px;
flex-wrap:wrap;

`;
Left=styled.View`
width:400px;
`;
Center=styled.View`
width:251px;
`;
Right=styled.View`
width:100%;
margin-top:40px;
`;
  
  
}

export {
 Container,
 Left,
 Right,
 Center
};
