import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Entypo } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, View, Image, Linking } from 'react-native';

import { ServiceCard } from '../../atoms/serviceCard';
import RequestDocs from '../../molecules/requestDocs';
import { MainSection, Typography } from '../../style';
import { COLORS } from '../../../../constants/colorPallete';
import { BoxShadow, LineSaperator } from '../../../tds/style';
import getPlatformType from '../../../../helpers/getPlatform';
import WideButton from '../../../../commonComponents/wideButton';
import { tenantActions } from '../../openFlowRedux/tenant.actions';
import { getFullReferencingVerificationStatus } from '../../helpers';
import FilePicker from '../../../../commonComponents/filePicker/filepicker.web';
import { ODSText } from '../../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../../../openbrix-design-system/ui/atoms/ODSButton';
import ODSdocumentCard from '../../../../../openbrix-design-system/ui/molecules/propertyBox/ODSdocumentCard';
import { getTokens } from '../../../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSModal } from '../../../../../openbrix-design-system/ui/atoms/ODSModal/ODSModal.web';
import { useTheme } from '@emotion/react';
import { ODSIcon } from '../../../../../openbrix-design-system/ui/atoms/ODSIcon';

const layoutType = getPlatformType();

export const AccordianCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const { title, status, comments, lastUpdate } = props;
  const getStatus = (status) => {
    switch (status) {
      case 'COMPLETE':
        return {
          uri: require('./completed.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      case 'NOT STARTED':
        return {
          uri: require('./notStarted.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      case 'N/A':
        return {
          uri: require('./notStarted.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      case 'PENDING':
        return {
          uri: require('./inProgress.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      default:
        return {
          uri: require('./inProgress.png'),
          styles: { width: 20, height: 20 },
        };
        break;
    }
  };

  return (
    <React.Fragment>
      <LineSaperator color={'#D9D9D9'} />
      <View style={{ flexDirection: 'row', height: 70 }}>
        <View
          style={{
            flex: 0.3,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Image
            source={getStatus(status).uri}
            resizeMode="contain"
            style={getStatus(status.toProperCase()).styles}
          />
          <ODSText marginTop={6} type={'xs'}>
            {status.toProperCase()}
          </ODSText>
        </View>
        <View style={{ flex: 0.5, justifyContent: 'center' }}>
          <ODSText marginTop={6} marginLeft={6} type={'md'}>
            {title}
          </ODSText>
        </View>
        <TouchableOpacity
          onPress={() => {
            setIsOpen(!isOpen);
          }}
          style={{ flex: 0.2, justifyContent: 'center', alignItems: 'center' }}
        >
          <Entypo name={isOpen ? 'chevron-up' : 'chevron-down'} size={23} color={COLORS.LGREEN} />
        </TouchableOpacity>
      </View>
      {isOpen ? (
        <View style={{ marginHorizontal: 25 }}>
          <ODSText marginTop={16} type={'button'}>
            Status
          </ODSText>
          <ODSText marginTop={6} type={'sm'}>
            {status.toProperCase()}
          </ODSText>

          <ODSText marginTop={16} type={'button'}>
            Comments
          </ODSText>
          <ODSText marginTop={6} type={'sm'}>
            {comments}
          </ODSText>

          <ODSText marginTop={16} type={'button'}>
            Last update:
          </ODSText>
          <ODSText marginBottom={10} marginTop={6} type={'sm'}>
            {lastUpdate}
          </ODSText>
        </View>
      ) : null}
    </React.Fragment>
  );
};
const UploadRef = (props) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [showButton, setButton] = useState(true);
  const [currentTenant, setCurrentTenant] = React.useState({});
  const [referencingDocuments, setReferencingDocuments] = React.useState([]);
  const [showModal,setShowModal]=useState(false) ; 
  const themeObj = getTokens('light').colors;
  const {colors}=useTheme();

  useEffect(() => {
    if (props.currentTenant) {
      setCurrentTenant(props.currentTenant);
      let documents = props.currentTenant?.open_flow_verifications;
      if (documents && documents.length > 0) {
        documents = documents.filter((document) => {
          return document.type_of_document == 'referencing';
        });
        setReferencingDocuments(documents);
      } else {
        setReferencingDocuments([]);
      }
    }
  }, [props.currentTenant]);

  const modifyDate = (date) => {
    return date.substr(0, 10).split('-').join('/');
  };

  const openReferencingLink = async (url, id) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const renderDetailsCard = () => {
    return (
      <View style={{ paddingHorizontal: layoutType == 'phone' ? 10 : 20 }}>
        <ODSText marginTop={10} type={'h5'}>
          Full Reference
        </ODSText>
        <ODSText marginTop={10} type={'sm'}>
          Please find below information related to you tenancy reference.
        </ODSText>

        <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'web' ? 300 : '100%',
            marginTop: 20,
          }}
        >
          <ODSButton
            restStyle={{ width: '100%' }}
            type="primary"
            disabled={
              getFullReferencingVerificationStatus(currentTenant?.full_referencing_status).Text ==
              'Not Started'
                ? true
                : false
            }
            onPress={() => {
              if (currentTenant?.fccReference?.url) {
                openReferencingLink(currentTenant?.fccReference?.url);
              }
            }}
          >
            {getFullReferencingVerificationStatus(currentTenant?.full_referencing_status).Text}
          </ODSButton>
        </View>

        <View style={{ flexDirection: 'row', marginTop: 26 }}>
          <View style={{ flex: 0.5 }}>
            <ODSText marginTop={6} type={'sm'}>
              Reference Handler:{' '}
            </ODSText>
          </View>
          <View style={{ flex: 0.5 }}>
            <ODSText marginTop={6} type={'button'}>
              {props?.latestUpdate?.handlerName ? props?.latestUpdate?.handlerName : null}
            </ODSText>
            <Typography
              top={'6px'}
              align={'left'}
              color={COLORS.BLACK}
              size={'14px'}
              bold={'700'}
            ></Typography>
          </View>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 6 }}>
          <View style={{ flex: 0.5 }}>
            <ODSText marginTop={6} type={'sm'}>
              Reference Handler:{' '}
            </ODSText>
          </View>
          <View style={{ flex: 0.5 }}>
            <ODSText marginTop={6} type={'button'}>
              {props?.latestUpdate?.handlerContactNumber
                ? props?.latestUpdate?.handlerContactNumber
                : null}
            </ODSText>
          </View>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 6 }}>
          <View style={{ flex: 0.5 }}>
            <ODSText marginTop={6} type={'sm'}>
              Ref:
            </ODSText>
          </View>
          <View style={{ flex: 0.5, marginBottom: 26 }}>
            <ODSText marginTop={6} type={'button'}>
              {currentTenant?.fccReference?.fcc_reference_id
                ? currentTenant?.fccReference?.fcc_reference_id
                : null}
            </ODSText>
          </View>
        </View>
        <AccordianCard
          title={'Employment'}
          status={
            props?.latestUpdate?.creditSearch?.status
              ? props?.latestUpdate?.creditSearch?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.creditSearch?.comments
              ? props?.latestUpdate?.creditSearch?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.currentEmployment?.last_updated
              ? moment(props?.latestUpdate?.currentEmployment?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Credit Search'}
          status={
            props?.latestUpdate?.creditSearch?.status
              ? props?.latestUpdate?.creditSearch?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.creditSearch?.comments
              ? props?.latestUpdate?.creditSearch?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.creditSearch?.last_updated
              ? moment(props?.latestUpdate?.creditSearch?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Living Status'}
          status={
            props?.latestUpdate?.currentLiving?.status
              ? props?.latestUpdate?.currentLiving?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.currentLiving?.comments
              ? props?.latestUpdate?.currentLiving?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.currentLiving?.last_updated
              ? moment(props?.latestUpdate?.currentLiving?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Tenant Search'}
          status={
            props?.latestUpdate?.tenantSearch?.status
              ? props?.latestUpdate?.tenantSearch?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.tenantSearch?.comments
              ? props?.latestUpdate?.tenantSearch?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.tenantSearch?.last_updated
              ? moment(props?.latestUpdate?.tenantSearch?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
      </View>
    );
  };
  const openUrl = (url) => {
    if (url) {
      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          if (url) {
            let ext = url.split('.').reverse()[0];
            if (ext === 'pdf') {
              window.open(
                // 'https://docs.google.com/viewerng/viewer?url='+
                url,
                '_blank'
              );
            } else {
              window.open(url, '_blank');
            }
          }
        } else {
        }
      });
    }
  };
  const renderUploadDocs = () => {
    return (
      <View style={{ paddingHorizontal: layoutType == 'phone' ? 10 : 20 }}>
        <ODSText marginTop={10} type={'h5'}>
          Upload docs
        </ODSText>

        <View style={{ flexDirection: 'column' }}>
          <View style={{ flex: 'none' }}>
            <ODSText marginTop={10} type={'button'}>
              Tenant Reference Company
            </ODSText>

            <ODSText marginTop={10} type={'sm'}>
              In this section you can submit document directly to the{' '}
              <ODSText color="#FC4850" type={'sm'}>
                tenant reference company ONLY.
              </ODSText>
            </ODSText>

            <ODSText marginTop={10} type={'button'}>
              Please note:{' '}
              <ODSText type={'sm'}>
                If you have been asked by the Agent to submit documentation, DO NOT use this
                section, please use the section Marked - "Letting Agent"
              </ODSText>
            </ODSText>
          </View>
          <View style={{ flex: 'none' }}>
            <View
              style={{
                marginTop: 10,
                flexDirection: 'column',
                marginBottom: 20,
              }}
            >
              {props?.requestedDocs && props?.requestedDocs.length ? (
                props?.requestedDocs?.map((e, index) => (
                  <RequestDocs
                    item={e}
                    index={index}
                    width={'100%'}
                    flowId={props.flowId}
                    tenant={currentTenant}
                    docLength={props?.requestedDocs?.length}
                    refreshApi={() => {
                      props?.onRefresh();
                    }}
                  />
                ))
              ) : (
                <ODSText type={'sm'}>
                  Upload boxes will appear here once fcc requests a document
                </ODSText>
              )}
            </View>
          </View>
        </View>
        <LineSaperator style={{ background: COLORS.LGREEN, height: 4 }} />

        <ODSText marginTop={10} type={'button'}>
          Letting Agent
        </ODSText>

        <View style={{ flexDirection: 'column' }}>
          <View style={{ flex: 'none' }}>
            <ODSText marginTop={10} type={'sm'}>
              In this section you can submit document directly to the{' '}
              <ODSText color="#FC4850" type={'sm'}>
                Letting Agent ONLY.
              </ODSText>
            </ODSText>
            <ODSText marginTop={10} type={'button'}>
              Please note:
              <ODSText type={'sm'}>
                If you have been asked by the Tenant Reference Company to submit documentation, DO
                NOT use this section, please use the section Marked - "Tenant Reference Company"
              </ODSText>
            </ODSText>
          </View>
          <View style={{ flex: 'none' }}>
            <View
              style={{
                marginTop: 20,
                flexDirection: 'row',
                marginBottom: 20,
                width: '100%',
              }}
            >
              <FilePicker
                fileName={fileName}
                width={'100%'}
                accept={'application/pdf'}
                file={file}
                onFileSelect={(val) => {
                  setFile(val.name);
                  setFileName(val.openbrixName);
                }}
              />
            </View>
            {fileName ? (
              <ODSButton
              restStyle={{ width: '100%',marginBottom:20 }}
              type="primary"
            
              onPress={() =>
                dispatch(
                  tenantActions.uploadReferenceDocs(
                    { documents: [{ document_url: fileName }] },
                    props.flowId,
                    (e) => {
                      setButton(e);
                      setFileName('');
                      props?.onRefresh();
                    }
                  )
                )
              }
            >
             Confirm & Upload
            </ODSButton>
            
            ) : null}
          </View>
        </View>
        {referencingDocuments
          ? referencingDocuments.map((e, index) => (
              <ODSdocumentCard
                title={'Referencing Doc ' + (index + 1)}
                description={modifyDate(e?.createdAt)}
                isDelete
                onPress={() => {
                  openUrl(e?.document_url);
                }}
                onDeletePress={() =>
                  dispatch(
                    tenantActions.deleteRefDocument({ document_url: e?.document_url }, null, () => {
                      props.onRefresh();
                    })
                  )
                }
                style={{ marginBottom: 10 }}
              />
            ))
          : null}
      </View>
    );
  };
  const renderOpenBankingRequest=()=>{
    return(
      <BoxShadow style={{ alignSelf: 'center',marginTop:20 }} tWidth={'70%'} wWidth={'620px'} bottom={'10px'} right={'0px'}>
      <>
        <ODSText color={colors.text.primary} style={{ alignSelf: 'center' }} type={'h5'}>
        Open Banking Request
        </ODSText>
        <ODSText color={colors.text.primary} marginTop={20} style={{ alignSelf: 'center' }} type={'md-bold'}>
        Tenant Reference Company
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={14}
          style={{ alignSelf: 'center' }}
          type={'md'}
        >Your reference company has made a request for open banking to facilitate the reference process.
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={10}
          style={{ alignSelf: 'center' }}
          type={'md'}
        >
          For more information about Open Banking please<TouchableOpacity onPress={()=>setShowModal(true)}> <ODSText
color={themeObj.neutral[700]}
style={{ alignSelf: 'center' }}
type={'md-bold'}

> click here</ODSText></TouchableOpacity>

        </ODSText>
        <ODSButton
                  lIcon={'TB-Arrow-Right'}
                  themeColor={themeObj.main.green}
                  restStyle={{ marginTop: 20, alignSelf:'center' }}
                  type="primary"
                  disabled={false}
                  onPress={() => window.open(props?.openbankingURL,"_blank")}
                >
                 Start Open Banking
                </ODSButton>
      </>
  </BoxShadow>
    )
  }

  return (
    <MainSection
      style={{
        flexDirection: layoutType == 'phone' ? 'column' : 'row',
        justifyContent: 'space-between',
        marginTop:0
      }}
    >
      <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
        <BoxShadow style={{ padding: 10 }}>{renderDetailsCard()}</BoxShadow>
        {props?.openbankingURL&&renderOpenBankingRequest()}
      </View>
      <View style={layoutType == 'phone' ? { flex: 1, marginTop: 26 } : { flex: 0.48 }}>
        <BoxShadow style={{ padding: 10 }}>{renderUploadDocs()}</BoxShadow>
      </View>
      <ODSModal
          visible={showModal}
          backdropOpacity={0}
          onBackdropPress={() => setShowModal(false)}
        >
          <View
            style={{
              width: layoutType == 'phone' ? '95%' : '60%',
              height: layoutType == 'phone' && 680,
              alignSelf: 'center',
              backgroundColor: 'white',
              padding: 24,
              borderRadius: 12,
              shadowColor: '#101828',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.1,
              shadowRadius: 4,
              elevation: 2,
            }}
          >
      <>
      <TouchableOpacity style={{ alignSelf: 'flex-end' }} onPress={() => setShowModal(false)}>
                            <ODSIcon size={24} icon="TB-Close" />
                        </TouchableOpacity>
        <ODSText color={colors.text.primary} style={{ alignSelf: 'center' }} type={'h5'}>
        Open Banking
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={14}
          style={{ alignSelf: 'center' }}
          type={'sm'}
        >{`To verify your income and as an alternative to uploading bank statements you can give us access to open banking as a secure expedient way of enabling you to provide your up to date financial data instantly in regards to your tenancy application.
What is Open Banking? Open Banking through FCC Paragon will speed up the process of your tenant referencing. It’s safe, secure, and instant and therefore will help to avoid delays.

Sharing a snapshot of your current financial account history is the same as providing bank statements to us to assess, only easier, faster and safer. This will enable us to verify your income and transactions.

You don’t have to manually upload your bank statements/digital copies saving you time and hassle, and at the same time, it can be done anywhere you have your mobile device with you.

Once you have consented the data is instantly available to FCC Paragon to access and assess in relation to your tenancy application.

Can you trust the service? Simply put, yes you can. FCC Paragon is licensed with Consents.Online an Equifax company to carry out such checks and are also registered with the ICO and the FCA as a regulated company. Consents.Online an Equifax company is licenced and authorised by the FCA (Financial Conduct Authority).`}
        </ODSText>
      </>
      
          </View>
        </ODSModal>
    </MainSection>
  );
};
export default UploadRef;
