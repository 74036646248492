import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageBackground, Text, View, Image } from 'react-native';

import { ListItem } from '../atoms/listitem';
import RentalPortal from '../../rentalPortal';
import { COLORS } from '../../../constants/colorPallete';
import { getProfile } from '../../../redux/actions/auth';
import getPlatformType, { getImage } from '../../../helpers/getPlatform';
import BackButton from '../../../commonComponents/BackButton';
import CircularProgress from '../../../commonComponents/circle';
import { tenantActions } from './../openFlowRedux/tenant.actions';
import { navigateAction } from '../../../helpers/navigationHelpers';
import { CircularWrapper } from '../../identifyAndFinance/stylesNew';
import { MainContainer, PropertyDetailsSection, Wrapper } from './home.styles';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import HomeBoxCard from '../../identifyAndFinance/HomeBox';
import { StyleSheet } from 'react-native';
import { Typography } from '../../tds/style';
import IdRef from '../../../assets/images/idRef.png';
import WideButton from '../../../commonComponents/wideButton';
import { callIfAvailable, sendEmail } from '../../../helpers/callEmailHelper';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';
import { ChildrenView } from '../../idVerification/styles';
import { Dimensions } from 'react-native';
import PropertyBox from '../../../../openbrix-design-system/ui/molecules/propertyBox';
import RentSetting from '../../../../openbrix-design-system/ui/molecules/rentSetting';
import OpenflowCard from '../../../../openbrix-design-system/ui/molecules/propertyBox/openflowCard';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';

const layoutType = getPlatformType();
const WIN_HEIGHT = Dimensions.get('window').height;
const ListArray = [
  {
    title: 'Tenant Details',
    buttonStatus: 'tenancy_status',
    routeKey: 'TenantDetails',
    desc: 'Are your details correct and complete?',
    id: 'tenantDetails',
    listIcon:"TB-User-Circle",
  },
  {
    title: 'Landlord & Property Document',
    buttonStatus: 'doc_status',
    routeKey: 'PropertyDocs',
    desc: 'Documentation to read and retain',
    id: 'landlord-PropertyDocument',
    listIcon: "doc",
  },
  {
    title: 'Rental Offer',
    buttonStatus: 'rental_offer_status',
    routeKey: 'RentalOffers',
    desc: 'Breakdown of your rental offer',
    id: 'rentalOffer',
    listIcon: "rentaloffer",
  },
  {
    title: 'Holding Deposit',
    buttonStatus: 'holding_deposit_status',
    routeKey: 'HoldingDeposit',
    desc: 'Holding deposit details and terms may be view here',
    id: 'holdingDeposit',
    listIcon: "pound",
  },
  {
    title: 'ID & Referencing',
    buttonStatus: 'verification_status',
    routeKey: 'IdAndReference',
    desc: 'Verify ID and begin the reference process.',
    id: 'id-Referncing',
    listIcon:"idref",
  },
  {
    title: 'Tenancy Agreement',
    buttonStatus: 'landlord_status',
    routeKey: 'ReviewAST',
    desc: 'Digitally sign your tenancy agreement and send it back to your agent.',
    id: 'tenancyAgreement',
    listIcon:"agrement",
  },
  {
    title: 'Move in',
    buttonStatus: 'move_in_status',
    routeKey: 'DepositInfo',
    desc: "Breakdown of your 'move in monies'",
    id: 'moveIn',
    listIcon: "movein",
  },
  {
    title: 'Deposit Protect & Home Setup',
    buttonStatus: 'property_status',
    routeKey: 'DepositProtection',
    desc: 'Set up your new home.',
    id: 'depositProtect-HomeSetUp',
    listIcon: "depositprotect",
  },
];

const Home = (props) => {
  const { navigation } = props;
  const dispatch = useDispatch();
  const [flowId, setFlowId] = React.useState(null);
  const [flowIdInfo, setFlowIdInfo] = React.useState(null);
  const globalState = useSelector((state) => state?.tenant);
  const profile = useSelector((state) => state.authReducer.profile);
  const authDataFromMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);

  useEffect(() => {
    dispatch(tenantActions.getFlowInfo());
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    setFlowId(globalState?.flowIdInfo?.openflow_id);
    setFlowIdInfo(globalState?.flowIdInfo);
  }, [globalState]);

  const emailAgent = async () => {
    let mailId = flowIdInfo?.branch?.email ? flowIdInfo?.branch?.email : 'Info@openbrix.co.uk';
    let mailTitle = 'Message from tlyfe openflo tenant';
    let mailBody = `Dear ${flowIdInfo?.branch?.name},
        
        I am currently using your tenant move in process and would like to discuss the following points.
    
    
        [Add your points here]
        
        
        Kind regards,
        ${profile?.data?.first_name + ' ' + profile?.data?.last_name}`;
    let url = `mailto:${mailId}?subject=${encodeURIComponent(mailTitle)}&body=${encodeURIComponent(
      mailBody
    )}`;
    window.open(url, '_blank');
  };
  const callAgent = async (x) => {
    let status = false;
    if (x) {
      callIfAvailable(x);
      // status = await callIfAvailable(x);
      // if (!status) {
      //   dispatch(showToaster("error", "Sorry, agent's contact unavailable"));
      // }
    } else if (!status) {
      dispatch(showToaster('error', "Sorry, agent's contact unavailable"));
    }
  };
  const renderCallButton = () => {
    return (
      <View style={{ flexDirection: layoutType != 'phone' ? 'row' : 'column', marginTop: 20 }}>
        <View
          style={
            layoutType != 'phone'
              ? {
                  flex: 1,
                  paddingRight: 6,
                }
              : { flex: 0.5, marginBottom: 10 }
          }
        >
          <WideButton
            childText={'Call Agent'}
            buttonType={'solid'}
            gradiant
            isLGreen
            
          />
        </View>
        <View
          style={
            layoutType != 'phone'
              ? {
                  flex: 1,
                  paddingLeft: 6,
                }
              : { flex: 0.5 }
          }
        >
          <WideButton
            childText={'Email Agent'}
            buttonType={'solid'}
            gradiant
            onPress={() =>{}}
            isLGreen
          />
        </View>
      </View>
    );
  };
  const renderPropertyBox = () => (
    <View style={styles.imageBackgroundContentContainer}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#FFF',
          borderRadius: 8,
          shadowColor: '#000',
          shadowOffset: { width: 1, height: 3 },
          shadowOpacity: 0.15,
          shadowRadius: 8,
          elevation: 5,
        }}
      >
        <Image
          source={{
            uri: flowIdInfo?.property?.property_images?.length
              ? flowIdInfo?.property?.property_images[0]
              : 'http://assets.openbrix.co.uk/certificate-doc-oWHno-1663268711051-0.png',
          }}
          resizeMode="cover"
          style={styles.floBackg}
        />

        <View style={{ padding: 10, marginTop: 10, flex: 6 }}>
          <Typography align={'left'} size={'24px'} color={COLORS.LGREEN}>
            {flowIdInfo?.property?.property_name ? flowIdInfo?.property?.property_name : ''}
          </Typography>
          <Typography align={'left'} size={'18px'} color={COLORS.BLACK}>
            {flowIdInfo?.property?.display_address ? flowIdInfo?.property?.display_address : ''}
          </Typography>
        </View>
        <View style={{ flex: 2 }}>
          <CircularWrapper>
            <CircularProgress
              size={65}
              strokeWidth={2}
              progressPercent={
                flowIdInfo?.percentage_completed ? flowIdInfo?.percentage_completed : 0
              }
              pgColor={COLORS.GREEN}
              pointerColor={COLORS.GREEN}
              customText={
                flowIdInfo?.percentage_completed ? flowIdInfo?.percentage_completed + '%' : 0
              }
              textColor={'white'}
              textSize={'14'}
            ></CircularProgress>
          </CircularWrapper>
        </View>
      </View>
      {renderCallButton()}
    </View>
  );
  return (
    <BackGroundGenerator
      openFlowData={{
        title: '',
        desc: '',
        buttonText: '',
        message: '',
      }}
      isOpenflowContainer
      props={props}
      isTLYF
      navigation={navigation}
      isOpenflowHome
    >
      <View style={layoutType !=="web"?{flexDirection:"column"}:  {alignSelf:"center",flexDirection:"row"}}>
        <View style={layoutType !=="web"?{}: {width: 407}}>
      <PropertyBox
      onCallPress={() =>
        callAgent(
          profile?.data?.agentDetails?.branch?.contact_number
            ? profile.data.agentDetails.branch.contact_number
            : '0203 740 4721'
        )
      }
      agentName={ [flowIdInfo?.branch?.company?.name, flowIdInfo?.branch?.name]
        .filter(Boolean) // Removes undefined, null, and empty strings
        .join(", ")}
      onEmailPress={()=>{emailAgent()}}
      uri= {flowIdInfo?.property?.property_images?.length
      ? flowIdInfo?.property?.property_images[0]
      : 'http://assets.openbrix.co.uk/certificate-doc-oWHno-1663268711051-0.png'}
      name= {flowIdInfo?.property?.property_name ? flowIdInfo?.property?.property_name : ''}
      address={flowIdInfo?.property?.display_address ? flowIdInfo?.property?.display_address:""}
      />
      </View>
      {/* <View style={layoutType!=="web"?{marginTop:20}: {width:407,marginLeft:10}}>
      <RentSetting/>
      </View> */}
      </View>
      <View style={{alignSelf:"center",flexDirection:"row"}}>
      <ODSText textAlign={'center'} marginTop={30} marginBottom={30} color="black" type={'h5'}>
      Move in Progress
      </ODSText>
      </View>
      <View  style={
            layoutType == 'web'
              ? { alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: 1050 }
              : { alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }
          } >
         {ListArray.map((item, index) => (
          <View style={
            layoutType !== 'web'
            ? { width: '100%',marginBottom:20 }
            : {
                width: 500,
                margin: 10,
              }
          }>
           <OpenflowCard
              id={item.id}
              key={'lti' + index}
              onPress={() => {
                if (item.buttonStatus === 'property_status') {
                  navigateAction(navigation, item.routeKey, {
                    id: flowId,
                  });
                } else {
                  flowIdInfo[item?.buttonStatus] === 0
                    ? null
                    : navigateAction(navigation, item.routeKey, {
                        id: flowId,
                      });
                }
              }}
              type={flowIdInfo && flowIdInfo[item?.buttonStatus]}
              title={item.title}
              description={item.desc}
             
              listIcon={item.listIcon}
           
           />
           </View>
            // <ListItem
            //   id={item.id}
            //   key={'lti' + index}
            //   onPress={() => {
            //     if (item.buttonStatus === 'property_status') {
            //       navigateAction(navigation, item.routeKey, {
            //         id: flowId,
            //       });
            //     } else {
            //       flowIdInfo[item?.buttonStatus] === 0
            //         ? null
            //         : navigateAction(navigation, item.routeKey, {
            //             id: flowId,
            //           });
            //     }
            //   }}
            //   marginBottom={25}
            //   width={layoutType == 'phone' ? '100%' : '49.3%'}
            //   showArrowIcon
            //   showButton
            //   type={flowIdInfo && flowIdInfo[item?.buttonStatus]}
            //   title={item.title}
            //   description={item.desc}
            //   listIcon={item.listIcon}
            // />
          ))}
      </View>
    </BackGroundGenerator>
    // <OpenflowCard/>
    //{renderPropertyBox()}
    //<PropertyBox/>
    // <RentSetting/>
    // <BackGroundGenerator props={props} isTLYF>
    //   <ImageBackground
    //     source={IdRef}
    //     style={{
    //       width: '100%',
    //       height: '170px',
    //       alignItems: 'center',
    //       flexDirection: 'row',
    //       justifyContent: 'space-between',
    //     }}
    //     resizeMode="cover"
    //   >
    //     {authDataFromMobile ? null : (
    //       <BackButton
    //         styles={{ marginTop: 0 }}
    //         navigation={props.navigation}
    //         replace={'Dashboard'}
    //       />
    //     )}
    //   </ImageBackground>

    //   <MainContainer style={{position: "absolute",alignSelf: "center",top:"30%"}}>
    //     {renderPropertyBox()}
    //     <View style={{
    //       width: layoutType == "phone" ? "95%":layoutType == "tab" ? "90%": "1046px",
    //       background: "#ffffff",
    //       boxShadow: "1px 3px 8px rgba(0, 0, 0, 0.15)",
    //       borderRadius: "15px 15px",
    //       height:  layoutType == "phone" ?380 :330,
    //       marginBottom:20,
    //     }}>
    //       <HomeBoxCard
    //         postCodeFromTeancy={globalState?.flowIdInfo?.property?.postcode}
    //         profile={profile?.data}/>
    //     </View>
    //     <Wrapper>
    //       {ListArray.map((item, index) => (
    //         <ListItem
    //           id={item.id}
    //           key={'lti' + index}
    //           onPress={() => {
    //             if (item.buttonStatus === 'property_status') {
    //               navigateAction(navigation, item.routeKey, {
    //                 id: flowId,
    //               });
    //             } else {
    //               flowIdInfo[item?.buttonStatus] === 0
    //                 ? null
    //                 : navigateAction(navigation, item.routeKey, {
    //                     id: flowId,
    //                   });
    //             }
    //           }}
    //           marginBottom={25}
    //           width={layoutType == 'phone' ? '100%' : '49.3%'}
    //           showArrowIcon
    //           showButton
    //           type={flowIdInfo && flowIdInfo[item?.buttonStatus]}
    //           title={item.title}
    //           description={item.desc}
    //           listIcon={item.listIcon}
    //         />
    //       ))}
    //     </Wrapper>

    //   </MainContainer>
    // </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  cardStyle: {
    display: 'flex',
    backgroundColor: '#ffffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRadius: 10,
    marginHorizontal: 5,
  },
  contentContainer: {
    backgroundColor: '#FFF',
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
  },
  commonContainerStyles: {
    borderRadius: 16,
    paddingHorizontal: 10,
    paddingVertical: 6,
    flexDirection: 'row',
  },
  floIcon: {
    width: 30,
    height: 30,
    elevation: 5,
    display: 'flex',
    shadowRadius: 3.84,
    shadowOpacity: 0.25,
    shadowColor: '#000',
    tintColor: COLORS.LGREEN,
    shadowOffset: { width: 0, height: 2 },
    marginRight: 8,
  },
  progress: {},
  floBackg: {
    width: 100,
    height: 127,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  imageBackgroundContentContainer: {
    marginTop: 10,
    marginBottom: 30,
    width: layoutType == 'phone' ? '90%' : 449,
  },
});
export default Home;
