import React from "react";
import { FlatList, Image, View } from "react-native";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import Wrapper from "./Wrapper";
import YoutubePlayer from '../../commonComponents/youtubePlayer/youtubePlayer';
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/tlyfe-web.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/tlyfe-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/tlyfe-mobile.png');
const Picture = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/tlyfe-logo.png');
const FooterWeb = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-web.png');
const FooterTab = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-tab.png');
const FooterMobile = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-mobile.png');
const clouds = require('../../../openbrix-design-system/images/tlyfe-pages/hangingCloud.png');
const mockVideo = require('../../../openbrix-design-system/images/tlyfe-pages/tlyfe-video-mock-mobile.png');
const profile = require('../../../openbrix-design-system/images/icons/profile.png');
const sheild = require('../../../openbrix-design-system/images/icons/sheild.png');
const pound = require('../../../openbrix-design-system/images/icons/pound.png');
const face = require('../../../openbrix-design-system/images/icons/face.png');

const bank = require('../../../openbrix-design-system/images/icons/bank.png');
const chat = require('../../../openbrix-design-system/images/icons/chat.png');
const ins = require('../../../openbrix-design-system/images/icons/ins.png');
const market = require('../../../openbrix-design-system/images/icons/market.png');

const multiplecheck = require('../../../openbrix-design-system/images/icons/multiplecheck.png');

const FEATURED = [

  {
    name: "Marketplace",
    desc: "Explore services tailored for tenants.",
    icon: market,
  },
  {
    name: "Credit Builder",
    desc: "Enhance your credit score as you rent.",
    icon: bank,
  },
  {
    name: "Tenancy Deposit",
    desc: "View and manage your tenancy deposit with the TDS",
    icon: ins,
  },
  {
    name: "Information Centre",
    desc: "Access essential resources and support.",
    icon: chat
  },
];

const BenefitItem = ({ icon, title, description }) => (
  <View style={{ marginTop: 20, flexDirection: "row" }}>
    <Image style={{ height: 30, width: 30, marginRight: 10 }} resizeMode="contain" source={icon} />
    <View style={{ flexDirection: "column", flex: 1 }}>
      <ODSText type="button">{title}</ODSText>
      <ODSText type="sm" style={{ marginTop: 14 }}>{description}</ODSText>
    </View>
  </View>
);

const TlyfeproLanding = () => {
  const { colors } = useTheme();
  const layout = getScreenType();

  const headerImage = layout === "web" ? HeaderWeb : layout === "tab" ? HeaderTab : HeaderMobile;
  const footerImage = layout === "web" ? FooterWeb : layout === "tab" ? FooterTab : FooterMobile;

  const sectionStyles = layout === "phone"
    ? { flexDirection: "column", alignItems: "center", paddingHorizontal: "6%", marginBottom: 30 }
    : { flexDirection: "row", justifyContent: "center", paddingVertical: 100 };

  return (
    <Wrapper
      theme={colors.transparency.pink}
      drawerColor={colors.theme.pink}
      isOpenBrixLogo
      bodyStyle={{ alignSelf: "flex-start" }}
      type="login"
      imageStyle={layout == "phone" ? { height: 900, justifyContent: "flex-start", paddingTop: 100 } : { height: 500, justifyContent: "center", paddingTop: 20 }}
      headerTitle="Rent Smarter With The Tenant Lifecycle App, currently used by over 90,000 UK tenants!"
      headerBody="tlyfe has redefined the rental experience, making life as a tenant simpler and more efficient. As the UK’s number one tenant app, tlyfe empowers you to secure your preferred property with ease and manage your tenancy effortlessly."
      headerImage={headerImage}
      headerBlacFont
      rightImage={Picture}
      leftText={`Give yourself the best chance of securing your next rental property by using our Pre-qualification tool to become a ‘Rent Ready’ Tenant.\n\nShare the results with your Agent to put yourself ahead of the crowd!!`}
      footerImage={footerImage}
      tlyfeProMenu
    >
      <View style={{ position: "relative" }}>
        <Image source={require("../../../openbrix-design-system/images/tlyfe-pages/clouds.png")}
          style={{ width: "100%", height: 100, position: "absolute", top: -97 }} />
        <View style={{ ...sectionStyles, alignItems: "center" }}>
          <Image
            style={layout === "phone" ? { height: 100, width: 100 } : { height: 154, width: 154, marginRight: "2.2%" }}
            resizeMode="contain"
            source={Picture}
          />
          <View style={{ marginLeft: "2.2%" }}>
            <ODSText type="h3" color={colors.main.blue["500"]} textAlign={layout !== "phone" ? "flex-start" : "center"}>Key Benefits</ODSText>
            <BenefitItem icon={profile} title="Rent Ready Solution" description="The industry’s first, streamlining your rental processfrom start to finish." />
            <BenefitItem icon={pound} title="Affordability Calculator" description="Know exactly what you can afford with our intuitive tool." />
            <BenefitItem icon={multiplecheck} title="Right to Rent Checks" description="Ensure compliance with ease." />
            <BenefitItem icon={face} title="ID Verification" description="Secure and straightforward verification." />
            <BenefitItem icon={sheild} title="Verified Digital References" description="Present your credentials with confidence." />

          </View>
        </View>
        <View style={layout === "phone" ?
          { ...sectionStyles, paddingTop: 20, backgroundColor: colors.main.purple["500"], justifyContent: "center", alignItems: "center", flexDirection: "column-reverse" } :
          { ...sectionStyles, backgroundColor: colors.main.purple["500"], height: 422, justifyContent: "center", alignItems: "center" }}>
          {layout == "phone" ? null : <>
            <Image
              style={layout === "phone" ? { height: 100, width: 73, position: "absolute", top: 0, right: 20 } : { height: 100, width: 73, position: "absolute", top: 0, right: 20 }}
              source={clouds}
            />
            <Image
              style={layout === "phone" ? { height: 52, width: 41, position: "absolute", top: 0, left: 40 } : { height: 131, width: 108, position: "absolute", top: 0, left: 40 }}
              source={clouds}
            />
          </>}

          <View style={{ marginLeft: layout === "phone" ? "0%" : "2%", position: "relative" }}>
            <Image
              style={layout === "phone" ? { height: 241, width: 200, bottom: -30 } : { height: 435, width: 250, marginRight: "2%", bottom: -73 }}
              resizeMode="contain"
              source={mockVideo}
            />
          </View>
          <View style={layout === "phone" ? {} : { marginLeft: "2%", width: "31%" }}>
            <ODSText color="white" type="h3">Seamless Integration</ODSText>
            <ODSText color="white" marginTop={10} type="sm">
              By allowing tenants to pre-qualify themselves, tlyfe guarantees a smoother, faster, and more transparent rental journey. This innovative approach reduces the time and effort required to find a property, enhancing confidence for both tenants and agents.
            </ODSText>
          </View>
        </View>
        <View style={layout === "phone" ? { ...sectionStyles} : { ...sectionStyles }}>
          <View style={{ marginLeft: "2%", }}>
            <ODSText type="h3" color={"black"} textAlign={layout !== "phone" ? "center" : "center"}>Beyond Renting</ODSText>
            <FlatList
              data={FEATURED}
              numColumns={layout=="phone"?1:2}
              contentContainerStyle={{
              }}
              style={{
                width: "100%",
                marginTop: 30
              }}
              renderItem={({
                item,
                index,
              }) => (
                <View
                  style={layout=="phone"?{
                    flex: 1,
                    height: 200,
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    margin: 10,
                    backgroundColor: colors.main.blue["700"],
                  }: [
                    {
                      flex: 1,
                      height: 200,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      margin: 20,
                      backgroundColor: colors.main.blue["700"],
                    },
                  ]}
                  id={index}
                >
                  <Image
                    resizeMode={"contain"}
                    source={item.icon}
                    style={{
                      width: 200,
                      height: 97,
                    }}
                  />
                  <ODSText color="white" textAlign={"center"} marginTop={10} type="button">
                    {item.name}
                  </ODSText>
                  <ODSText color="white" textAlign={"center"} marginTop={6} marginBottom={20} type="sm">
                    {item.desc}
                  </ODSText>
                </View>
              )}
            />
          </View>
        </View>
        <View style={layout === "phone" ?
          { ...sectionStyles, paddingTop: 20, backgroundColor: colors.main.salmon["200"], justifyContent: "center", alignItems: "center", flexDirection: "column-reverse", marginBottom: 0, padding: 60 } :
          { ...sectionStyles, backgroundColor: colors.main.salmon["200"], justifyContent: "center", alignItems: "center", flexDirection: "row-reverse" }}>
          <Image source={require("../../../openbrix-design-system/images/tlyfe-pages/clouds.png")}
            style={{
              width: "100%", height: 100, position: "absolute",
              top: -3,
              transform: [{ rotate: '180deg' }]
            }} />
          <View style={{ marginLeft: layout === "phone" ? "0%" : "2%", position: "relative" }}>
            <View
              style={layout === "phone" ? { height: 400, width: 200, borderRadius: 20, paddingVertical: 20, paddingHorizontal: 10, backgroundColor: "black" } : {
                height: 422, width: 200, marginRight: "2%",
                borderRadius: 20, paddingVertical: 20, paddingHorizontal: 10, backgroundColor: "black"
              }}
            >
              <YoutubePlayer
                url={'https://www.youtube.com/watch?v=4T0oKyMI6wI&ab_channel=OpenBrix'}
                width="100%"
                height="100%"
                style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8, overflow: 'hidden' }}
              />
            </View>
          </View>
          <View style={layout === "phone" ? { marginBottom: 20 } : { marginLeft: "2%", width: "30%" }}>
            <ODSText color="black" textAlign={"flex-start"} type="h3">Experience the future of renting with tlyfe.</ODSText>
            <ODSText color="black" textAlign={"flex-start"} marginTop={10} type="sm">
              Simplify your rental journey and enjoy peace of mind, all in one app.
            </ODSText>
          </View>
        </View>
      </View>
    </Wrapper>
  );
};

export default TlyfeproLanding;
