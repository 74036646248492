import idx from "idx";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Entypo, FontAwesome5, MaterialIcons } from "@expo/vector-icons";

import { Link } from "@react-navigation/native";
import { Text, TouchableOpacity, View } from "react-native";
import WideButton from "../wideButton";
import getPlatformType from "../../helpers/getPlatform";
import { getProfile, LogoutApi } from "../../redux/actions/auth";
import DropDownPicker from "../../commonComponents/DropDownPicker";
import { FilterConfigObject } from "./../../helpers/objectHelpers";
import CustomPopUp from "./../../commonComponents/customPopUp/customPopUp";
import CustomDrawerModal from "../../navigation/customDrawer/customDrawerModal";
import { addNotification, onNotificationOpen } from "../../redux/actions/notification";
import { navigateAction, popAction, replaceAction } from "../../helpers/navigationHelpers";
import CustomNotificationModal from "../../components/Notification/customNotificationModal";
import {
  AlertCol,
  BackBtn,
  BackTextWrapper,
  BottomBar,
  Container,
  DrawerIcon,
  FilterCol,
  HeaderWrapper,
  LoginText,
  MainHeader,
  MainMenu,
  MenuItem,
  MenuItemActive,
  MyAccount,
  ObxLogo,
  ObxLogoWrapper,
  SearchText,
} from "./headerStyle";
import { COLORS } from "../../constants/colorPallete";
import { Typography } from "../../components/openFlow/style";
import { Hoverable } from "react-native-web-hooks";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import { ThemeContext } from "@emotion/react";
import { ODSIcon } from "../../../openbrix-design-system/ui/atoms/ODSIcon";

const { sort_by } = FilterConfigObject;

const layoutType = getPlatformType();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoute: props?.route?.name,
      drawerState: false,
      notificationState: false,
      popUpIsVisible: false,
      sortby: "&sortBy=property_price&order=DSC",
      ownIdView: false,
      userProfile: null,
      notificationList: [],
      notificationCount: 0,
      openDialog: false,
      authDataFromMobile: props?.authDataFromMobile,
      hoverStartTime: null,
      isDeleteAccount: true,
    };
  }

  componentDidMount = () => {
    if (this.props?.token) {
      this.props.getProfile();
      this.props.addNotification();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.userProfile != this.props?.profile?.data) {
      this.setState({
        userProfile: this.props?.profile?.data,
        authDataFromMobile: this?.props?.authDataFromMobile,
      });
    }
  };


  setDrawerState = (value) => {
    this.setState({
      drawerState: value,
    });
  };

  setNotificationDrawerState = (value) => {
    this.setState({ notificationState: value });
    if (!value && this.getNotificationCount(this.props?.data)) {
      this.props.onNotificationOpen();
    }
  };

  _NotificationModal = () => {
  };

  getNotificationCount = (data) => {
    if (data && data.length) {
      let unseenNotification = data.filter((data, index) => !data.is_viewed);
      return unseenNotification.length;
    }
    return null;
  };
  handleClick = (cb) => {
    Promise.resolve()
    .then(() => {
      this.setState({
        openDialog: false,
        ownIdView: false,
      });
    })
    .then(() => {
      if (cb) cb();
    });
  };
  renderNotificationIconMobile = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          this.setNotificationDrawerState(true);
        }}
      >
        {/* {this.getNotificationCount(this.props?.data) ? (
          <View
            style={{
              height: 18,
              width: 18,
              borderRadius: 10,
              top: "-94%",
              left: "50%",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              backgroundColor: "red",
            }}
          >
            <Text style={{ color: "white" }}>{this.getNotificationCount(this.props?.data)}</Text>
          </View>
        ) : null} */}
        {/* <DrawerIcon source={require("../../assets/images/notification.png")} resizeMode="contain"/> */}
      </TouchableOpacity>
    );
  };

  //commented out until tom or someone else can finish the backend for this
  handleHoverIn = () => {
    // this.setState({ hoverStartTime: Date.now() });
  };
  handleHoverOut = (type) => {
    // if (this?.state?.hoverStartTime) {
    //   const duration = (Date.now() - this?.state?.hoverStartTime) / 1000.0;
    //   this?.props?.appUserTrackingProductHover({
    //     app_user_id: this?.props?.userId,
    //     product: type,
    //     hover_time_seconds: duration,
    //     current_page: navigationRef.current.getCurrentRoute().name,
    //   });
    // }
    // this.setState({ hoverStartTime: null });
  };
  static contextType = ThemeContext;

  render() {
    const theme = this.context;
    const {colors}=theme;
    const {
      currentRoute,
      popUpIsVisible,
      notifications,
      userProfile,
      isDeleteAccount,
    } = this.state;
    const token = this.props.token;
    //
    // const { colors } = this.props.theme;

    const {
      showDrawerMob, // if drawerOption is to be shown on the header in mobile
      showDrawerTab, // if drawerOption is to be shown on the header in Tab

      navigation,
      headerColorMob, // color of the headerbar in mob
      headerColorTab, // color of the headerbar in tab
      headerColorWeb, // color of the headerbar in Web

      showLogoMob, // if OBX logo is to be shown in Mob
      showLogoTab, // if OBX logo is to be shown in Tab

      showLogoWeb, // if OBX logo is to be shown in Web

      pageTitleMob, // if you want to show page title in between in Mob
      pageTitleTab, // if you want to show page title in between in Tab
      pageTitleWeb, // if you want to show page title in between in Web

      rightChildLabelMob, // to render text component with touch action in the right for mobile
      rightChildLabelTab, // to render text component with touch action in the right for Tab
      rightChildLabelWeb, // to render text component with touch action in the right for Web

      headerButtonsMob, // to render button within the headerbar for mob
      headerButtonsTab, // to render button within the headerbar for Tab
      headerButtonsWeb, // to render button within the headerbar for Web

      textWithButtonMob, // the text along with header button for Mob
      textWithButtonTab, // the text along with header button for Tab
      textWithButtonWeb, // the text along with header button for Web
      drawerWithFilterMob,
      showBackButtonMob,
      showBackButtonTab,
      showBackButtonWeb,
      MenuWeb,
      replace,
      MenuAccountWeb,
      showClearAllButtonTab,
      showClearAllButtonMob,
      showClearAllButtonWeb,
      showMyAccountMob,
      showMyAccountTab,
      onBackAction,
      showNotification,
      isTLYF=true,
      isTds,
      authDataFromMobile,

    } = this.props;
    //
    // ─── MOBILE SPECIFIC HEADER ──────────────────────────────────────
    //
    const SELECTEDCOLOR = isTLYF ? colors.other['border-color'] : "#eb5936";
    if (layoutType == "phone") {
      return (
        <HeaderWrapper
          headerColor={headerColorMob}
          style={{
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.09,
            shadowRadius: 6.68,
            elevation: 11,
          }}
          noBottomMargin
          {...this.props}
        >
          <Container>
            <MainHeader>
              {showDrawerMob && (
                <TouchableOpacity onPress={() => this.setDrawerState(true)} style={{ zIndex: 100 }}>
                  <DrawerIcon
                    source={require("../../assets/images/drawerMenu.png")}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              )}

              {showBackButtonMob && (
                <BackTextWrapper
                  onPress={() => {
                    if (onBackAction) {
                      onBackAction();
                    } else {
                      if (replace) {
                        replaceAction(navigation, replace);
                      } else {
                        popAction(navigation, 1);
                      }
                    }
                  }}
                >
                  <Entypo
                    name="chevron-left"
                    size={18}
                    color="#000"
                    // style={{ verticalAlign: "bottom" }}
                  />{" "}
                  Back
                </BackTextWrapper>
              )}

              {showLogoMob &&
                (isTds ? (
                  <Link to="/my-dashboard">
                    <ObxLogo
                      source={require("../../assets/images/tds.png")}
                      resizeMode="contain"
                      style={{ marginLeft: 20 }}
                    />
                  </Link>
                ) : (
                  <ObxLogoWrapper
                    onPress={() => navigateAction(navigation, isTLYF ? "Dashboard" : "Home")}
                  >
                    <ObxLogo
                      source={
                        isTLYF
                          ? require("../../assets/images/tlyfe-black-logo.png")
                          : require("../../assets/images/obxLogoBig.png")
                      }
                      resizeMode="contain"
                      style={{
                        marginLeft: 0,
                      }}
                    />
                    {token ? (
                      <View
                        style={{
                          position: "absolute",
                          right: 0,
                          flexDirection: "row",
                        }}
                      >
                        {this.renderNotificationIconMobile()}
                      </View>
                    ) : null}
                  </ObxLogoWrapper>
                ))}
              {pageTitleMob && (
                <Text
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    margin: "auto",
                    zIndex: 10,
                  }}
                >
                  {pageTitleMob}
                </Text> // FOR SAMADHAN, this is to be centered
              )}

              {rightChildLabelMob && (
                <MainMenu>
                  <SearchText
                    onPress={() => navigateAction(navigation, "tlyfe - powered by OpenBrix")}
                  >
                    Home
                  </SearchText>
                  {!token && (
                    <LoginText onPress={() => navigateAction(navigation, "Sign in")}>
                      Register/Login
                    </LoginText>
                  )}
                </MainMenu>
              )}
              {drawerWithFilterMob && (
                <View
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {authDataFromMobile ? (
                    <BackTextWrapper
                      style={{
                        alignItems: "center",
                      }}
                      onPress={() => {
                        if (onBackAction) {
                          onBackAction();
                        } else {
                          if (replace) {
                            replaceAction(navigation, replace);
                          } else {
                            popAction(navigation, 1);
                          }
                        }
                      }}
                    >
                      <Entypo
                        name="chevron-left"
                        size={18}
                        color="#000"
                        // style={{ verticalAlign: "bottom" }}
                      />{" "}
                      Back
                    </BackTextWrapper>
                  ) : (
                    <TouchableOpacity
                      onPress={() => this.setDrawerState(true)}
                      style={{ flexGrow: 1 }}
                    >
                      <DrawerIcon
                        source={require("../../assets/images/drawerMenu.png")}
                        resizeMode="contain"
                      />
                    </TouchableOpacity>
                  )}
                  <View
                    style={{
                      flexGrow: 9,
                      alignItems: "center",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <EditSearch onPress={() => this.props.navigation.goBack()}>
                      Edit search
                    </EditSearch> */}
                    <DropDownPicker
                      items={sort_by}
                      placeholder="Sort by"
                      onChangeItem={(e) => {
                        this.props.sortBy(e.value);
                      }}
                      defaultValue={this.props.sortValue}
                      dropDownStyle={{ backgroundColor: "white" }}
                    />
                    <View style={{ width: 15 }}/>
                    <WideButton
                      childText="Filter"
                      width="30%"
                      onPress={() => this.props.headerButtonAction()}
                    />
                    {authDataFromMobile ? null : token ? this.renderNotificationIconMobile() : null}
                  </View>
                </View>
              )}
              {showClearAllButtonMob && (
                <BackTextWrapper
                  style={{
                    position: "absolute",
                    right: 0,
                  }}
                  onPress={() => (this.props.clearAction ? this.props.clearAction() : null)}
                >
                  Clear All
                </BackTextWrapper>
              )}
              {showMyAccountMob && (
                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate("My Account");
                  }}
                  style={{
                    position: "absolute",
                    right: 0,
                    flexDirection: "row",
                  }}
                >
                  <MyAccount>Account</MyAccount>
                  {token ? this.renderNotificationIconMobile() : null}
                </TouchableOpacity>
              )}
            </MainHeader>
            {headerButtonsMob && ( // FOR SAMADHAN, height of this in mobile in to be handled
              <BottomBar
                style={{
                  borderColor: "#D0D0D0",
                  borderTopWidth: 0,
                }}
              >
                <AlertCol>
                  <BackBtn
                    onPress={() => {
                      if (onBackAction) {
                        onBackAction();
                      } else {
                        popAction(navigation, 1);
                      }
                    }}
                  >
                    <Entypo
                      name="chevron-left"
                      size={18}
                      color="#000"
                      // style={{ verticalAlign: "bottom" }}
                    />{" "}
                    Back
                  </BackBtn>
                  <WideButton
                    childText="Create alert"
                    width="50%"
                    id={"CREATE_ALERT"}
                    onPress={() =>
                      this.props.navigation.navigate(token ? "Create Alert" : "Sign in", {
                        alert_id: "",
                      })
                    }
                  />
                </AlertCol>
                <FilterCol>
                  {/* <EditSearch onPress={() => this.props.navigation.goBack()}>
                    Edit search
                  </EditSearch> */}
                  <DropDownPicker
                    items={sort_by}
                    placeholder="Sort by"
                    onChangeItem={(e) => {
                      this.props.sortBy(e.value);
                    }}
                    defaultValue={this.props.sortValue}
                    dropDownStyle={{ backgroundColor: "white" }}
                  />
                  <View style={{ width: 15 }}/>

                  <WideButton
                    childText="Filter"
                    width="50%"
                    id={"CREATE_FILTER"}
                    onPress={() => this.props.headerButtonAction()}
                  />
                </FilterCol>
              </BottomBar>
            )}
          </Container>
          <CustomDrawerModal
            setDrawerState={this.setDrawerState}
            drawerState={this.state.drawerState}
            {...this.props}
          />
          <CustomNotificationModal
            setNotificationDrawerState={this.setNotificationDrawerState}
            notificationList={this.state.notificationList}
            drawerState={this.state.notificationState}
            {...this.props}
          />
        </HeaderWrapper>
      );
    }

      //
      // ─── TAB SPECIFIC HEADER ────────────────────────────────────────────────────────
    //
    else if (layoutType == "tab") {
      return (
        <HeaderWrapper
          {...this.props}
          headerColor={headerColorTab}
          style={{
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.09,
            shadowRadius: 6.68,
            elevation: 11,
          }}
        >
          <Container>
            <MainHeader>
              {showDrawerTab && (
                <TouchableOpacity onPress={() => this.setDrawerState(true)} style={{ zIndex: 100 }}>
                  <DrawerIcon
                    source={require("../../assets/images/drawerMenu.png")}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              )}

              {showBackButtonTab && (
                <BackTextWrapper
                  onPress={() => {
                    if (onBackAction) {
                      onBackAction();
                    } else {
                      if (replace) {
                        replaceAction(navigation, replace);
                      } else {
                        popAction(navigation, 1);
                      }
                    }
                  }}
                >
                  <Entypo
                    name="chevron-left"
                    size={18}
                    color="#000"
                    // style={{ verticalAlign: "bottom" }}
                  />{" "}
                  Back
                </BackTextWrapper>
              )}

              {showLogoTab &&
                (isTds ? (
                  <ObxLogoWrapper>
                    <Link to="/my-dashboard">
                      <ObxLogo
                        source={require("../../assets/images/tds.png")}
                        resizeMode="contain"
                      />
                    </Link>
                  </ObxLogoWrapper>
                ) : (
                  <ObxLogoWrapper
                    onPress={() => navigateAction(navigation, isTLYF ? "Dashboard" : "Home")}
                  >
                    <ObxLogo
                      source={
                        isTLYF
                          ? require("../../assets/images/tlyfe-black-logo.png")
                          : require("../../assets/images/obxLogoBig.png")
                      }
                      resizeMode="contain"
                    />
                  </ObxLogoWrapper>
                ))}
              {pageTitleTab && (
                <Text style={{ alignSelf: "center" }}>{pageTitleTab}</Text> // FOR SAMADHAN, this is to be centered
              )}

              {rightChildLabelTab && (
                <MainMenu>
                  <SearchText>Search</SearchText>
                  {!token && (
                    <LoginText onPress={() => navigateAction(navigation, "Sign in")}>
                      Register/Login
                    </LoginText>
                  )}
                  {token ? (
                    <View
                      style={{
                        position: "absolute",
                        right: 0,
                        flexDirection: "row",
                      }}
                    >
                      {this.renderNotificationIconMobile()}
                    </View>
                  ) : null}
                </MainMenu>
              )}
              {showClearAllButtonTab && (
                <BackTextWrapper
                  style={{
                    position: "absolute",
                    right: 0,
                  }}
                  onPress={() => (this.props.clearAction ? this.props.clearAction() : null)}
                >
                  Clear All
                </BackTextWrapper>
              )}
              {showMyAccountTab && (
                <MyAccount
                  onPress={() => {
                    this.props.navigation.navigate("My Account");
                  }}
                >
                  Account
                </MyAccount>
              )}
            </MainHeader>
            {headerButtonsTab && ( // FOR SAMADHAN, height of this in mobile in to be handled
              <BottomBar
                style={{
                  borderColor: "#D0D0D0",
                  borderTopWidth: 1,
                }}
              >
                <AlertCol>
                  <BackBtn
                    onPress={() => {
                      if (onBackAction) {
                        onBackAction();
                      } else {
                        popAction(navigation, 1);
                      }
                    }}
                  >
                    <Entypo
                      name="chevron-left"
                      size={18}
                      color="#000"
                      // style={{ verticalAlign: "bottom" }}
                    />{" "}
                    Back
                  </BackBtn>
                  <WideButton
                    childText="Create alert"
                    width="50%"
                    onPress={() =>
                      this.props.navigation.navigate(token ? "Create Alert" : "Sign in", {
                        alert_id: "",
                      })
                    }
                  />
                </AlertCol>
                <FilterCol>
                  {/* <EditSearch onPress={() => this.props.navigation.goBack()}>
                    Edit search
                  </EditSearch> */}
                  <DropDownPicker
                    items={sort_by}
                    placeholder="Sort by"
                    onChangeItem={(e) => {
                      this.props.sortBy(e.value);
                    }}
                    defaultValue={this.props.sortValue}
                    dropDownStyle={{ backgroundColor: "white" }}
                  />
                  <View style={{ width: 15 }}/>
                  <WideButton
                    childText="Filter"
                    width="50%"
                    onPress={() => this.props.headerButtonAction()}
                  />
                </FilterCol>
              </BottomBar>
            )}
          </Container>
          <CustomDrawerModal
            setDrawerState={() => this.setDrawerState()}
            drawerState={this.state.drawerState}
            {...this.props}
          />
          <CustomNotificationModal
            setNotificationDrawerState={this.setNotificationDrawerState}
            notificationList={this.state.notificationList}
            drawerState={this.state.notificationState}
            {...this.props}
          />
        </HeaderWrapper>
      );
    }

      //
      // ─── WEB SPECIFIC HEADER ────────────────────────────────────────────────────────
    //
    else {
      return (
        <HeaderWrapper
          {...this.props}
          headerColor={headerColorWeb}
          style={{
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.09,
            shadowRadius: 6.68,
            elevation: 11,
            zIndex: 16,
          }}
        >
          <Container>
            <MainHeader>
              {showBackButtonMob && (
                <BackTextWrapper>
                  <Entypo
                    name="chevron-left"
                    size={18}
                    color="#000"
                    // style={{ verticalAlign: "bottom" }}
                  />{" "}
                  Back
                </BackTextWrapper>
              )}

              {showLogoWeb &&
                (isTds ? (
                  <Link to="/my-dashboard">
                    <ObxLogo source={require("../../assets/images/tds.png")} resizeMode="contain"/>
                  </Link>
                ) : (
                  <Link to={isTLYF ? "/my-dashboard" : "/home"}>
                    <ObxLogo
                      id={"HEADER_LOGO"}
                      source={
                        true
                          ? require("../../assets/images/tlyfe-black-logo.png")
                          : require("../../assets/images/obxLogoBig.png")
                      }
                      resizeMode="contain"
                    />
                  </Link>
                ))}
              {pageTitleWeb && (
                <Text style={{ alignSelf: "center" }}>{pageTitleWeb}</Text> // FOR SAMADHAN, this is to be centered
              )}
              {token ? (
                <MainMenu style={{ alignItems: "center",gap:40 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <MenuItem
                      id="Tlyfe"
                      onPress={() => navigateAction(navigation, "Dashboard")}
                      // style={{ marginBottom: 10 }}
                      color={
                        currentRoute == "Rental Passport" || currentRoute == "Document Share Center"
                          ? SELECTEDCOLOR
                          : "#000000"
                      }
                    >
                      My Dashboard{" "}
                    </MenuItem> */}
                    <TouchableOpacity 
                    onPress={() => navigateAction(navigation, "Dashboard")}
                    style={{flexDirection:'row',alignItems:'center'}}>
                    <ODSText type='sm-bold' color={currentRoute=="Dashboard"?SELECTEDCOLOR:colors.text.primary} marginRight={8}>My Dashboard</ODSText>
                    </TouchableOpacity>
                   <TouchableOpacity  onPress={() =>
                        this.setState({
                          ownIdView: !this.state.ownIdView,
                          openDialog: false,
                        })}>
                    <ODSIcon icon={this.state.ownIdView?"TB-Caret-Up":"TB-Caret-Down"} size={20}  color={colors.text.primary}/>
                   </TouchableOpacity>
                    {/* <Entypo
                      name="chevron-down"
                      size={25}
                      id="dropdown"
                      onPress={() =>
                        this.setState({
                          ownIdView: !this.state.ownIdView,
                          openDialog: false,
                        })
                      }
                      color={
                        currentRoute == "Rental Passport" || currentRoute == "Document Share Center"
                          ? SELECTEDCOLOR
                          : "#000000"
                      }
                    /> */}
                    {this.state.ownIdView ? (
                      <View
                        style={[
                          {
                            borderWidth: 1.5,
                            borderColor: colors.other['border-color'],
                            zIndex: 10000,
                            // boxShadow: "0px 0px 25px #DADADA",
                            width: 234,
                            paddingHorizontal: 24,
                            paddingVertical:20,
                            top: 50,
                            left: -80,
                            borderRadius: 10,
                            position: "absolute",
                            backgroundColor: "#FFFFFF",
                            gap:24
                          },
                        ]}
                      >
                    
                        <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              navigateAction(navigation, "PreQualification");
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                <ODSText type='sm-bold' color={currentRoute == "PreQualification" ? SELECTEDCOLOR:colors.text.secondary}>Rent Ready</ODSText>

                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              navigateAction(navigation, "TDS Search Deposit");
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                        
        
                          <ODSText type='sm-bold' color={currentRoute == "TDS Search Deposit" ? SELECTEDCOLOR:colors.text.secondary}>Tenancy Deposit</ODSText>

                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              navigateAction(navigation, "Credit Builder");
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                      
                          <ODSText type='sm-bold' color={currentRoute == "Credit Builder" ? SELECTEDCOLOR : colors.text.secondary}>Credit Builder</ODSText>

                        </TouchableOpacity>
                        {/* <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              navigateAction(navigation, "Money Centre");
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              height: 8,
                              width: 8,
                              borderRadius: 4,
                              backgroundColor: "#a4d4a2",
                              marginRight: 10,
                            }}
                          />
                          <Typography
                            raj
                            color={currentRoute == "Money Centre" ? SELECTEDCOLOR : "#000000"}
                            size={"16px"}
                            line={"35px"}
                            align={"left"}
                          >
                            Money Centre
                          </Typography>
                        </TouchableOpacity> */}
                        <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              navigateAction(navigation, "Document Share Center");
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                        
                  
                          <ODSText type='sm-bold' color={currentRoute == "Document Share Center" ? SELECTEDCOLOR : colors.text.secondary}>Document Share</ODSText>

                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              navigateAction(navigation, "Marketplace");
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                        
                  
                          <ODSText type='sm-bold' color={currentRoute == "Marketplace" ? SELECTEDCOLOR : colors.text.secondary}>Marketplace</ODSText>

                        </TouchableOpacity>
      
                      </View>
                    ) : null}
                  </View>

                  {/* <Link to="/My-Alerts">
                    <MenuItem
                      color={
                        currentRoute == "My Alerts" ? SELECTEDCOLOR : "#000000"
                      }
                    >
                      Alerts
                    </MenuItem>
                  </Link> */}
                   <Link to="/media-hub">
                    <Hoverable
                      onHoverIn={() => this.handleHoverIn("MediaHub")}
                      onHoverOut={() => this.handleHoverOut("MediaHub")}
                    >
                      <ODSText type='sm-bold' color={currentRoute=="MediaHub"?SELECTEDCOLOR:colors.text.primary} >Info Hub</ODSText>
                    </Hoverable>
                  </Link>
                  {/* <Link to="/marketplace">
                    <Hoverable
                      onHoverIn={() => this.handleHoverIn("Marketplace")}
                      onHoverOut={() => this.handleHoverOut("Marketplace")}
                    >
                      <MenuItem
                        id="marketPlace"
                        color={currentRoute == "Marketplace" ? SELECTEDCOLOR : "#000000"}
                      >
                        Marketplace
                      </MenuItem>
                    </Hoverable>
                  </Link> */}
              {userProfile?.isActiveOpenflow?   
               <Link to="/my-tenancy">
                    <Hoverable
                      onHoverIn={() => this.handleHoverIn("My Tenancy")}
                      onHoverOut={() => this.handleHoverOut("My Tenancy")}
                    >
                      <ODSText type='sm-bold' color={currentRoute=="My Tenancy"?SELECTEDCOLOR:colors.text.primary} >My Tenancy</ODSText>
                    </Hoverable>
                  </Link>:null}
                  <Link to="/property-search">
                   
                    <ODSText type='sm-bold' color={currentRoute=="landingSearch"?SELECTEDCOLOR:colors.text.primary} >Property Search</ODSText>

                  </Link>
                  {/* <Link to="/Rental-Passport">
                    <MenuItem
                      color={
                        currentRoute == "Rental Passport"
                          ? SELECTEDCOLOR
                          : "#000000"
                      }
                    >
                      Rent record
                    </MenuItem>
                  </Link> */}

                  {/* <View style={{ flexDirection: "column" }}>
                    <MenuItem
                    // onPress={() =>
                    //   this.setState({ ownIdView: !this.state.ownIdView })
                    // }
                    // color={
                    //   currentRoute == "Rental Passport" ||
                    //   currentRoute == "Document Share Center"
                    //     ? SELECTEDCOLOR
                    //     : "#000000"
                    // }
                    >
                      Notifications{" "}
                      <AntDesign
                        name="down"
                        size={15}
                        // color={
                        //   currentRoute == "Rental Passport" ||
                        //   currentRoute == "Document Share Center"
                        //     ? SELECTEDCOLOR
                        //     : "#000000"
                        // }
                      />
                    </MenuItem>
                  </View> */}
                  {/* <Link
                    // to="/signin"
                    onPress={() => {
                      this.setState({ popUpIsVisible: true });
                    }}
                  >
                    <MenuItem
                      id="logout"
                      color={currentRoute == 'Sign in' ? SELECTEDCOLOR : '#000000'}
                    >
                      Logout
                    </MenuItem>

                  </Link> */}

                  <View style={{ position: "relative" }}>
                    <FontAwesome5
                      name="user-circle"
                      id="account"
                      onPress={() => this.setState({ openDialog: !this.state.openDialog })}
                      style={{ marginLeft: 0 }}
                      size={30}
                      color={"black"}
                    />

                    {this.state.openDialog ? (
                      <View
                        style={[
                          {
                            borderWidth: 1.5,
                            borderColor: colors.other['border-color'],
                            zIndex: 10000,
                            // boxShadow: "0px 0px 25px #DADADA",
                            width: 234,
                            paddingHorizontal: 24,
                            paddingVertical:20,
                            top: 55,
                            right: 0,
                            borderRadius: 10,
                            position: "absolute",
                            backgroundColor: "#FFFFFF",
                            gap:24
                          },
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              this.props.navigation.navigate("My Account");
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          
                
                          <ODSText type='sm-bold' color={currentRoute == "My Account" ? SELECTEDCOLOR : colors.text.secondary}>My Account</ODSText>

                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            this.handleClick(() => {
                              this.setState({
                                isDeleteAccount: false,
                                popUpIsVisible: true,

                              });
                            });
                          }}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                        
            
                          <ODSText type='sm-bold' color={colors.text.secondary}>Logout</ODSText>

                        </TouchableOpacity>

                      </View>
                    ) : null}
                  </View>
                  {/* <MaterialIcons
                    id="notifications"
                    onPress={() => this.setNotificationDrawerState(true)}
                    style={{ marginLeft: 30 }}
                    name="notifications-none"
                    size={36}
                    color={"black"}
                  /> */}
                </MainMenu>
              ) : (
                <MainMenu>
                  <SearchText
                    id="search-1"
                    onPress={() => navigateAction(navigation, "tlyfe - powered by OpenBrix")}
                    color={
                      currentRoute == "tlyfe - powered by OpenBrix" ? SELECTEDCOLOR : "#000000"
                    }
                  >
                    {" "}
                    Home
                  </SearchText>
                  {!token && (
                    <LoginText
                      id="register/login"
                      color={currentRoute == "Sign in" ? SELECTEDCOLOR : "#000000"}
                      onPress={() => navigateAction(navigation, "Sign in")}
                    >
                      Register/Login
                    </LoginText>
                  )}
                </MainMenu>
              )}
              {showClearAllButtonWeb && (
                <BackTextWrapper
                  style={{
                    position: "absolute",
                    right: 0,
                  }}
                  onPress={() => (this.props.clearAction ? this.props.clearAction() : null)}
                >
                  Clear All
                </BackTextWrapper>
              )}
            </MainHeader>
            {headerButtonsWeb && ( // FOR SAMADHAN, height of this in mobile in to be handled
              <BottomBar
                style={{
                  borderColor: "#D0D0D0",
                  borderTopWidth: 1,
                }}
              >
                <AlertCol>
                  <BackBtn
                    onPress={() => {
                      if (onBackAction) {
                        onBackAction();
                      } else {
                        popAction(navigation, 1);
                      }
                    }}
                  >
                    <Entypo
                      name="chevron-left"
                      size={18}
                      color="#000"
                      // style={{ verticalAlign: "bottom" }}
                    />{" "}
                    Back
                  </BackBtn>
                  <WideButton
                    childText="Create alert"
                    width="30%"
                    onPress={() =>
                      this.props.navigation.navigate(token ? "Create Alert" : "Sign in", {
                        alert_id: "",
                      })
                    }
                  />
                </AlertCol>
                <FilterCol>
                  {/* <EditSearch>Edit search</EditSearch> */}
                  <DropDownPicker
                    items={sort_by}
                    placeholder="Sort by"
                    onChangeItem={(e) => {
                      this.props.sortBy(e.value);
                    }}
                    defaultValue={this.props.sortValue}
                    dropDownStyle={{ backgroundColor: "white" }}
                  />
                  <View style={{ width: 15 }}/>
                  <WideButton
                    childText="Filter"
                    width="30%"
                    onPress={() => this.props.headerButtonAction()}
                  />
                </FilterCol>
              </BottomBar>
            )}
          </Container>
          <CustomPopUp
            loading={popUpIsVisible}
            primaryButtonText={"Sure"}
            secondaryButtonText={"cancel"}
            primaryText={isDeleteAccount ? "Delete Account ?" : "Logout?"}
            secondaryText={isDeleteAccount ? "Are you sure you want to delete your account?" : " Are you sure you want to logout?"}
            onPrimaryButtonPress={() => {
              this.setState({ popUpIsVisible: false }, () => {
                this.props.navigation.navigate("Sign in");
                this.props.LogoutApi(isDeleteAccount);
              });
            }}
            onSecondaryButtonPress={() => {
              this.setState({ popUpIsVisible: false });
            }}
          />
          <CustomNotificationModal
            setNotificationDrawerState={this.setNotificationDrawerState}
            notificationList={this.state.notificationList}
            drawerState={this.state.notificationState}
            {...this.props}
          />
        </HeaderWrapper>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const token = idx(state, (_) => _.appReducer.loginData.data.token);
  const { profile } = state.authReducer;
  const { data } = state.notificationReducer;
  const userId = state.authReducer?.profile?.data?.appUser?.app_user_id;
  return {
    token,
    profile,
    data,
    userId,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      LogoutApi,
      getProfile,
      addNotification,
      onNotificationOpen,
      //appUserTrackingProductHover,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
