import React, { useState } from "react";

import MediaHubList from "./mediaHubList";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { backgroundSearchArr } from "../LandingPages/images/tlyfe/searchArrayTlyfe";
import { Image, View } from "react-native";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import { useTheme } from "@emotion/react";
import getPlatformType from "../../helpers/getPlatform";
import HousingGatewayCard from "../../../openbrix-design-system/ui/molecules/HousingGatewayCard/index.web";

const MediaHub = (props) => {
  const [type, setType] = useState("web");
  const { colors } = useTheme();
  const layout = getPlatformType();

  return (
    <BackGroundGenerator
      props={props}
      layoutType={(data) => setType(data)}
      backgroundArr={backgroundSearchArr}
      isTLYF={true}
      isLanding
      hideMiddleLogo
    >
      <View>
        <Image source={require('../../../openbrix-design-system/images/dashboard/infohub-web.png')} style={{ width: '100%', height: 400, marginBottom: layout == 'web' ? -150 : -90, marginTop: layout != "web" ? -40 : 0 }} />
        <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingVertical: layout == "web" ? 48 : 32, zIndex: 1000, backgroundColor: '#fafafa' }}>
          <View style={{ alignItems: 'center' }}>
            <ODSText type="h3" color={colors.text.primary} >Info Hub</ODSText>
            <ODSText marginVertical={14} type='h4-light' color={colors.text.primary} >in collaboration with</ODSText>
            <Image source={require('../../../openbrix-design-system/images/logos/tds-black-logo.png')} style={{ width: 104, height: 60, resizeMode: 'contain' }} />
          </View>
          <View style={{ width: layout == 'phone' ? '90%' : '50%',marginTop: layout=="phone"?24:0 }}>
            <HousingGatewayCard hasBorder={true} />
          </View>
        </View>
        <MediaHubList navigation={props.navigation} detailsLink="MediaHubDetailsPage" />
      </View>
    </BackGroundGenerator>
  );
};

export default MediaHub;
