import React, { useState } from "react";
import { View, TouchableOpacity, StyleSheet, Linking, Dimensions, Platform } from "react-native";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../atoms/ODSText";
import { ODSInput } from "../../atoms/ODSInput";
import { ODSIcon } from "../../atoms/ODSIcon";
import moment from "moment";
import getScreenType from "../../../utils/getScreenType";

const PropertyDocs = ({ data, onDelete, addDoc }) => {
    const { colors } = useTheme();
    const [searchText, setSearchText] = useState('');
    const layout = getScreenType();
    const width = Dimensions.get('window').width;

    const filteredData = data.filter((e) =>
        e?.name?.toLowerCase().includes(searchText?.toLowerCase())
    );

    return (
        <View style={styles.container}>
            <ODSText type="sm" textAlign="center" marginTop={32} color={colors.text.secondary}>
                Here you’ll find all documents associated with your rental property.
            </ODSText>
            <View style={[styles.divider, {
                backgroundColor: colors.other['border-neutral'], marginVertical: layout == "web" ? 32 : 16,
            }]} />
            <ODSInput
                width={layout == "phone" ? width - 30 : 297}
                leftIcon="TB-Search"
                value={searchText}
                onChange={setSearchText}
                placeholder="Search documents"
            />
            <View style={[styles.docsContainer, { marginVertical: 24 }]}>
                {filteredData.map((e) => (
                    <View
                        key={e.id}
                        style={[
                            styles.docItem,
                            {
                                borderColor: colors.other['border-neutral'], shadowColor: '#101828',
                                width: layout == 'web' ? 407 : layout == 'tab' ? 298 : width - 30
                            }
                        ]}
                    >
                        <TouchableOpacity onPress={() => layout != "web" ? Linking.openURL(e?.url) : window.open(e?.url)}>
                            <ODSIcon icon="TB-Download" size={32} color={colors.main.purple[500]} />
                        </TouchableOpacity>
                        <View style={styles.docTextContainer}>
                            <ODSText numberOfLines={1} width='90%' type="md-bold" color={colors.text.primary}>{e?.name}</ODSText>
                            <ODSText type="sm" color={colors.text.secondary} marginTop={4}>
                                {e?.date && moment(e.date).format("DD/MM/YYYY")}
                            </ODSText>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    divider: {

        height: 1,
    },
    docsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    docItem: {
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 12,
        padding: 24,
        borderWidth: 1,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: Platform.OS == "android" ? 0 : 5,
        marginBottom: 16
    },
    docTextContainer: {
        marginLeft: 16,
        flex:1
    },
    deleteButton: {
        marginLeft: 'auto',
        width: 32,
        height: 32,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default PropertyDocs;
