import idx from "idx";
import { Alert } from "react-native";
import { types } from "../../actionTypes";
import { store } from "../../store/setup";

import { SERVER_URL, _post, URL, _get, _put, _delete } from "../../../helpers/connectionHelpers";
import { showToaster } from "../toast";

export const getLadger = (bodyofRequest, navigation) => {
  
  return (dispatch) => {
    dispatch({ type: types.LADGER_REQUEST });

    _get(
      `${SERVER_URL}${URL.getLadgerURL}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.LADGER_SUCCESS,
          payload: res,
        });
        Alert.alert(res.message);
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.LADGER_FAILED, error: err.message });
        Alert.alert(err.message);
      }
    );
  };
};

export const getlink = (cb) => {
  return (dispatch) => {
    dispatch({ type: types.LADGER_REQUEST });
    _get(
      `${SERVER_URL}${URL.getCreditLink}`,
      {}, 
      {},
      (res) => {
        if(res?.status){
          if(cb){
            cb(res?.data)
          }
        }
      },
      (err) => {
      }
    );
  };
};

export const cancelSubscription = (cb) => {
  return (dispatch) => {
    dispatch({ type: types.CL_PAYMENT_REQUEST });
    dispatch({ type: types.CCJ_PENDING });
    _post(`${SERVER_URL}${URL.cancelCreditLadderSubscription}`, {}, JSON.stringify({}), (res) => {
      dispatch({ type: types.CCJ_PENDING_SUCCESS });
      if(res?.status){
        dispatch(showToaster('', 'Subscription cancelled successfully!'));
        if(cb){
          cb(res?.data)
        } else {
          Alert.error("Failed to cancel subscription")
        }
      }
    }, (err) => {
      dispatch({ type: types.CCJ_PENDING_SUCCESS });
    });
  };
};
export const getPaymentStatus = (cb) => {
  return (dispatch) => {
    dispatch({ type: types.CL_PAYMENT_REQUEST });
    dispatch({ type: types.CCJ_PENDING });
    _get(
      `${SERVER_URL}${URL.getPayStatus}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
      dispatch({ type: types.CCJ_PENDING_SUCCESS });
      if(res?.status){
        if(cb){
          cb(res?.data)
        } else {
          Alert.error("Failed to fetch payments")
        }
      }
    }, (err) => {
      dispatch({ type: types.CCJ_PENDING_SUCCESS });
    });
  };
};
export const startPayment = (cb) => {
  return (dispatch) => {
    dispatch({ type: types.CL_PAYMENT_REQUEST });
    dispatch({ type: types.CCJ_PENDING });
    _post(`${SERVER_URL}${URL.startCreditLadderPayment}`, {}, JSON.stringify({}), (res) => {
    dispatch({ type: types.CCJ_PENDING_SUCCESS });
      if(res?.status){
        if(cb){
          cb(res?.data)
        } else {
          Alert.error("Failed to start payment")
        }
      }
    }, (err) => {
      dispatch({ type: types.CCJ_PENDING_SUCCESS })
    });
  };
};
export const updatePayment = (cb) => {
  return (dispatch) => {
    dispatch({ type: types.CL_PAYMENT_REQUEST });
    dispatch({ type: types.CCJ_PENDING });
    _post(`${SERVER_URL}${URL.updatePaymentMethod}`, {}, JSON.stringify({}), (res) => {
    dispatch({ type: types.CCJ_PENDING_SUCCESS });
      if(res?.status){
        if(cb){
          cb(res?.data)
        } else {
          Alert.error("Failed to start payment")
        }
      }
    }, (err) => {
      dispatch({ type: types.CCJ_PENDING_SUCCESS })
    });
  };
};

export const getCreditLadderHistory = (bodyofRequest, navigation) => {

  return (dispatch) => {
    dispatch({ type: types.CLHISTORY_REQUEST });

    _get(
      `${SERVER_URL}${URL.getCLHistory}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success

        dispatch({
          type: types.CLHISTORY_SUCCESS,
          payload: res,
        });
        Alert.alert(res.message);
      },
      (err) => {
        // function to perform on failure

        dispatch({ type: types.CLHISTORY_FAILED, error: err.message });
        Alert.alert(err.message);
      }
    );
  };
};

export const getData = (bodyofRequest, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST });

    _get(
      `${SERVER_URL}${URL.getListFromReqRes}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: res,
        });
        Alert.alert(res.message);
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.LOGIN_FAILED, error: err.message });
        Alert.alert(err.message);
      }
    );
  };
};

export const logError = (bodyofRequest, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.LOG_ERROR_REQUEST });
    
    let token = idx(store, (_) => _.getState().appReducer.loginData.data.token);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-type", `application/json`);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body:JSON.stringify(bodyofRequest),
      // redirect: "follow",
    };
    // 
    fetch(`${SERVER_URL}${URL.logErrorURL}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        
        const response = JSON.parse(result);
        if (response.status) {
          dispatch({
            type: types.LOG_ERROR_SUCCESS,
            payload: response,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: response.data,
          });
          navigation("Rental Passport");
        } else {
          dispatch({ type: types.LOG_ERROR_FAILED, error: response.error });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: response.error,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: types.LOG_ERROR_FAILED, error: err.message });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: err.message,
        });
        Alert.alert(err.message);
      });
  };
};
