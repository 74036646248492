import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ReactGA from "react-ga4";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { navigationRef } from "./RootNavigation";

import Zopa from "../components/Zopa";
import Charity from "../components/charity";
import MediaHub from "../components/mediaHub";
import Market from "../components/OpenMarket";
import TdsLogin from "../components/tds/login";
import AboutUs from "../components/aboutUsTlyfe";
import MyProperty from "../components/myProperty";
import DummyScreen from "../components/DummyScreen";
import LegalScreen from "../components/legalScreen";
import RentalPortal from "../components/rentalPortal";
import ContactUs from "../components/contactusScreen";
import TlyfeLanding from "../components/tlyfeLanding";
import Home from "../components/openFlow/screens/home";
import AddDocument from "../components/addNewDocument";
import ServiceScreen from "../components/serviceScreen";
import ResetPassword from "../components/resetPassword";
import ReportIssue from "../components/issues/logIssue";
import PastIssues from "../components/issues/pastIssues";
import ChangePassword from "../components/changePassword";
import MyAlerts from "../components/createAlert/myAlerts";
import PropertyDetails from "../components/propertyDetails";
import SavedProperties from "../components/savedProperties";
import searchDeposit from "../components/tds/searchDeposit";
import insuredDeposit from "../components/tds/insuredDeposit";
import ConfirmTenancy from "../components/tds/confirmTenancy";
import AgentConfirmation from "../components/agentConfirmation";
import ReviewAST from "../components/openFlow/screens/reviewAST";
import SearchRenterScreen from "../components/searchRenterScreen";
import Dashboard from "../components/identifyAndFinance";
import custodialRespond from "../components/tds/custodialRespond";
import custodialAccount from "../components/tds/custodialAccount";
import LandingPage from "../components/idVerification/landingPage";
import marketDetails from "../components/OpenMarket/marketDetails";
// import RentalInfo from "../components/openFlow/screens/rentalInfo";
import SearchFilter from "../components/searchFilter/searchFilter";
import DepositInfo from "../components/openFlow/screens/depositInfo";
import custodialDashboard from "../components/tds/custodialDashboard";
import custodialTenancies from "../components/tds/custodialTenancies";
import custodialRepayment from "../components/tds/custodialRepayment";
import notificationSettings from "../components/notificationSettings";
import RentalOffers from "../components/openFlow/screens/rentalOffers";

import NonFCCUpload from "../components/openFlow/screens/nonfccupload";
import custodialActivation from "../components/tds/custodialActivation";
import TenantDetails from "../components/openFlow/screens/tenantDetails";
import HoldingDeposit from "../components/openFlow/screens/holdingDeposit";
import CreditHistory from "../components/identifyAndFinance/CreditHistory";
import moreInformation from "../components/moreInformation/moreInformation";
import CreateAlertScreen from "../components/createAlert/createAlertScreen";
import IdVerification from "../components/identifyAndFinance/IdVerification";
import viewcustodialRepayment from "../components/tds/viewcustodialRepayment";
import Loader from "./../commonComponents/activityIndicator/activityIndicator";
import LandingPageTlyfe from "../components/landingPageTlyfe/landingPageTlyfe";
import custodialChangePassword from "../components/tds/custodialChangePassword";
import LandingTlyfe from "../components/LandingPages/landingTlyfe/landingTlyfe";
import DepositProtection from "../components/openFlow/screens/depositProtection";
import PreReferencingLite from "../components/identifyAndFinance/preReferencingLite";
import DetailMaintenance from "../components/identifyAndFinance/detailMaintenance";
import LandingContact from "../components/LandingPages/landingContact/landingContact";
import LandingOpenflo from "../components/LandingPages/landingOpenflo/landingOpenFlo";
import DashboardNew from "../components/identifyAndFinance/id&veificationNew";
import LandingPageTlyfeObx from "../components/landingPageTlyfeObx/landingPageTlyfeObx";
import NotificationDetails from "../components/notificationSettings/notificationDetails";
import ResetMailConfirmation from "../components/resetPassword/resetPasswordConfirmation";
import custodialAddInternationalBankAccount from "../components/tds/custodialAddInternationalBankAccount";
import createPassword from "../components/tds/createPassword";
import IdAndRef from "../components/openFlow/screens/IdAndRefNew";
import PreQualification from "../components/identifyAndFinance/pre-qualification";
import PreQualificationAffordability from "../components/idVerification/preQualificationAffordability";
import PreQualificationRightToRent from "../components/idVerification/preQualificationRightToRent";
import PreQualificationIdAndVerification from "../components/idVerification/preQualificationIdAndVerification";
import PreQualificationPreReference from "../components/idVerification/preQualificationPreReference";
import SharedDoc from "../components/openFlow/screens/sharedDoc";
import LandingPrequalification from "../components/LandingPages/landingTlyfe/landingPrequalification";
import LandingInsurance from "../components/LandingPages/landingTlyfe/landingInsurance";
import LandingCredit from "../components/LandingPages/landingTlyfe/landingCredit";
import LandingOpenBrix from "../components/LandingPages/landingTlyfe/landingOpenBrix";
import LandingAgent from "../components/LandingPages/landingTlyfe/landingAgent";
import LandingInfoHub from "../components/LandingPages/landingTlyfe/landingInfoHub";
import LandingInfoHubDetails from "../components/LandingPages/landingTlyfe/landingInfoHubDetails";
import LandingTeam from "../components/LandingPages/landingTlyfe/landingTeam";
import MyDocumentsList from "../components/myDocumentsList";
import MediaHubDetailsPage from "../components/mediaHub/mediaHubDetailsPage";
import LandingContactUs from "../components/LandingPages/landingTlyfe/landingContactUs";
import LandingAbout from "../components/LandingPages/landingTlyfe/landingAboutUs";
import HandoverPage from "../components/handover";
import TenantInsurance from "../components/tenantInsurance";
import PreQualificationTenantInfo from "../components/idVerification/PreQualificationTenantInfo";
import Rewards from "../components/idVerification/rewrds";
import tdsLocation from "../components/tds/tdsLocation";
import PropertyDocs from "../components/openFlow/screens/propertyDocs";
import TdsScoctland from "../components/tds/tdsScoctland";
import CustodialForgetPassword from "../components/tds/custodialForgetPassword";
import { Linking } from "react-native";
import { messaging } from "../helpers/firebase";
import { Playground } from "../../openbrix-design-system/ui/playground";
import CreditBuilderLanding from "../components/tlyfePages/CreditBuilderLanding";
import InsuranceLanding from "../components/tlyfePages/InsuranceLanding";
import RentReadyLanding from "../components/tlyfePages/RentReadyLanding";
import Introduction from "../components/tlyfePages/Introduction";
import LoginLanding from "../components/tlyfePages/LoginLanding";
import MyAccount from "../components/myaccount/newMyAccount";
import ChildScreen from "../components/myaccount/ChildScreen";
import TlyfeproLandingHome from "../components/tlyfePages/TlyfeproLandingHome";
import TlyfeproLanding from "../components/tlyfePages/TlyfeproLanding";
import TlyfeproOpenbrixLanding from "../components/tlyfePages/TlyfeproOpenbrixLanding";
import ArchiveDocuments from "../components/openFlow/screens/sharedDoc/archiveDocuments";
import FccHome from "../components/FCC/FCCHome";
import FccChooseAddress from "../components/FCC/ChooseAddress";
import FccProgression from "../components/FCC/FCCProgression";
import OpenFlow from "../components/openFlow/screens/openFlow";
import CheckOut from "../components/openFlow/screens/checkOut";
import ForwardMessege from "../components/openFlow/screens/forwardMessege";
import TlyfeProtect from "../components/openFlow/screens/TlyfeProtect/home";

const Stack = createStackNavigator();

function Routes(props) {

  const dispatch = useDispatch();
  let previousTimeStamp = Date.now();
  const routeNameRef = React.useRef();
  const isToken = props?.appReducer?.loginData?.data?.token;
  const [isReadyFirstTime, setIsReadyFirstTime] = useState(null);
  const hasCookiesPermission = useSelector((state) => state?.cookiesReducer?.accept_cookies);
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);

  //commented out until tom or someone else can finish the backend for this
  // useEffect(() => {
  //   if(userId && isReadyFirstTime) {
  //     dispatch(
  //       appUserTrackingFirstTime({ landing_page: isReadyFirstTime, app_user_id: userId })
  //     );
  //   }
  //
  // }, [isReadyFirstTime, userId]);

  const getInitialRoutes = () => {
    return isToken ? "Dashboard" : "tlyfe - powered by OpenBrix";
  };

  const linking = {
    prefixes: ["https://dev.tlyfe.openbrix.co.uk/"],
    initialRouteName: getInitialRoutes(),
    config: {
      screens: {
        [isToken ? "Dashboard" : "tlyfe - powered by OpenBrix"]: "",
        Dashboard: "my-dashboard",
        ErrorPage: "*",
        HandoverPage: "HandoverPage",
        Search: "home/search",
        landingSearchFrom: "home/:from",
        landingSearch: "property-search",
        Charity: "charity",
        landingTlyfe: "tlyfe",
        landingTlyfeFrom: "tlyfe/:from",
        Register: "register",
        RegisterEmailFrom: "register/:from",
        "Sign in": "sign-in",
        "Sign infrom": "sign-in/:from",
        "TDS Search Deposit": "tds/login",
        "tdsLocation": "tds/location",
        "TdsScoctland":"tds/scotland",
        MediaHub: "media-hub",
        MediaHubDetailsPage: "media-hub/:id",
        "About Us": "about-us",
        "About UsFrom": "about-us/:from",
        "Money Centre": "money-centre",
        landingOpenflo: "openflo",
        landingOpenfloFrom: "openflo/:from",
        "My Alerts": "my-alerts",
        LandingHome: "landinghome",
        "Contact Us": "contact-us",
        "Legal Bits": "legal-bits/:to",
        "Legal Bits": "legal-bits",
        Maintenance: "maintenance",
        "My Account": "my-account",
        "My Account To": "my-account/:to",
        "ChildScreen": 'myaccount/:id',
        LandingPageTlyfe: "landing",
        TlyfeLanding: "tlyfeLanding",
        "My Tenancy": "my-tenancy/:id",
        "My Tenancy Home": "my-tenancies",
        "CheckOut":"checkout",
        "ForwardMessege":"ForwardMessege",
        "Marketplace": "marketplace",
        SearchFilter: "search-filter",
        insuredDeposit: "tds/insured",
        CreditHistory: "credithistory",
        "Our Services": "Our-Services",
        "User Details": "User-Details",
        "Document Share Center": "share-centre",
        LandingPageTlyfeObx: "agent-flo",
        PropertyDocs: "propertydoc/:id",
        RegisterEmail: "register/:email",
        "Add Documents": "add-documents",
        "RentalDocs": "rental-docs",
        "Market Details": "market-details",
        IdVerification: "id-verification",
        DepositInfo: "openflo/movein/:id",
        PreReferencing: "pre-referencing",
        ReviewAST: "openflo/review-ast/:id",
        "Credit Builder": "credit-builder",
        "Past Issues": "past-issues/:item",
        "Password Reset": "password-reset",
        "TDS Search Deposit": "tds/deposit-search",
        "Custodial Tenancies": "tds/custodial",
        DashboardNew: "id-flo-new",
        "Rental Passport": "rental-passport",
        "Change Password": "Change-Password",
        "Saved Properties": "saved-properties",
        "LandingPrequalification": "landing/Prequalification",
        "LandingPrequalificationFrom": "landing/Prequalification/:from",
        "LandingAgent": "landing/Agent",
        "LandingAgentFrom": "landing/Agent/:from",
        "LandingCredit": "landing/CreditBuilder",
        "LandingCreditFrom": "landing/CreditBuilder/:from",
        "LandingTeam": "landing/Team",
        "LandingInsurance": "landing/Insurance",
        "LandingInsuranceFrom": "landing/Insurance/:from",
        "LandingContactUs": "landing/Contact",
        "LandingAbout": "landing/AboutUs",
        DepositProtection: "openflo/homesetup/:id",
        "Create Alert": "create-alert/:alert_id",
        RentalOffers: "openflo/rental-offer/:id",
        "Custodial Account": "tds/custodial/account",
        "Custodial Activation": "tds/activation",
        "TDS Create Password": "tds/activation/createPassword",
        "Custodial Dashboard": "tds/custodial/tenancies/:dan/:tenancy_id",
        IdAndReference: "openflo/id-reference/:id",
        "Shared Doc": "shared-doc",
        PreReferencingLite: "pre-referencing/lite",
        TenantDetails: "openflo/tenant-details/:id",
        HoldingDeposit: "openflo/holding-deposit/:id",
        LandingPageTlyfeObxRmsil: "agent-flo/:email",
        "Custodial Repayment": "tds/custodial/repayment/:dan/:tenancy_id/:case_id",
        "Notification Details": "notification-details",
        "PreQualification": "PreQualification",
        "PreQualificationAffordability": "PreQualification/Affordablity",
        "PreQualificationRightToRent": "PreQualification/RightToRent",
        "PreQualificationIdAndVerification": "PreQualification/IdAndVerification",
        "PreQualificationPreReference": "PreQualification/PreRefrence",
        "Notification Settings": "notification-settings",
        "Registration Confirmed": "registration-confirmed",
        // RentalInfo: "openflo/rental-offers/rental-info/:id",
        "Property Details": "property-details/:property_id",
        "Custodial Change Password": "tds/custodial/change-password",
        Detailmaintenance: "detailMaintenance/:maintenance_id",
        "Password Reset - Confirmation": "password-reset-confirmation",
        "Custodial Respond": "/tds/custodial/respond/:dan/:tenancy_id/:case_id",
        "ConfirmTenancy": "/tds/custodial/confirm-tenancy/:tenancy_id/:dan",
        "View Custodial Repayment": "/tds/custodial/view/:dan/:tenancy_id/:case_id",
        "Custodial Add International Bank Account": "tds/custodial/add-international-account",
        NonFccUpload: "openflo/id-reference/non-tl-fcc-upload/:flowId/:otp/:fcc_applicant_id/:document_id",
        [isToken ? "Dashboard" : "tlyfe - powered by OpenBrix"]: "",
        Dashboard: "my-dashboard",
        ErrorPage: "*",
        Search: "home/search",
        landingSearchFrom: "home/:from",
        landingSearch: "property-search",
        Charity: "charity",
        landingTlyfe: "tlyfe",
        landingTlyfeFrom: "tlyfe/:from",
        Register: "register",
   
        RegisterEmailFrom: "register/:from",
        "Sign in": "sign-in",
        "Sign infrom": "sign-in/:from",
        "Tds Login": "tds/login",
        "About Us": "about-us",
        "About UsFrom": "about-us/:from",
        "Money Centre": "money-centre",
        landingOpenflo: "openflo",
        landingOpenfloFrom: "openflo/:from",
        "My Alerts": "my-alerts",
        LandingHome: "landinghome",
        "Contact Us": "contact-us",
        Maintenance: "maintenance",
        LandingPageTlyfe: "landing",
        TlyfeLanding: "tlyfeLanding",
        "Marketplace": "marketplace",
        SearchFilter: "search-filter",
        insuredDeposit: "tds/insured",
        CreditHistory: "credithistory",
        "Our Services": "Our-Services",
        "User Details": "User-Details",
        "Document Share Center": "share-centre",
        LandingPageTlyfeObx: "agent-flo",
        PropertyDocs: "propertydoc/:id",
        RegisterEmail: "register/:email",
        RentalDocs: "rental-docs",
        "Market Details": "market-details",
        IdVerification: "id-verification",
        DepositInfo: "openflo/movein/:id",
        PreReferencing: "pre-referencing",
        ReviewAST: "openflo/review-ast/:id",
        "Credit Builder": "credit-builder",
        "Past Issues": "past-issues/:item",
        "Password Reset": "password-reset",
        "TDS Search Deposit": "tds/deposit-search",
        "Custodial Tenancies": "tds/custodial",
        DashboardNew: "id-flo-new",
        "Rental Passport": "rental-passport",
        "Change Password": "Change-Password",
        "Saved Properties": "saved-properties",
        LandingPrequalification: "landing/Prequalification",
        LandingPrequalificationFrom: "landing/Prequalification/:from",
        LandingAgent: "landing/Agent",
        LandingAgentFrom: "landing/Agent/:from",
        LandingInfoHub: "landing/InfoHub",
        LandingInfoHubDetails: "landing/InfoHub/:id",
        LandingCredit: "landing/CreditBuilder",
        LandingCreditFrom: "landing/CreditBuilder/:from",
        LandingTeam: "landing/Team",
        LandingInsurance: "landing/Insurance",
        LandingInsuranceFrom: "landing/Insurance/:from",
        LandingContactUs: "landing/Contact",
        LandingAbout: "landing/AboutUs",
        DepositProtection: "openflo/homesetup/:id",
        "Create Alert": "create-alert/:alert_id",
        RentalOffers: "openflo/rental-offer/:id",
        "Custodial Account": "tds/custodial/account",
        "Custodial Activation": "tds/activation",
        "Forget Password": "tds/forgetpassword/:email",
        "TDS Create Password": "tds/activation/createPassword",
        "Custodial Dashboard": "tds/custodial/tenancies/:dan/:tenancy_id",
        IdAndReference: "openflo/id-reference/:id",
        "Shared Doc": "shared-doc",
        PreReferencingLite: "pre-referencing/lite",
        TenantDetails: "openflo/tenant-details/:id",
        HoldingDeposit: "openflo/holding-deposit/:id",
        LandingPageTlyfeObxRmsil: "agent-flo/:email",
        "Custodial Repayment": "tds/custodial/repayment/:dan/:tenancy_id/:case_id",
        "Notification Details": "notification-details",
        PreQualification: "PreQualification",
        PreQualificationAffordability: "PreQualification/Affordablity",
        PreQualificationRightToRent: "PreQualification/RightToRent",
        PreQualificationIdAndVerification: "PreQualification/IdAndVerification",
        PreQualificationTenantInfo: "PreQualification/TenantInfo",
        PreQualificationPreReference: "PreQualification/PreRefrence",
        "Notification Settings": "notification-settings",
        "Registration Confirmed": "registration-confirmed",
        // RentalInfo: "openflo/rental-offers/rental-info/:id",
        "Property Details": "property-details/:property_id",
        "Custodial Change Password": "tds/custodial/change-password",
        Detailmaintenance: "detailMaintenance/:maintenance_id",
        "Password Reset - Confirmation": "password-reset-confirmation",
        "Custodial Respond": "/tds/custodial/respond/:dan/:tenancy_id/:case_id",
        ConfirmTenancy: "/tds/custodial/confirm-tenancy/:tenancy_id/:dan",
        "View Custodial Repayment": "/tds/custodial/view/:dan/:tenancy_id/:case_id",
        "Custodial Add International Bank Account": "tds/custodial/add-international-account",
        NonFccUpload:
          "openflo/id-reference/non-tl-fcc-upload/:flowId/:otp/:fcc_applicant_id/:document_id",
        // TenantInsurance: "tenant-insurance",
        Rewards:"/tlyfe-rewards",
        Playground:'/openbrix-design-system',
        CreditBuilderLanding:'/creditbuilder',
        InsuranceLanding:'/tenant-insurance',
        RentReadyLanding:'/rent-ready',
        Introduction:'/introduction',
        tlyfePRO:"/landing/tlyfePRO",
        tlyfe:"/landing/tlyfe",
        home:"/landing/home",
        'ArchiveDocuments':'/mydoc/archive-document',
        "TlyfeProtect": "tlyfe-protect",
      },
    },
    // async getInitialURL() {
    //   const url = await Linking.getInitialURL();
    //   console.log('URL', url);
    //   if (typeof url === 'string') {
    //     return url;
    //   }
    //   const message = await messaging().getInitialNotification();
    //   const deeplinkURL =
    //     initialRouteName == 'Main' && buildDeepLinkFromNotificationData(message?.data);
    //   if (typeof deeplinkURL === 'string') {
    //     return deeplinkURL;
    //   }
    // },

    // subscribe(listener) {
    //   const onReceiveURL = ({ url }) => listener(url);
    //   const linkingSubscription = Linking.addEventListener('url', onReceiveURL);
    //   const unsubscribe = messaging.onNotificationOpenedApp((remoteMessage) => {
    //     const url = buildDeepLinkFromNotificationData(remoteMessage.data);
    //     if (typeof url === 'string') {
    //       listener(url);
    //     }
    //   });

    //   return () => {
    //     linkingSubscription.remove();
    //     unsubscribe();
    //   };
    // },
  };

  return (
    <NavigationContainer
      linking={linking}
      initialRouteName={getInitialRoutes()}
      backBehavior="history"
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        if (hasCookiesPermission) {
          const currentRouteName = navigationRef.current.getCurrentRoute().name;
          setIsReadyFirstTime(currentRouteName);
          ReactGA.send({
            hitType: "pageview",
            page: "/" + linking?.config?.screens[currentRouteName],
            title: currentRouteName,
          });
        }
      }}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;
        const currentTimeStamp = Date.now();
        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK 
          // Analytics.setCurrentScreen(currentRouteName);

          //commented out until tom or someone else can finish the backend for this
          //const duration = (currentTimeStamp - previousTimeStamp) / 1000.0;
          //dispatch(
          //  appUserTracking({
          //    new_page: currentRouteName,
          //    app_user_id: userId,
          //    prev_page: previousRouteName,
          //    time_on_prev_page_seconds: (duration <= 10) ? duration : Math.round(duration),
          //  }),
          //);
          previousTimeStamp = currentTimeStamp;
          if (hasCookiesPermission) {
            ReactGA.send({
              hitType: "pageview",
              page: "/" + linking?.config?.screens[currentRouteName],
              title: currentRouteName,
            });
          }
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
        previousTimeStamp = currentTimeStamp;
      }}
    >
      <Stack.Navigator initialRouteName={getInitialRoutes()} backBehavior="history">
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingPageTlyfeObx"
          component={LandingPageTlyfeObx}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Sign in"
          component={LoginLanding}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Sign infrom"
          component={LoginLanding}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="HandoverPage"
          component={HandoverPage}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Search"
          component={SearchRenterScreen}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Marketplace"
          component={Market}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="TlyfeProtect"
          component={TlyfeProtect}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Market Details"
          component={marketDetails}
        />
                <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="CheckOut"
          component={CheckOut}
        />
     <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="ForwardMessege"
          component={ForwardMessege}
        />


        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Password Reset"
          component={ResetPassword}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Change Password"
          component={ChangePassword}
        />
             <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Forget Password"
          component={CustodialForgetPassword}
        />
        
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Password Reset - Confirmation"
          component={ResetMailConfirmation}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Document Share Center"
          component={SharedDoc}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="ArchiveDocuments"
          component={ArchiveDocuments}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="RentalDocs"
          component={MyDocumentsList}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Add Documents"
          component={AddDocument}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="My Account"
          component={MyAccount}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="My Account To"
          component={MyAccount}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="ChildScreen"
          component={ChildScreen}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Notification Settings"
          component={notificationSettings}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Registration Confirmed"
          component={AgentConfirmation}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="PropertyDocs"
          component={PropertyDocs}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="HoldingDeposit"
          component={HoldingDeposit}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="DepositInfo"
          component={DepositInfo}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="DepositProtection"
          component={DepositProtection}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="NonFccUpload"
          component={NonFCCUpload}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="Register"
          component={Home}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="RegisterEmail"
          component={Home}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="RegisterEmailFrom"
          component={Home}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="tlyfe - powered by OpenBrix"
          component={Dashboard}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Saved Properties"
          component={SavedProperties}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="RentalLandingScree"
          component={SearchFilter}
        />
            <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="TdsScoctland"
          component={TdsScoctland}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="User Details"
          component={AboutUs}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="DummyScreen"
          component={DummyScreen}
        />

        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="Property Details"
          component={PropertyDetails}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Create Alert"
          component={CreateAlertScreen}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Rental Passport"
          component={RentalPortal}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Contact Us"
          component={ContactUs}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="My Tenancy"
          component={Home}
        />
           <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="My Tenancy Home"
          component={OpenFlow}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="TenantDetails"
          component={TenantDetails}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="IdAndReference"
          component={IdAndRef}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Shared Doc"
          component={SharedDoc}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="ReviewAST"
          component={ReviewAST}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="RentalOffers"
          component={RentalOffers}
        />

        {/* <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="RentalInfo"
          component={RentalInfo}
        /> */}

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Maintenance"
          component={ReportIssue}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="My Alerts"
          component={MyAlerts}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Past Issues"
          component={PastIssues}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Credit Builder"
          component={moreInformation}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Notification Details"
          component={NotificationDetails}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="MyPropertyScreen"
          component={MyProperty}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Legal Bits"
          component={LegalScreen}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Our Services"
          component={ServiceScreen}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="MediaHub"
          component={MediaHub}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="MediaHubDetailsPage"
          component={MediaHubDetailsPage}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Charity"
          component={Charity}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Dashboard"
          component={Dashboard}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingContactUs"
          component={LandingContactUs}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingAbout"
          component={LandingAbout}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreQualification"
          component={PreQualification}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreQualificationAffordability"
          component={PreQualificationAffordability}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreQualificationRightToRent"
          component={PreQualificationRightToRent}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreQualificationIdAndVerification"
          component={PreQualificationIdAndVerification}
        />
              <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreQualificationTenantInfo"
          component={PreQualificationTenantInfo}
        />
        
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreQualificationPreReference"
          component={PreQualificationPreReference}
        />
           <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Rewards"
          component={Rewards}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreReferencing"
          component={LandingPage}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="PreReferencingLite"
          component={PreReferencingLite}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="IdVerification"
          component={IdVerification}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="CreditHistory"
          component={CreditHistory}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="DetailMaintenance"
          component={DetailMaintenance}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Money Centre"
          component={Zopa}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingPageTlyfe"
          component={LandingPageTlyfe}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="TlyfeLanding"
          component={TlyfeLanding}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="DashboardNew"
          component={DashboardNew}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingHome"
          component={LandingOpenBrix}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="landingSearch"
          component={LandingOpenBrix}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="landingSearchFrom"
          component={LandingOpenBrix}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="About Us"
          component={LandingContact}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="About UsFrom"
          component={LandingContact}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
            title: "Tyfe - the tenant lifecycle app",
          }}
          name="landingTlyfe"
          component={LandingTlyfe}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
            title: "Tyfe - the tenant lifecycle app",
          }}
          name="landingTlyfeFrom"
          component={LandingTlyfe}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
            title: "Openflo - the next generation tenant onboarding solution",
          }}
          name="landingOpenflo"
          component={LandingOpenflo}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
            title: "Openflo - the next generation tenant onboarding solution",
          }}
          name="landingOpenfloFrom"
          component={LandingOpenflo}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="TDS Search Deposit"
          component={searchDeposit}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Tds Login"
          component={TdsLogin}
        />
             <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="tdsLocation"
          component={tdsLocation}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="insuredDeposit"
          component={insuredDeposit}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Dashboard"
          component={custodialDashboard}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Tenancies"
          component={custodialTenancies}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Account"
          component={custodialAccount}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Repayment"
          component={custodialRepayment}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Add International Bank Account"
          component={custodialAddInternationalBankAccount}
        />

        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Change Password"
          component={custodialChangePassword}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Activation"
          component={custodialActivation}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Custodial Respond"
          component={custodialRespond}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="Confirm Tenancy"
          component={ConfirmTenancy}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="View Custodial Repayment"
          component={viewcustodialRepayment}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="TDS Create Password"
          component={createPassword}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingPrequalification"
          component={LandingPrequalification}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingPrequalificationFrom"
          component={LandingPrequalification}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingInsurance"
          component={LandingInsurance}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingInsuranceFrom"
          component={LandingInsurance}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingCredit"
          component={LandingCredit}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingCreditFrom"
          component={LandingCredit}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingAgent"
          component={LandingAgent}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingAgentFrom"
          component={LandingAgent}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingInfoHub"
          component={LandingInfoHub}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingInfoHubDetails"
          component={LandingInfoHubDetails}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="LandingTeam"
          component={LandingTeam}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
          name="TenantInsurance"
          component={TenantInsurance}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="Playground"
          component={Playground}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="CreditBuilderLanding"
          component={CreditBuilderLanding}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="InsuranceLanding"
          component={InsuranceLanding}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="RentReadyLanding"
          component={RentReadyLanding}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="home"
          component={TlyfeproOpenbrixLanding}
        />

           <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="tlyfe"
          component={TlyfeproLanding}
        />

<Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="tlyfePRO"
          component={TlyfeproLandingHome}
        />
        
          <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="Introduction"
          component={Introduction}
        />
          <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="LoginLanding"
          component={LoginLanding}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="FccHome"
          component={FccHome}
        />
          <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="FccChooseAddress"
          component={FccChooseAddress}
        />
         <Stack.Screen
          options={{
            headerShown: false,
            gestureEnabled: true,
          }}
          name="FccProgression"
          component={FccProgression}
        />
      </Stack.Navigator>

      {props.tenantLoading || props.Loading || props.tdsLoading || props.refLoading || props.ladgerLoading || props.loginLoading || props.resetPasswordLoading || props.signupLoading ? (
        <Loader
          loading={props.tenantLoading || props.Loading || props.tdsLoading || props.refLoading || props.ladgerLoading || props.loginLoading || props.resetPasswordLoading || props.signupLoading}/>
      ) : null}
    </NavigationContainer>
  );
}

const mapStateToProps = (state) => ({
  appReducer: state.appReducer,
  tdsLoading: state.tds.tdsLoading,
  tenantLoading: state.tenant.tenantLoading,
  Loading: state?.notificationReducer?.Loading,
  refLoading: state?.preReferencing?.isLoading,
  ladgerLoading: state?.ladgerReducer?.ladgerLoading,
  loginLoading:state?.authReducer?.loginLoading,
  resetPasswordLoading:state?.authReducer?.resetPasswordLoading,
  signupLoading:state?.authReducer?.signupLoading
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
