import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import getPlatformType from './../../helpers/getPlatform';
import { useTheme } from '@emotion/react';
import { FlatList, View } from 'react-native';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { getSubscriptionList } from '../../redux/actions/app';
import moment from 'moment';
import CustomPopUp from '../../commonComponents/customPopUp/customPopUp.web';
import { cancelSubscription, startPayment, updatePayment } from '../../redux/actions/Ladger';
import { navigateAction } from '../../helpers/navigationHelpers';
import { useNavigation } from '@react-navigation/native';
import CheckoutForm from '../idVerification/checkoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPEKEY } from '../../helpers/connectionHelpers';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Wrapper } from '../identifyAndFinance/stylesNew';

const layoutType = getPlatformType();

const SubscriptionList = (props) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading,setLoad]=useState(false);
  const {colors}=useTheme();
  const navigation=useNavigation();
  const [popUpIsVisible, setpopUpIsVisible] = useState(false);
  const [showCheckOutForm, setShowCheckOutForm] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  let stripePromise = loadStripe(STRIPEKEY);




  useEffect(() => {
    getSubscriptionListData();
  }, []);
  const getSubscriptionListData=()=>{
    setLoad(true);
    dispatch(
        getSubscriptionList((data) => {
          if(data?.status){
              setList(data?.data)
          }
          setLoad(false)
        })
      );
  }
  const cancelSubscription1 = () => {
    setpopUpIsVisible(false);
    dispatch(
      cancelSubscription(() => {
        getSubscriptionList();
      })
    );
  };
  const updateSubscription=()=>{
    dispatch(
        updatePayment((response) => {
            console.log("___",response)
            window.open(response?.url, '_blank');
        //   if (window?.ReactNativeWebView && isMobilePaymentRequire) {
        //     window.ReactNativeWebView.postMessage(
        //       JSON.stringify({
        //         val: {
        //           type: 'payment',
        //           clientSecret: response?.clientSecret,
        //         },
        //       })
        //     );
        //   } else {
        //     setClientSecret(response.clientSecret);
        //     setTimeout(
        //       function () {
        //         setShowCheckOutForm(true);
        //       }.bind(this),
        //       1000
        //     );
        //   }
        })
      );

  }
  const renderPaymentSuccess = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Image
          source={require('./../../assets/images/Progress.svg')}
          resizeMode="contain"
          style={{
            height: 50,
            width: 50,
            alignSelf: 'center',
            marginTop: 25,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={colors.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
        >
          Payment successful!
        </ODSText>
        <Typography
          align={'center'}
          top={'10px'}
          size={'20px'}
          bold={'900'}
          color={colors.theme.primary}
        ></Typography>
        <ODSText
          textAlign={'center'}
          color={colors.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'sm'}
        >
          Your payment is being applied to your account, please check again in a few moments.
        </ODSText>
        <ODSButton
            lIcon={'TB-Rotating'}
            restStyle={{ marginTop: 20, alignSelf:"center"}}
           
            disabled={false}
            onPress={() => {
              dispatch(
                getPaymentStatus((cb) => {
                  if (cb) {
                    setShowCheckOutForm(false);
                    loadData();
                  }
                })
              );
            }}
          >
           Refresh
          </ODSButton>
       
      </View>
    );
  };
  const renderPaymentFailed = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Image
          source={require('./../../assets/images/failed.png')}
          resizeMode="contain"
          style={{
            height: 50,
            width: 50,
            alignSelf: 'center',
            marginTop: 25,
          }}
        />
         <ODSText
          textAlign={'center'}
          color={colors.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
        >
          Payment failed!
        </ODSText>
        <Typography
          align={'center'}
          top={'10px'}
          size={'20px'}
          bold={'900'}
          color={colors.theme.primary}
        ></Typography>
        <ODSText
          textAlign={'center'}
          color={colors.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'sm'}
        >
          Ooops! There appears to be a problem, your payment has failed.
        </ODSText>
        <ODSButton
            lIcon={'TB-Rotating'}
            restStyle={{ marginTop: 20, alignSelf:"center"}}
           type={"secondary"}
            disabled={false}
            onPress={() =>   setShowCheckOutForm(false)}
          >
           Try again
          </ODSButton>
      </View>
    );
  };
  const renderPayments = () => {
    return (
      <Wrapper>
        {showCheckOutForm == 'success' ? (
          renderPaymentSuccess()
        ) : showCheckOutForm == 'failed' ? (
          renderPaymentFailed()
        ) : (
          <View style={{ flexDirection: 'column', width: layoutType == 'phone' ? '100%' : 400 }}>
            <ODSText
              textAlign={'flex-start'}
              color={colors.neutral[700]}
              type={'button'}
              marginTop={20}
            >
              Checkout
            </ODSText>

            <View
              style={{
                padding: 24,
                borderColor: '#1B8A8C',
                borderWidth: 1.3,
                borderRadius: 8,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <ODSText color={colors.neutral[700]} type={'sm'}>
                  Credit Builder
                </ODSText>
                <ODSText color={colors.neutral[700]} type={'sm'}>
                  £8 (inc. VAT)
                </ODSText>
              </View>
              <ODSText
                textAlign={'flex-start'}
                color={colors.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                Total cost: £8
              </ODSText>
            </View>
                <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                <CheckoutForm
                    theme={colors.main.blue}
                    onSuccessToken={(success) => {
                    if (success) {
                        setShowCheckOutForm('success');
                    } else {
                        setShowCheckOutForm('failed');
                    }
                    }}
                />
                </Elements>
          </View>
        )}
      </Wrapper>
    );
  };
// if(showCheckOutForm){
//     return renderPayments();
// }
  return <View>
    {loading && <ODSText alignSelf='center' marginTop={20} type='md' color={colors.text.primary}>Loading.....</ODSText>}
    {!loading &&!list?.cl_data && !list?.vb_data && <ODSText alignSelf='center' marginTop={20} type='h6' color={colors.text.primary}>You don't have any subscription history!</ODSText>}
   {list?.cl_data &&
    <View style={{padding:32,borderWidth:1,borderRadius:12,marginVertical:12,borderColor:colors.other['border-neutral']}}>
    <ODSText type='h6' color={colors.text.primary} marginBottom={16}>Credit Builder</ODSText>
    {list?.cl_stripe_data?.status=="canceled"?<>
        <ODSText type='md' color={colors.text.primary}>This subscription has been <ODSText type='md-bold' color={colors.feedback.error[500]}>cancelled.</ODSText> If you wish to reinstate it please go to Credit Builder section.</ODSText>
        <ODSButton
          restStyle={{ marginTop:32 }}
          themeColor={colors.main.blue}
          type="primary"
          disabled={false}
          onPress={()=>navigateAction(navigation,"Credit Builder")}
        >Go to Credit Builder</ODSButton>
    </>
    :
<>
    <ODSText type='md-bold' color={colors.text.primary}>Subscription renews on</ODSText>
    <ODSText type='md' color={colors.text.primary} marginTop={4}>{moment(list?.cl_data?.current_period_end).format('DD/MM/YYYY')}</ODSText>
    <ODSText type='md-bold' color={colors.text.primary} marginTop={12}>Payment Method</ODSText>
    <ODSText type='md' color={colors.text.primary} marginTop={4}>xxxx xxxx xxxx {list?.cl_payment_method?.card?.last4}</ODSText>
    <ODSButton
          restStyle={{ marginTop:32 }}
          themeColor={colors.main.blue}
          type="primary"
          disabled={false}
          onPress={updateSubscription}
        >Update payment method</ODSButton>
     <ODSButton
          restStyle={{marginTop:12 }}
          themeColor={colors.main.red}
          type="secondary"
          disabled={false}
          onPress={()=>setpopUpIsVisible(true)}
        >Cancel Subscription</ODSButton>
        </>}


  </View>}
  {list?.vb_data &&
  <View style={{padding:32,borderWidth:1,borderRadius:12,marginVertical:12,borderColor:colors.other['border-neutral']}}>
   <ODSText type='h6' color={colors.text.primary} marginBottom={16}>tlyfe Rewards</ODSText>
    <ODSText type='md-bold' color={colors.text.primary}>Subscription renews on</ODSText>
    <ODSText type='md' color={colors.text.primary} marginTop={4}>{moment(list?.vb_data?.current_period_end).format('DD/MM/YYYY')}</ODSText>
    <ODSText type='md-bold' color={colors.text.primary} marginTop={12}>Payment Method</ODSText>
    <ODSText type='md' color={colors.text.primary} marginTop={4}>xxxx xxxx xxxx {list?.vb_payment_method?.card?.last4}</ODSText>
    <ODSButton
          restStyle={{ marginTop:32 }}
          themeColor={colors.main.blue}
          type="primary"
          disabled={false}
          onPress={updateSubscription}
        >Update payment method</ODSButton>
     <ODSButton
          restStyle={{marginTop:12 }}
          themeColor={colors.main.red}
          type="secondary"
          disabled={false}
        //   onPress={onPress}
        >Cancel Subscription</ODSButton>
  </View>}
  <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={'Sure'}
        secondaryButtonText={'Back'}
        primaryText={'Cancel Subscriptions ?'}
        secondaryText={' Are you sure you want to Cancel Subscriptions?'}
        onPrimaryButtonPress={() => {
          cancelSubscription1();
        }}
        note={
          'that by cancelling this product your monthly rent will not be recorded on your credit file with UK largest credit reference agencies. Many banks and financial institutions use these agencies when giving you credit and loans. This can materially impact your interest on credit cards and loans. Furthermore due to open banking regulation, once cancelled we would require 45 days to reconnect to your bank.'
        }
        onSecondaryButtonPress={() => {
          setpopUpIsVisible(false);
        }}
      />
      
  </View>
};
export default SubscriptionList;


