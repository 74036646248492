import React from 'react';
import {
  StyleSheet,
  View,
  Image,
} from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import ODSButton from '../../atoms/ODSButton';
import { getTokens } from '../../../design-tokens/color-tokens';
import ODSKnob from '../../atoms/ODSKnob';

const PropertyBox = ({
  restStyle,
  btnTheme,
  onEmailPress,
  uri,
  name,
  address,
  agentName,
  onCallPress,
  type,
  knobCount
}) => {
  const themeObj = getTokens('light').colors;
  return (
    <View style={[styles(themeObj).containerStyle, restStyle]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ width: '70%' }}>
          <ODSText textAlign={'left'} marginTop={6} color="black" type={'h5'}>
            {knobCount == 100 ? 'Congrats!' : type}
          </ODSText>
          {knobCount == 100 ? <ODSText marginTop={6} marginBottom={12} color="black" type={'sm'}>
            {type == "Move In" ? 'You moved in' : 'You renewed your tenancy.'}
          </ODSText> :
            <ODSText marginTop={6} color="black" type={'sm'}>
              {address}
            </ODSText>}
        </View>
        <ODSKnob
          size={55}
          bgColor={type == "Renewal" ? themeObj.theme.pink : themeObj?.button.main}
          pgColor={"white"}
          strokeWidth={3}
          progressPercent={knobCount}
          text={knobCount + "%"}
          color={type == "Renewal" ? themeObj.theme.pink : themeObj?.button.main}
          restStyle={{ margin: 0 }}
        />
      </View>

      <View style={{ width: '100%', flexDirection: 'row', marginTop: 20 }}>
        <View style={{ marginRight: 16 }}>
          <Image
            source={
              uri
                ? {
                  uri: uri,
                }
                : require('./../../../images/Bitmap.png')
            }
            resizeMode="cover"
            style={{ width: 122, height: 83 }}
          />
        </View>
        <View style={{ justifyContent: 'center', flex: 1 }}>
          <ODSText marginTop={6} color="black" type={'button'}>
            Agent: <ODSText color="black" type={'md'}>{agentName ? agentName : '-'}</ODSText>
          </ODSText>
        </View>
      </View>
      {knobCount == 100 && <ODSText marginTop={14} color="black" type={'sm'}>
        {address}
      </ODSText>}

      <View style={{ marginTop: 32, flexDirection: 'row', justifyContent: 'space-between' }}>

        <View style={{ flex: 0.45 }}>
          <ODSButton
            rIcon={'TBPhone'}
            restStyle={{ width: '100%', height: 48 }}
            type="secondary"
            disabled={false}
            themeColor={btnTheme}
            onPress={onCallPress}>
            Call
          </ODSButton>
        </View>
        <View style={{ flex: 0.45 }}>
          <ODSButton
            rIcon={'email'}
            restStyle={{ width: '100%', height: 48 }}
            type="secondary"
            disabled={false}
            themeColor={btnTheme}
            onPress={onEmailPress}>
            Email
          </ODSButton>
        </View>
      </View>
    </View>
  );
};

const styles = (theme: object) =>
  StyleSheet.create({
    containerStyle: {
      width: '100%',
      borderRadius: 12,
      shadowColor: '#101828',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      elevation: 2,
      backgroundColor: theme.neutral['white'],
      padding: 20,
      borderWidth: 1,
      borderColor: '#D0D6DD',
    },
  });

export default PropertyBox;
