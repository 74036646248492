import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, View } from "react-native";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import Wrapper from "./Wrapper";
import YoutubePlayer from '../../commonComponents/youtubePlayer/youtubePlayer';
import { Typography } from "../tds/style";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton/index.web";
import { COLORS } from "../../constants/colorPallete";
import { ODSInput } from "../../../openbrix-design-system/ui/atoms/ODSInput";
import { useDispatch } from "react-redux";
import { contactUs } from "../../redux/actions/auth";
import { showToaster } from "../../redux/actions/toast";
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/open-web.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/open-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/open-mobile.png');
const Picture = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/tlyfepro.png');
const FooterWeb = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-web.png');
const FooterTab = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-tab.png');
const FooterMobile = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-mobile.png');
const clouds = require('../../../openbrix-design-system/images/tlyfe-pages/hangingCloud.png');
const mockVideo = require('../../../openbrix-design-system/images/tlyfe-pages/tlyfe-video-mock-mobile.png');
const profile = require('../../../openbrix-design-system/images/icons/profile.png');
const sheild = require('../../../openbrix-design-system/images/icons/sheild.png');
const pound = require('../../../openbrix-design-system/images/icons/pound.png');
const face = require('../../../openbrix-design-system/images/icons/face.png');

const bank = require('../../../openbrix-design-system/images/icons/bank.png');
const chat = require('../../../openbrix-design-system/images/icons/chat.png');
const ins = require('../../../openbrix-design-system/images/icons/ins.png');
const market = require('../../../openbrix-design-system/images/icons/market.png');
const whoWeAreWeb = require('./whoweare.png');
const whoWeAreTab = require('./whoweare-tab.png');
const whoWeAreMobile = require('./whoweare-mobile.png');





const multiplecheck = require('../../../openbrix-design-system/images/icons/multiplecheck.png');

const FEATURED = [

  {
    name: "Marketplace",
    desc: "Explore services tailored for tenants.",
    icon: market,
  },
  {
    name: "Credit Builder",
    desc: "Enhance your credit score as you rent.",
    icon: bank,
  },
  {
    name: "Tenant Insurance",
    desc: "Comprehensive coverage at your fingertips.",
    icon: ins,
  },
  {
    name: "Information Centre",
    desc: "Access essential resources and support.",
    icon: chat
  },
];
const tiles = [
  {
    name: "John Hards",
    title: "Chairman",
    image: require("./images/team/jh.png"),
  },
  {
    name: "Adam Pigott",
    title: "CEO",
    image: require("./images/team/ap.png"),
  },
  {
    name: "Shahad Choudhury",
    title: "CIO",
    image: require("./images/team/sh.png"),
  },
  {
    name: "Sandra Donovan",
    title: "COO",
    image: require("./images/team/sd.png"),
  },
  {
    name: "Jamie Grierson",
    title: "Head of Sale",
    image: require("./images/team/jg.png"),
  }
];
const logo = [
  {

    image: require("./images/logo/tds.png"),
    style: { width: 110, height: 47 }
  },
  {
    image: require("./images/logo/Cl.png"),
    style: { width: 189, height: 39 }
  },
  {
    image: require("./images/logo/Inventory.png"),
    style: { width: 155, height: 39 }
  },
  {
    image: require("./images/logo/Safe.png"),
    style: { width: 114, height: 45 }
  },
  {
    image: require("./images/logo/Yoti.png"),
    style: { width: 95, height: 43 }
  },
  {
    image: require("./images/logo/Fcc.png"),
    style: { width: 166, height: 39 }
  },
  {
    image: require("./images/logo/Home.png"),
    style: { width: 166, height: 39 }
  },
];
const BenefitItem = ({ icon, title, description }) => (
  <View style={{ marginTop: 20, flexDirection: "row" }}>
    <Image style={{ height: 30, width: 30, marginRight: 10 }} resizeMode="contain" source={icon} />
    <View style={{ flexDirection: "column", flex: 1 }}>
      <ODSText type="button">{title}</ODSText>
      <ODSText type="sm" style={{ marginTop: 14 }}>{description}</ODSText>
    </View>
  </View>
);

const TlyfeproOpenbrixLanding = () => {
  const { colors } = useTheme();
  const layout = getScreenType();

  const headerImage = layout === "web" ? HeaderWeb : layout === "tab" ? HeaderTab : HeaderMobile;
  const whoWeAre = layout === "web" ? whoWeAreWeb : layout === "tab" ? whoWeAreTab : whoWeAreMobile;

  const footerImage = layout === "web" ? FooterWeb : layout === "tab" ? FooterTab : FooterMobile;

  const sectionStyles = layout === "phone"
    ? { flexDirection: "column", alignItems: "center", paddingHorizontal: "6%", marginBottom: 30 }
    : { flexDirection: "row", justifyContent: "center", paddingVertical: 100 };
  const windowWidth = Dimensions.get('window').width;
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });
  const [chekForValidate, setCheckForValidate] = useState(false);
  const validateForm = () => {
    let valid = true;
    let errors = {
      name: "",
      email: "",
      subject: "",
      message: ""
    };
    console.log("formformform", form?.name)
    if (!form?.name) {
      errors.name = 'Invalid Name ';
      valid = false;
    }
    if (!form?.email) {
      errors.email = 'Invalid email address.';
      valid = false;
    }
    if (!form?.subject) {
      errors.subject = 'Please enter subject.';
      valid = false;
    }
    if (!form?.message) {
      errors.message = 'Please enter valid message.';
      valid = false;
    }
    setErrors(errors);
    return valid;
  };
  useEffect(() => {
    if (chekForValidate) {
      validateForm()
    }
  }, [form]);
  const dispatch = useDispatch()
  const _onSave = () => {
    setCheckForValidate(true)
    if (validateForm()) {
      dispatch(contactUs({
        email: form.email,
        topic: form.subject,
        name: form.name,
        message: form.message,
      }))
      setCheckForValidate(false)
      setForm({
        name: "",
        email: "",
        subject: "",
        message: ""
      });
      return true;
    } else {
      return false;
    }
  };

  return (
    <Wrapper
      theme={colors.transparency.blue}
      drawerColor={colors.main.blue}
      bodyStyle={{ alignSelf: "flex-start" }}
      type="login"
      headerTitle=""
      isOpenBrixLogo
      headerBody=""
      headerImage={headerImage}
      headerBlacFont
      rightImage={Picture}
      leftText={""}
      footerImage={footerImage}
      showClouds
      tlyfeProMenu
    >
      <View style={{ position: "relative", backgroundColor: "#09B2C2" }}>
        <View style={{ ...sectionStyles, alignItems: "center" }}>
          <View
            style={layout == "phone" ? { paddingTop: 20 } : {
              flexDirection: layout == 'web' ? 'row' : 'column',
              alignSelf: 'center',
            }}
          >
            <View
              style={layout == "phone" ? {
                width: "100%",
                borderRadius: 10,
                backgroundColor: '#fff',
                justifyContent: "center",
                alignItems: "center",
                padding: 20,
                marginBottom: 20
              } : [
                {
                  width: 400,
                  borderRadius: 10,
                  margin: 20,
                  backgroundColor: '#fff',
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 20
                },
              ]}
            >

              <ODSText type="button">LOGIN</ODSText>
              <View style={{
                height: 0,
                width: 50,
                borderTopColor: colors.main.purple["400"],
                borderTopWidth: 3,
                marginTop: 5
              }} />

              <ODSText marginTop={20} textAlign={"center"} type="h6">Are you a tenant? We just moved!</ODSText>
              <ODSText marginTop={20} textAlign={"center"} type="sm"> Please go to tlyfe.co.uk to register or login to your account.</ODSText>


              <ODSButton
                restStyle={{ marginTop: 20, height: 40, }}
                type="primary"
                themeColor={colors.main.purple}
                onPress={() => window.open('https://www.tlyfe.co.uk/', '_blank')}
                disabled={false}
              >
                {'Go to tlyfe.co.uk'}
              </ODSButton>

            </View>
            <View
              style={layout == "phone" ? {
                width: "100%",
                borderRadius: 10,
                backgroundColor: '#fff',
                justifyContent: "center",
                alignItems: "center",
                padding: 20, marginBottom: 20
              } : [
                {
                  width: 400,
                  borderRadius: 10,
                  margin: 20,
                  backgroundColor: '#fff',
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10
                },
              ]}
            >

              <ODSText type="button">LOGIN</ODSText>
              <View style={{
                height: 0,
                width: 50,
                borderTopColor: colors.main.pink["400"],
                borderTopWidth: 3,
                marginTop: 5
              }} />

              <ODSText marginTop={20} textAlign={"center"} type="h6">Are you a tenant? We just moved!</ODSText>
              <ODSText marginTop={20} textAlign={"center"} type="sm"> Please go to tlyfe.co.uk to register or login to your account.</ODSText>


              <ODSButton
                restStyle={{ marginTop: 20, height: 40, }}
                type="primary"
                themeColor={colors.main.pink}
                onPress={() => window.open('https://www.tlyfe.co.uk/', '_blank')}
                disabled={false}
              >
                Go to agent.openbrix.co.uk
              </ODSButton>

            </View>
            </View>
          </View>
        </View>
        <Image source={whoWeAre}
          resizeMode={"contain"}
          style={layout == "phone" ? { width: "100%", height: 2562, backgroundColor: "white" } : { width: "100%", height: 1289, backgroundColor: "white" }}
        />
        <View style={layout === "phone" ?
          { ...sectionStyles, paddingTop: 20, backgroundColor: colors.main.purple["500"],marginTop:30  } :
          { ...sectionStyles, backgroundColor: colors.main.purple["500"],marginTop:30 }}>

          <View style={{ marginLeft: layout === "phone" ? "0%" : "2%", position: "relative" }}>

          </View>
          <View style={layout === "phone" ? { flexDirection: "column" } : { flexDirection: "column" }}>
            <ODSText textAlign={"center"} marginBottom={20} color="white" type="h3">Meet the Team</ODSText>

            <FlatList
              data={tiles}
              horizontal
              contentContainerStyle={{
                justifyContent: "center"
              }}
              style={layout == "web" ? {
                width: "1000%",
              } : {
                flexGrow: 1,
                width: windowWidth,
                alignSelf: "center",
                padding: 20
              }}
              renderItem={({
                item,
                index,
              }) => (
                <View
                  key={index}
                >
                  <Image
                    source={item.image}
                    style={{
                      width: 192,
                      height: 240,
                      marginRight: 10
                    }}
                    resizeMode={"contain"}
                  />
                  <ODSText marginTop={14} color="white" textAlign={"center"} type="button"> {item.name}</ODSText>
                  <ODSText marginTop={6} color="white" textAlign={"center"} type="sm"> {item.title}</ODSText>
                </View>
              )}
            />

          </View>
        </View>
        <View style={layout === "phone" ?
          { ...sectionStyles, paddingTop: 20, backgroundColor: "white" } :
          { ...sectionStyles, paddingVertical: 40, backgroundColor: "white" }}>
          <View style={layout === "phone" ? { flexDirection: "column" } : { flexDirection: "column" }}>
            <ODSText textAlign={"center"} marginBottom={30} color="black" type="h3">OpenBrix is happy to partner with</ODSText>
            <View style={{ flexWrap: "wrap", width: windowWidth, flexDirection: "row", justifyContent: "center" }}>
              {logo.map((item, index) =>
                <Image
                  key={index}
                  resizeMode={"contain"}
                  source={item.image}
                  style={{
                    ...item.style,
                    margin: 16
                  }}
                />)}
            </View>
          </View>
        </View>
        <View style={layout === "phone" ?
          { ...sectionStyles, paddingTop: 20, backgroundColor: "#09B2C2",marginBottom:0,paddingBottom:30 } :
          { ...sectionStyles, paddingVertical: 40, backgroundColor: "#09B2C2" }}>
          {layout == "phone" ? null : <>
            <Image
              style={layout === "phone" ? { height: 100, width: 73, position: "absolute", top: 0, right: 20 } : { height: 100, width: 73, position: "absolute", top: 0, right: 20 }}
              source={clouds}
            />
            <Image
              style={layout === "phone" ? { height: 52, width: 41, position: "absolute", top: 0, left: 40 } : { height: 52, width: 41, position: "absolute", top: 0, left: 40 }}
              source={clouds}
            />
          </>}
          <View style={layout === "phone" ? { flexDirection: "column" } : { flexDirection: "column" }}>
            <ODSText textAlign={"center"} marginBottom={30} color="black" type="h3">Contact Us</ODSText>
            <View style={layout == "web" ? { backgroundColor: colors.other['background-neutral'], borderRadius: 12, padding: 26, width: 500 } : { backgroundColor: colors.other['background-neutral'], borderRadius: 12, padding: 20, width: 340 }}>
              <ODSInput
                value={form.name}
                disabled={false}
                label={"Your name"}
                maxLength={60}
                placeholder=''
                onChange={(e) => {
                  setForm({ ...form, name: e })

                }}
                hints={errors.name ? [{ state: 'error', hint: errors.name }] : []}
              />
              <ODSInput
                value={form.email}
                disabled={false}
                label={"Your email"}
                maxLength={60}
                placeholder=''
                onChange={(e) => {
                  setForm({ ...form, email: e })

                }}
                hints={errors.email ? [{ state: 'error', hint: errors.email }] : []}
              />
              <ODSInput
                value={form.subject}
                disabled={false}
                label={"Subject"}
                maxLength={60}
                placeholder=''
                onChange={(e) => {
                  setForm({ ...form, subject: e })

                }}
                hints={errors.subject ? [{ state: 'error', hint: errors.subject }] : []}
              />
              <ODSInput
                value={form.message}
                disabled={false}
                label={"Message"}
                maxLength={60}
                placeholder=''
                onChange={(e) => {
                  setForm({ ...form, message: e })

                }}
                hints={errors.message ? [{ state: 'error', hint: errors.message }] : []}
              />
              <ODSButton
                restStyle={layout == "web" ? { marginTop: 20, height: 40, width: 200, alignSelf: "flex-end" } : { marginTop: 20, height: 40, }}
                type="primary"
                themeColor={colors.main.blue}
                onPress={() => _onSave()}
                disabled={false}
              >
                {'Send'}
              </ODSButton>
            </View>
          </View>
        </View>
     
    </Wrapper>
  );
};

export default TlyfeproOpenbrixLanding;
