import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { useTheme } from '@emotion/react';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';

const AlertCard = ({ 
  title = 'Property Alerts', 
  description = 'Here you can manage your property alerts', 
  comingSoonText = 'Coming soon!', 
  icon = 'TB-Alerts' 
}) => {
  const { colors } = useTheme();

  return (
    <View style={[styles.shadowBox, { backgroundColor: colors.background }]}>
      <View style={styles.header}>
        <ODSIcon icon={icon} size={24} color={colors.button.main} />
        <ODSText type="h5" style={styles.title} color={colors.text.primary}>
          {title}
        </ODSText>
      </View>
      <ODSText type="sm" style={styles.description} color={colors.text.primary}>
        {description}
      </ODSText>
      <ODSText type="md" style={styles.comingSoon} color={colors.text.primary}>
        {comingSoonText}
      </ODSText>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    flex: 1,
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
    }),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
  },
  title: {
    marginLeft: 12,
  },
  description: {
    textAlign: 'center',
    marginTop: 12,
  },
  comingSoon: {
    textAlign: 'center',
    marginTop: 48,
  },
});

export default AlertCard;
