import { Alert, Platform } from "react-native";
import { types } from "../../actionTypes";
import { SERVER_URL, _get, URL } from "../../../helpers/connectionHelpers";

export const getDummyListing = (bodyofRequest, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.GET_USERLIST_REQUEST });

    _get(
      `${SERVER_URL}${URL.getListFromReqRes}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        
        dispatch({
          type: types.GET_USERLIST_SUCCESS,
          payload: res,
        });
        Alert.alert(res.message);
        navigation.pop();
      },
      (err) => {
        // function to perform on failure
        
        dispatch({ type: types.GET_USERLIST_FAILED, error: err.message });
        Alert.alert(err.message);
      }
    );
  };
};
export const getInvoice = (cb) => {
  return (dispatch) => {
    dispatch({ type: types.GET_INVOICE_REQUSET });

    _get(
      `${SERVER_URL}${URL.getInvoices}`,
      {}, 
      {},
      (res) => {
        dispatch({ type: types.GET_INVOICE_RESPONSE });
        if(res){
          if(cb){
            cb(res.data)
          }
        }
      },
      (err) => {
        dispatch({ type: types.GET_INVOICE_RESPONSE });
        if(err){
          cb(err)
        }
      }
    );
  };
};
export const getSubscriptionList = (cb) => {
  return (dispatch) => {
    _get(
      `${SERVER_URL}${URL.getSubscription}`,
      {}, 
      {},
      (res) => {
        if(res){
          if(cb){
            cb(res)
          }
        }
      },
      (err) => {
        if(err){
          cb(err)
        }
      }
    );
  };
};
export const saveSearchObject = (obj) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_SEARCH_OBJECT,
      payload: obj,
    });
  };
};
export const removeSearchObject = (obj) => {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_SEARCH_OBJECT,
      payload: obj,
    });
  };
};
export const tdsLocationSelected = (LOCATION) => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_TDS_SELECTION,
      payload: LOCATION,
    });
  };
};
export const saveSortValue =(val)=>{
  return (dispatch) => {
    dispatch({
      type: types.SAVE_SORT_VALUE,
      payload: val,
    });
  };
};