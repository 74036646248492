import React from 'react';
import {
  StyleSheet,
  View,
  Image
} from 'react-native';
import { ODSText } from '../ODSText';
import getScreenType from '../../../utils/getScreenType';
import { useTheme } from '@emotion/react';
import ODSButton from '../ODSButton';
const webImage = require('../../../images/dashboard/insurance-web.png');
const mobileImage = require('../../../images/dashboard/insurance-mobile.png')

const ODSInsuranceTile = ({
  onPress
}) => {
  const theme = useTheme();
  const layout = getScreenType();
  return (
    <View style={[styles(theme.colors).containerStyle]}>
      <ODSText marginTop={layout == "phone" ? 24 : 40} color={theme.colors.text.primary} type={'h5'}>
        Marketplace
      </ODSText>
      <ODSText width={layout == "phone" ? '70%' : '80%'} textAlign={'center'} marginTop={12} color={theme.colors.text.secondary} type={'md'}>
        Everything you need to make it home!
      </ODSText>
      <View style={{ width: '100%', alignItems: 'center', marginTop: 48 }}>
        <ODSButton
          restStyle={{ position: 'absolute', top: -24, zIndex: 111 }}
          themeColor={theme.colors.main.purple}
          type="secondary"
          disabled={false}
          onPress={onPress}
        >
          Go to marketplace
        </ODSButton>
        <Image source={layout == "phone" ? mobileImage : webImage} style={{ width: layout == "phone" ? '100%' : 620, height: 220, resizeMode: 'cover', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
      </View>

    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    containerStyle: {
      width: "100%",
      backgroundColor: theme.elements.invert,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

export default ODSInsuranceTile;
