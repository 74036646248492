import React, { useState } from "react";
import { TextInput, TouchableOpacity, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import getPlatformType from "../../../../helpers/getPlatform";

const SearchBox = ({
  data,
  result,
  searchKey,
}) => {
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const layoutType = getPlatformType();

  const handleClear = () => {
    setQuery("");
    setFilteredData([]);
    result(data);
  };

  const handleSearch = (text) => {
    const formattedQuery = text.toLowerCase();
    console.log(data);
    console.log(searchKey);
    console.log(formattedQuery);
    const filteredData = data.filter((item) => {
      return item[searchKey].toLowerCase()
      .includes(formattedQuery);
    });
    setQuery(text);
    setFilteredData(filteredData);
    result(filteredData);
  };

  return (
    <View style={{
      padding: 8,
      borderRadius: 8,
      borderColor: "#B2B2B2",
      borderWidth: 1,
      marginVertical: 12,
      width: layoutType == "phone" ? "100%" : "100%",
    }}>
      <View style={{
        flexDirection: "row",
        alignItems: "center",
      }}>
        <Ionicons name="ios-search" size={24} color="black" style={{ marginLeft: 10 }}/>
        <TextInput
          style={{
            flex: 1,
            marginLeft: 20,
            padding: 10,
            outline: "none",
          }}
          placeholder="Search"
          placeholderTextColor="#888"
          value={query}
          onChangeText={handleSearch}
        />
        {query ? (
          <TouchableOpacity onPress={handleClear}>
            <Ionicons name="ios-close" size={24} color="black" style={{ marginRight: 10 }}/>
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

export default SearchBox;
