import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import {ODSButton} from '../../atoms/ODSButton';
import { getTokens } from '../../../design-tokens/color-tokens';
import ODSTiles from '../../atoms/ODSTiles';
import getScreenType from '../../../utils/getScreenType';
import { Image } from 'react-native-svg';
import { ODSIcon } from '../../atoms/ODSIcon';
import { Layout } from 'react-native-reanimated';

const layout = getScreenType();

const CheckOutBox = ({ restStyle, uri, onOpenflowClick }) => {
    const themeObj = getTokens('light').colors;

    return (
        <View style={[styles(themeObj).containerStyle, restStyle]}>
            <ODSText
                textAlign="flex-start"
                marginTop={6}
                marginBottom={30}
                color="black"
                type="h5"
            >
                Check out
            </ODSText>
            <View style={{flexDirection:"row",justifyContent:"flex-start"}}>
            <View style={{flexDirection:"column",flex:1}}>
                <ODSText
                    textAlign="flex-start"
                    color="black"
                    type="button"
                >
                    Date sent
                </ODSText>
                <ODSText
                    textAlign="flex-start"
                    marginTop={6}
                    color="black"
                    type="sm"
                >
                    11/22/2021
                </ODSText>
            </View>
            <View style={{flexDirection:"column",flex:1}}>
                <ODSText
                    textAlign="flex-start"
                 

                    color="black"
                    type="button"
                >
                    Agent
                </ODSText>
                <ODSText
                    textAlign="flex-start"
                    marginTop={6}
                    color="black"
                    type="sm"
                >
                  Opebrix
                </ODSText>
                </View>
            </View>
            <>
                <ODSText
                    textAlign="flex-start"
                    marginTop={20}

                    color="black"
                    type="button"
                >
                    Status
                </ODSText>
                <ODSText
                    textAlign="flex-start"
                    marginTop={6}
                    color="black"
                    type="sm"
                >
                    Not Started
                </ODSText>
            </>
            <View style={{ height: 1, backgroundColor: 'black', width: '100%' ,marginBottom: 25, marginTop:15 }} />
            <ODSText
                textAlign="flex-start"
                marginTop={6}
                marginBottom={30}
                color="black"
                type="h5"
            >
                Meter Readings
            </ODSText>
            <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between"}}>
  
    <View style={{ flexDirection: "row", alignItems: "center", width: layout ==="phone"? "100%":"48%", marginBottom: 10 }}>
        <ODSIcon icon="elec" size={20} />
        <View style={{ flexDirection: "column", flex: 1, marginLeft: 10 }}>
            <ODSText
                textAlign="left"
                color="black"
                type="button"
                style={{ flex: 1 }}
            >
                Electric
            </ODSText>
            <ODSText
                textAlign="left"
                marginTop={6}
                color="black"
                type="sm"
            >
                12345353 - 11/22/2021
            </ODSText>
        </View>
    </View>

   
    <View style={{ flexDirection: "row", alignItems: "center", width: layout ==="phone"? "100%":"48%", marginBottom: 10 }}>
        <ODSIcon icon="gas" size={20} />
        <View style={{ flexDirection: "column", flex: 1, marginLeft: 10 }}>
            <ODSText
                textAlign="left"
                color="black"
                type="button"
                style={{ flex: 1 }}
            >
                Gas
            </ODSText>
            <ODSText
                textAlign="left"
                marginTop={6}
                color="black"
                type="sm"
            >
                98765432 - 11/23/2021
            </ODSText>
        </View>
    </View>

    <View style={{ flexDirection: "row", alignItems: "center", width: layout ==="phone"? "100%":"48%", marginBottom: 10 }}>
        <ODSIcon icon="water" size={20} />
        <View style={{ flexDirection: "column", flex: 1, marginLeft: 10 }}>
            <ODSText
                textAlign="left"
                color="black"
                type="button"
                style={{ flex: 1 }}
            >
                Water
            </ODSText>
            <ODSText
                textAlign="left"
                marginTop={6}
                color="black"
                type="sm"
            >
                45678901 - 11/24/2021
            </ODSText>
        </View>
    </View>


    <View style={{ flexDirection: "row", alignItems: "center", width: layout ==="phone"? "100%":"48%", marginBottom: 10 }}>
        <ODSIcon icon="oil" size={20} />
        <View style={{ flexDirection: "column", flex: 1, marginLeft: 10 }}>
            <ODSText
                textAlign="left"
                color="black"
                type="button"
                style={{ flex: 1 }}
            >
                Oil
            </ODSText>
            <ODSText
                textAlign="left"
                marginTop={6}
                color="black"
                type="sm"
            >
                11223344 - 11/25/2021
            </ODSText>
        </View>
    </View>
</View>

        </View>
    );
};

const styles = (theme) =>
    StyleSheet.create({
        containerStyle: {
            width: getScreenType() == 'web' ? 970 : "100%",
            borderRadius: 12,
            shadowColor: '#101828',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 2,
            elevation: 2,
            backgroundColor: theme.neutral['white'],
            padding: 20,
            borderWidth: 1,
            borderColor: '#D0D6DD',
            alignSelf: 'center',
            marginBottom: 100
        },
        tilesContainerWeb: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
        tilesContainerMobile: {
            flexDirection: 'column',
            width: '100%',
        },
    });

export default CheckOutBox;
