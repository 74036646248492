import OneSignal from "react-onesignal";
import * as Sentry from "@sentry/react";
import React from "react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { store } from "./src/redux/store/setup";

import PreRoutes from "./src/navigation/preRoutes.js";
import * as Font from "expo-font";
import {  onMessage } from "firebase/messaging";
import { messaging } from "./src/helpers/firebase";
import { registerServiceWorker } from "./web/register-service-worker";
const REACT_APP_ONE_SIGNAL_APP_ID = "c4d34844-6456-4f6e-9d5a-24cc17842b85";
import { ThemeProvider } from '@emotion/react';
import Loader from "./src/commonComponents/activityIndicator/activityIndicator.web";
import { getTokens } from "./openbrix-design-system/design-tokens/color-tokens";
import { GOOGELELOGIKEY } from "./src/helpers/connectionHelpers";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { Appearance } from "react-native";

Sentry.init({
  dsn: "https://2e11f00c33a94a01802f007ebb8b44ce@o533323.ingest.sentry.io/5652865",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.sentry_env || "unknown_sentry_env",
  tracesSampleRate: 1.0,
});


let customFonts = {
  "Lato": require("./src/assets/fonts/Lato-Regular.ttf"),
  "Lato-Black": require("./src/assets/fonts/Lato-Black.ttf"),
  "Lato-Medium": require("./src/assets/fonts/Lato-Medium.ttf"),
  "Lato-Bold": require("./src/assets/fonts/Lato-Bold.ttf"),
  "Raj-bold": require("./src/assets/fonts/Rajdhani-Bold.ttf"),
  "Raj": require("./src/assets/fonts/Rajdhani-Medium.ttf"),
};

export default class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isDarkMode: false,
    };
    this.handleDarkModeChange = this.handleDarkModeChange.bind(this);

  }
  async _loadFontsAsync() {
    await Font.loadAsync(customFonts);
  }
  
  
  async componentDidMount() {
    registerServiceWorker();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", JSON.stringify(payload));
      
      // const notificationTitle = payload.notification.title;
      // const notificationOptions = {
      //   body: payload.notification.body,
      //   icon: payload.notification.image,
      // };
    
      // self.registration.showNotification(notificationTitle, notificationOptions);
      
    });
  }

  componentWillUnmount() {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkModeMediaQuery.removeListener(this.handleDarkModeChange);
  }

  handleDarkModeChange(e) {
    this.setState({ isDarkMode: e.matches });
  };
  
  
  async componentDidMount() {
    console.log("isDarkMode",this.state.isDarkMode);

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.setState({ isDarkMode: darkModeMediaQuery.matches });
    darkModeMediaQuery.addListener(this.handleDarkModeChange);
    
     
    this._loadFontsAsync();
    const events = [{
      listener: "once",
      event: "subscriptionChange",
      callback: (isSubscribed) => {
        if (true === isSubscribed) {
          console.log("The user subscription state is now:", isSubscribed);
        }
      },
    }, {
      event: "notificationDisplay",
      callback: (event) => {
        // TODO @hitest: call get notifications api from here
        // addNotification();
        console.warn("OneSignal notification displayed:", event);
      },
    }, {
      event: "notificationDismiss",
      callback: (event) => {
        console.warn("OneSignal notification dismissed:", event);
      },
    }];

    try {
      await OneSignal.initialize(REACT_APP_ONE_SIGNAL_APP_ID, {}, events);
    } catch (e) {
      console.log(e);
    }
  }


  render() {
    return (
      <GoogleOAuthProvider clientId={GOOGELELOGIKEY}>
      <ThemeProvider theme={getTokens(this.state.isDarkMode ? 'dark' : 'light')}>
      <Provider store={store}>
        <PreRoutes/>
        {/* Control is transferred to Preroutes in order to get time after the strore is configure here } */}
      </Provider>
      </ThemeProvider>
      </GoogleOAuthProvider>
    );
  }
}

