import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import {ODSButton} from '../../atoms/ODSButton';
import { getTokens } from '../../../design-tokens/color-tokens';
import ODSTiles from '../../atoms/ODSTiles';
import getScreenType from '../../../utils/getScreenType';
import { Image } from 'react-native-svg';
import { ODSIcon } from '../../atoms/ODSIcon';
import { Layout } from 'react-native-reanimated';

const layout = getScreenType();

const ForwardMessegeBox = ({ restStyle, uri, onOpenflowClick }) => {
    const themeObj = getTokens('light').colors;

    return (
        <View style={[styles(themeObj).containerStyle, restStyle]}>
   <ODSText
                textAlign="flex-start"
                marginTop={6}
                marginBottom={30}
                color="black"
                type="h5"
            >
                Forwarding  Adddress
            </ODSText>
        </View>
    );
};


const styles = (theme) =>
    StyleSheet.create({
        containerStyle: {
            width: getScreenType() == 'web' ? 970 : "100%",
            borderRadius: 12,
            shadowColor: '#101828',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 2,
            elevation: 2,
            backgroundColor: theme.neutral['white'],
            padding: 20,
            borderWidth: 1,
            borderColor: '#D0D6DD',
            alignSelf: 'center',
            marginBottom: 100
        },
        tilesContainerWeb: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
        tilesContainerMobile: {
            flexDirection: 'column',
            width: '100%',
        },
    });
    export default ForwardMessegeBox;