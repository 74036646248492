const label = {
  INCOMPLETE: "Incomplete",
  LOREM_IPSUM_DOLOR:
    `You will find a breakdown and balance of all the required "Moving in" Monies, PLUS the prescribed information and requirements of the Deposit.`,
    LOREM_IPSUM_DOLOR_POST:
    `Details of your deposit protection and all the utility connections (Eg. Energy and media).Let Tlyfe do all the work for you and we will have everything connected for your move-in.
    `,
    MOVING_IN: "Moving in",
  POST_MOVING_IN: "Deposit Protect & Home Setup",
  TDS_INFORMATION_DOCUMENT: "TDS Information Document",
  TDS_INFORMATION: "TDS Information",
  DEPOSIT_INFORMATION: "Deposit Information",
  MOVE_IN_MONIES: "Move in  Monies",
  INVENTORY_N_CHECK_IN: "Inventory & Check in",
  TENANCY_DEPOSIT_SCHEME_INFORMATION: "Tenancy Deposit Scheme Information",
  TDS_PROVIDER: "TDS Provider: ",
  TDS_WEBSITE: "TDS Website: ",
  MOVE_IN_MONIES_BREAKDOWN: "Move In Monies Breakdown",
  AMOUNT_PER_APPLICATION: "Amount Per Applicant",
  AMOUNT: "Amount",
  COMPANY: "Company",
  DATE_SENT: "Date send",
  PAID: "PAID",
  ACCOUNT_DETAILS: "Tenant Account Details",
  DATE: "Date",
  STATUS: "Status",
  INVENTORY_CHECK: "Inventory Check",
  CHECK_IN: "Check in",
  DOCUMENTS: "Documents",
  INVENTORY: "Inventory",
  DEPOSIT_PROTECTION: "Deposit Protection",
  RENT_COLLECTION: "Rent Collection",
  HOME_SETUP: "Home Setup",
  CONGRATULATION: "Congratulations! ",
  DEPOSIT_PROTECTION_DOCS: "Deposit Protection Docs",
  DEPOSIT_CERTIFICATE: "Deposit Certificate",
  RESPONSIBILITIES: "Responsibilities",
  RENTAL_OFFER: "Rental Offer",
  SPECIAL_CONDITION: "Special Conditions",
  RULE_1: "In accordance of Section 47 and 48 of the Landlord and Tenant ACT 1987",
  RULE_2: "The landlord names and address, in England and Wales, at which Notices maybe served on the landlord by the tenant:",
  RENT_COLLECTION_ACCOUNT: "Rent Collection - Account",
  YOUR_RENT_WILL_BE: "Your rent will Be collected from the account below",
  IF_YOUR_AGENT_HAS_NOT_COMPLETED: "If your Agent has not completed the details then please check and update the details directly below",
  RENT_COLLECTION_SCHEDULE: "Rent Collection Schedule",
  YOUR_AGENT_HAS_SELECTED_FCC: "Your Agent has selected FCC paragon to collect your rent.",
  BELOW_IS_YOUR_RENT_COLLECTION: "Below is your rent collection Schedule",
  COLLECTION_DATE: "Collection Date"

};

export default label;
