import React, { useEffect, useState } from 'react';
import Loader from './../../commonComponents/activityIndicator/activityIndicator';
import { Dimensions, Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutApi, addRentalInfo, changePassword, contactUs, deleteRentalInfo, editRentalInfo, getProfile, getRentalInfo, referFriend, updateNotiSettings, updateProfile } from '../../redux/actions/auth';
import getPlatformType from '../../helpers/getPlatform';
import NotificationList from '../notificationSettings/NotificationList';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomPopUp from '../../commonComponents/customPopUp/customPopUp';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { useTheme } from '@emotion/react';
import MyAccountPanel from '../../../openbrix-design-system/ui/molecules/Account/MyAccountPanel/MyAccountPanel';
import MyAccountCard from '../../../openbrix-design-system/ui/molecules/Account/MyAccount/MyAccount';
import SavedPropertiesCard from '../../../openbrix-design-system/ui/molecules/Account/SavedPropertiesCard/SavedPropertiesCard';
import AlertCard from '../../../openbrix-design-system/ui/molecules/Account/AlertCard/AlertCard';
import ReferAFriendCard from '../../../openbrix-design-system/ui/molecules/Account/ReferAFriend/ReferAFriendCard';
import SettingsCards from '../../../openbrix-design-system/ui/molecules/Account/Settings/SettingsCard';
import { ODSModal } from '../../../openbrix-design-system/ui/atoms/ODSModal/ODSModal.web';
import ChangePasswordCard from '../../../openbrix-design-system/ui/molecules/Account/ChangePasswordCard/ChangePasswordCard';
import ContactUsCard from '../../../openbrix-design-system/ui/molecules/Account/ContactUsCard/ContactUsCard';
import { useNavigation } from '@react-navigation/native';
import ShareMedia from '../../commonComponents/shareMedia/shareMedia.web';
import { types } from '../../redux/actionTypes';
import MyInvoiceCard from '../../../openbrix-design-system/ui/molecules/Account/MyInvoices/MyInvoices';
import MyRentalInfo from '../../../openbrix-design-system/ui/molecules/Account/MyRentalInfor';
import SubscriptionList from '../subscriptionList';

const layoutType = getPlatformType();

const MyAccount = (props) => {
    const dispatch = useDispatch();
    const [deletePopUpIsVisible, setDeletePopUpIsVisible] = useState(false);
    const [rentalData, setRentalData] = useState({});

    const [state, setState] = React.useState({
        currentTab: 'tab1',
        firstName: '',
        lastName: '',
        contact: '',
        email: '',
        user_id: '',
        idverificationStatus: '',
        yoti_reason: '',
        opbrix_id: '',
        TextInputDisableHolder: true,
        prevProfile: null,
        showDetails: false,
        contactError: '',
        isTenantOrBuyer: '',
        showLoading: false,
    });

    const { navigate } = useNavigation();
    const profileLoading = useSelector((state) => state.authReducer.profileLoading);
    const profile = useSelector((state) => state.authReducer.profile);
    const rental_profile = useSelector((state) => state.authReducer?.rental_profile?.data);

    useEffect(() => {
        dispatch(getProfile({}, props.navigation.navigate));
        dispatch(getRentalInfo({}, props.navigation.navigate));
        window.scrollTo(0, 0);
    }, [selectedId]);

    const onProfileUpdate = (data) => {
        dispatch(
            updateProfile({
                user: {
                    first_name: data.firstName,
                    last_name: data.lastName,
                    contact_number: data.contact,
                    user_id: state.user_id,
                    user_type: 0,
                    title: data.title,
                },
            })
        );
    }
    const onContactUs = (data) => {
        dispatch(
            contactUs({
                email: data.email,
                topic: data.subject,
                name: data.firstName,
                message: data.message,
            })
        );
    }
    const onChangePassword = (data) => {
        dispatch(changePassword(
            {
                newPassword: data.newPassword,
                currentPassword: data.currentPassword,
            },
            navigate,
        ))
    }
    const onReferAFriend = (email) => {
        dispatch(referFriend({ email: email }, () => {

        }))
    }
    const onUpdateSettings = (data) => {
        let req = {
            property_notification: data.property_notification,
            marketplace_notification: data.marketplace_notification,
            rental_notification: data.rental_notification,
            maintenance_notification: data.maintenance_notification,
            openflow_notification: data.openflow_notification,
        };
        dispatch(updateNotiSettings(req))
    }
    const onAddRentalInfo = (data) => {
        let req = {
            "property_name": data?.property_name,
            "rental_amount": data.rental_amount,
            "deposit_amount": data.deposit_amount,
            "property_address": data.property_address,
            "rental_start_date":new Date(data.rental_start_date).toISOString(),
            "expected_end_date": new Date(data.expected_end_date).toISOString(),
            "property_post_code": data.property_post_code,
          };
        dispatch(addRentalInfo(req))
    }
    const onEditRentalInfo = (data) => {
        let req = {
            "property_name": data?.property_name,
            "rental_amount": data.rental_amount,
            "deposit_amount": data.deposit_amount,
            "property_address": data.property_address,
            "rental_start_date":new Date(data.rental_start_date).toISOString(),
            "expected_end_date": new Date(data.expected_end_date).toISOString(),
            "property_post_code": data.property_post_code,
            "rental_profile_id":data?.rental_profile_id
          };
          console.log("reqreq000",data)
          console.log("reqreq1111",req)
        dispatch(editRentalInfo(req))
    }
    const onDelereRentalInfo = (id) => {
        dispatch(deleteRentalInfo(id))
    }
    useEffect(() => {
        if (profile != undefined) {
            setState({
                ...state,
                firstName: profile.data.first_name,
                lastName: profile.data.last_name,
                contact: profile.data.contact_number,
                email: profile.data.email,
                idverificationStatus: profile?.data?.appUser?.idverification,
                yoti_reason: profile.data?.appUser?.yoti_reason,
                opbrix_id: profile?.data?.appUser?.opbrix_id,
                user_id: profile.data.user_id,
                title: profile?.data?.title,
            });
        }
    }, [profile]);
    useEffect(() => {
        if (rental_profile&&setRentalData.length) {
            setRentalData(rental_profile);
        }else{
            setRentalData([])
        }
    }, [rental_profile]);
    
    useEffect(() => {
        if (profile != undefined) {
            setState({
                ...state,
                firstName: profile.data.first_name,
                lastName: profile.data.last_name,
                contact: profile.data.contact_number,
                email: profile.data.email,
                idverificationStatus: profile?.data?.appUser?.idverification,
                yoti_reason: profile.data?.appUser?.yoti_reason,
                opbrix_id: profile?.data?.appUser?.opbrix_id,
                user_id: profile.data.user_id,
                title: profile?.data?.title,
            });
        }
    }, [profile]);
    const onCopyPress = (e) => {
        navigator.clipboard.writeText(e);
        dispatch({ type: types.SHOW_ALERT, alertType: "success", text: "ID Copied" });
    }



    const { colors } = useTheme();
    const [selectedId, setSelectedId] = useState('profile');

    return (
        <BackGroundGenerator props={props} isTLYF>

            <Loader loading={profileLoading} />
            <ImageBackground
                source={require('../../../openbrix-design-system/images/banner/web/my-account.png')}
                style={{
                    width: '100%',
                    height: '246px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
                resizeMode="cover"
            >
                <ODSButton
                    rIcon={'TB-Arrow-Left'}
                    restStyle={{ marginTop: 20, marginLeft: layoutType == "web" ? 60 : 20 }}
                    type="secondary"
                    disabled={false}
                    onPress={() => replaceAction(props?.navigation, 'Dashboard')}
                >
                    Back
                </ODSButton>
            </ImageBackground>
            <ODSText type='h3' marginVertical={32} color={colors.text.primary} textAlign='center'>My Account</ODSText>
            <View style={{ flexDirection: 'row', width: layoutType == "web" ? 1050 : layoutType == "tab" ? 700 : '100%', alignSelf: 'center', paddingHorizontal: 16, marginBottom: 40, alignItems: 'flex-start', gap: 24 }}>
                <View style={{ width: layoutType == "web" ? 377 : '100%' }}>
                    <MyAccountPanel selectedId={layoutType == "web" ? selectedId : ''} onPress={(id) => {
                        window.scrollTo(0, 0);
                        setSelectedId(id);
                        layoutType != 'web' && id != 'delete' &&
                            navigateAction(props?.navigation, "ChildScreen", { useremail: state?.email, id: id, data: state, idStatus: getDigitalVerificationStatus(state.idverificationStatus).StatusText, navigation: props.navigation })
                    }} />
                </View>
                <View style={{ width: 1, height: 1000, marginHorizontal: 16, backgroundColor: '#D0D6DD' }} />
                {layoutType == "web" && <View style={{ width: 625 }}>
                    {selectedId == "profile" && <MyAccountCard onCopyPress={onCopyPress} onPress={(data) => onProfileUpdate(data)} data={state} idStatus={getDigitalVerificationStatus(state.idverificationStatus).StatusText} />}
                    {selectedId == "My_Rental_Information" && 
                    <MyRentalInfo 
                        data={rentalData}
                         onAddRentalInfo={(data) => {
                            onAddRentalInfo(data)
                        }}
                        onDeletePress={(id)=>{
                            onDelereRentalInfo(id)
                        }}
                        onEditPress={(data)=>{
                            onEditRentalInfo(data)
                        }}
                    />}
                    {selectedId == "invoice" && <MyInvoiceCard invoiceList={<NotificationList/>} subscriptionList={<SubscriptionList/>}/>}

                    {selectedId == "change-password" && <View
                        style={{
                            width: 450,
                            alignSelf: 'center',
                            backgroundColor: 'white',
                            padding: 24,
                            borderRadius: 12,
                            shadowColor: '#101828',
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.1,
                            shadowRadius: 4,
                            elevation: 2,
                        }}>
                        <ChangePasswordCard onChangePassword={(data) => onChangePassword(data)} onClose={() => setSelectedId('profile')} />

                    </View>}
                    {selectedId == 'settings' &&
                        <View
                            style={{
                                width: 450,
                                alignSelf: 'center',
                                backgroundColor: 'white',
                                padding: 24,
                                borderRadius: 12,
                                shadowColor: '#101828',
                                shadowOffset: { width: 0, height: 1 },
                                shadowOpacity: 0.1,
                                shadowRadius: 4,
                                elevation: 2,
                            }}>
                            <SettingsCards data={{
                                property_notification: profile?.data?.appUser?.property_notification,
                                marketplace_notification: profile?.data?.appUser?.marketplace_notification,
                                maintenance_notification: profile?.data?.appUser?.maintenance_notification,
                                rental_notification: profile?.data?.appUser?.rental_notification,
                                openflow_notification: profile?.data?.appUser?.openflow_notification,
                            }} onSave={(data) => onUpdateSettings(data)} onClose={() => setSelectedId('profile')} />
                        </View>
                    }
                    {selectedId == "contact-us" && <ContactUsCard data={state} onSend={(data) => onContactUs(data)} />}
                    {selectedId == "saved" && <SavedPropertiesCard />}
                    {selectedId == "alerts" && <AlertCard />}
                    {selectedId == "refer" && <ReferAFriendCard
                        useremail={state.email}
                        shareComponent={() => <ShareMedia
                            // shareUrl={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                            shareUrl={`Refer a Friend\nCheck it out!\n\nhttps://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}

                            emailSubject={
                                "Refer a friend"
                            }
                            emailBody={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                        />} onSend={(email) => onReferAFriend(email)} />}

                </View>}
            </View>


            <CustomPopUp
                loading={selectedId == "delete" ? true : false}
                primaryButtonText={'Sure'}
                secondaryButtonText={'cancel'}
                primaryText={'Delete Account?'}
                secondaryText={' Are you sure you want to delete your account?'}
                onPrimaryButtonPress={() => {
                    setSelectedId('profile')
                    dispatch(LogoutApi(true, props.navigation));
                }}
                onSecondaryButtonPress={() => {
                    setSelectedId('profile')
                }}
            />

        </BackGroundGenerator>
    );
};
export default MyAccount;
