export const tenantConstants = {
  GET_RENTALCOLL_REQUEST: "GET_RENTALCOLL_REQUEST",
  GET_RENTALCOLL_SUCCESS: "GET_RENTALCOLL_SUCCESS",
  GET_RENTALCOLL_FAILURE: "GET_RENTALCOLL_FAILURE",
  //Rental offers
  GET_RENTAL_OFFERS_REQUEST: "GET_RENTAL_OFFERS_REQUEST",
  GET_RENTAL_OFFERS_SUCCESS: "GET_RENTAL_OFFERS_SUCCESS",
  GET_RENTAL_OFFERS_FAILURE: "GET_RENTAL_OFFERS_FAILURE",
  ADD_RENTAL_OFFERS_REQUEST: "ADD_RENTAL_OFFERS_REQUEST",
  ADD_RENTAL_OFFERS_SUCCESS: "ADD_RENTAL_OFFERS_SUCCESS",
  ADD_RENTAL_OFFERS_FAILURE: "ADD_RENTAL_OFFERS_FAILURE",
  //Onboardad Teanants
  GET_TEANANTS_REQUEST: "GET_TEANANTS_REQUEST",
  GET_TEANANTS_SUCCESS: "GET_TEANANTS_SUCCESS",
  GET_TEANANTS_FAILURE: "GET_TEANANTS_FAILURE",

  //Review AST
  ADD_GUARANTOR_TO_REFERENCE_REQUEST: "ADD_GUARANTOR_TO_REFERENCE_REQUEST",
  ADD_GUARANTOR_TO_REFERENCE_SUCCESS:"ADD_GUARANTOR_TO_REFERENCE_SUCCESS",
  ADD_GUARANTOR_TO_REFERENCE_FAILURE:"ADD_GUARANTOR_TO_REFERENCE_FAILURE",

  //Review AST
  UPLOAD_AST_REQUEST:"UPLOAD_AST_REQUEST",
  UPLOAD_AST_SUCCESS:"UPLOAD_AST_SUCCESS",
  UPLOAD_AST_FAILURE:"UPLOAD_AST_FAILURE",

  //hOLDING DEPOITE
  GET_HOLDING_REQUEST: "GET_HOLDING_REQUEST",
  GET_HOLDING_SUCCESS: "GET_HOLDING_SUCCESS",
  GET_HOLDING_FAILURE: "GET_HOLDING_FAILURE",

  //Onboardad Teanants
  GET_TOGGLE_REQUEST: "GET_TOGGLE_REQUEST",
  GET_TOGGLE_SUCCESS: "GET_TOGGLE_SUCCESS",
  GET_TOGGLE_FAILURE: "GET_TOGGLE_FAILURE",

  //Get Teanants Details
  GET_TEANANTS_DETAILS_REQUEST: "GET_TEANANTS_DETAILS_REQUEST",
  GET_TEANANTS_DETAILS_SUCCESS: "GET_TEANANTS_DETAILS_SUCCESS",
  GET_TEANANTS_DETAILS_FAILURE: "GET_TEANANTS_DETAILS_FAILURE",
  // Get Documents
  GET_TEANANTS_DOC_REQUEST: "GET_TEANANTS_DOC_REQUEST",
  GET_TEANANTS_DOC_SUCCESS: "GET_TEANANTS_DOC_SUCCESS",
  GET_TEANANTS_DOC_FAIL: "GET_TEANANTS_DOC_FAIL",
  //Get FLOWINFO
  ADD_TEANANTS_DETAILS_REQUEST: "ADD_TEANANTS_DETAILS_REQUEST",
  ADD_TEANANTS_DETAILS_SUCCESS: "ADD_TEANANTS_DETAILS_SUCCESS",
  ADD_TEANANTS_DETAILS_FAIL: "ADD_TEANANTS_DETAILS_FAIL",
  //Onboardad Teanants
  GET_FLOWINFO_REQUEST: "GET_FLOWINFO_REQUEST",
  GET_FLOWINFO_SUCCESS: "GET_FLOWINFO_SUCCESS",
  GET_FLOWINFO_FAILURE: "GET_FLOWINFO_FAILURE",
  GET_FLOWINFO_RESET: "GET_FLOWINFO_RESET",

  GET_TENANTFLOW_REQUEST: "GET_TENANTFLOW_REQUEST",
  GET_TENANTFLOW_SUCCESS: "GET_TENANTFLOW_SUCCESS",
  GET_TENANTFLOW_FAILURE: "GET_TENANTFLOW_FAILURE",

  //Move in
  GET_MOVEIN_REQUEST: "GET_MOVEIN_REQUEST",
  GET_MOVEIN_SUCCESS: "GET_MOVEIN_SUCCESS",
  GET_MOVEIN_FAILURE: "GET_MOVEIN_FAILURE",
  ADD_MOVEIN_REQUEST: "ADD_MOVEIN_REQUEST",
  ADD_MOVEIN_SUCCESS: "ADD_MOVEIN_SUCCESS",
  ADD_MOVEIN_FAILURE: "ADD_MOVEIN_FAILURE",
  ARRANGE_CHECKIN_REQUEST: "ARRANGE_CHECKIN_REQUEST",
  ARRANGE_CHECKIN_SUCCESS: "ARRANGE_CHECKIN_SUCCESS",
  ARRANGE_CHECKIN_FAILURE: "ARRANGE_CHECKIN_FAILURE",
  //Tenancy Information
  GET_TENANCY_INFO_REQUEST: "GET_TENANCY_INFO_REQUEST",
  GET_TENANCY_INFO_SUCCESS: "GET_TENANCY_INFO_SUCCESS",
  GET_TENANCY_INFO_FAILURE: "GET_TENANCY_INFO_FAILURE",
  GET_NHA_REQUEST: "GET_NHA_REQUEST",
  GET_NHA_SUCCESS: "GET_NHA_SUCCESS",
  GET_NHA_FAILURE: "GET_NHA_FAILURE",
  GET_AST_REQUEST: "GET_NHA_REQUEST",
  GET_AST_SUCCESS: "GET_AST_SUCCESS",
  GET_AST_FAILURE: "GET_AST_FAILURE",
  ADD_TENANCY_INFO_REQUEST: "ADD_TENANCY_INFO_REQUEST",
  ADD_TENANCY_INFO_SUCCESS: "ADD_TENANCY_INFO_SUCCESS",
  ADD_TENANCY_INFO_FAILURE: "ADD_TENANCY_INFO_FAILURE",

  //Save slelectedProperty

  SAVE_TEANANTS_PROPERTY_REQUEST: "SAVE_TEANANTS_PROPERTY_REQUEST",
  SAVE_TEANANTS_PROPERTY_SUCCESS: "SAVE_TEANANTS_PROPERTY_SUCCESS",
  SAVE_TEANANTS_PROPERTY_FAILURE: "SAVE_TEANANTS_PROPERTY_FAILURE",

  //Save SAVE_HOLDING_DEPOSIT

  SAVE_HOLDING_DEPOSIT_REQUEST: "SAVE_HOLDING_DEPOSIT_REQUEST",
  SAVE_HOLDING_DEPOSIT_SUCCESS: "SAVE_HOLDING_DEPOSIT_SUCCESS",
  SAVE_HOLDING_DEPOSIT_FAILURE: "SAVE_HOLDING_DEPOSIT_FAILURE",

  //rEQYESTR SAVE_HOLDING_DEPOSIT

  REQUEST_HOLDING_DEPOSIT_REQUEST: "REQUEST_HOLDING_DEPOSIT_REQUEST",
  REQUEST_HOLDING_DEPOSIT_SUCCESS: "REQUEST_HOLDING_DEPOSIT_SUCCESS",
  REQUEST_HOLDING_DEPOSIT_FAILURE: "REQUEST_HOLDING_DEPOSIT_FAILURE",

  //rEQYESTR SAVE_HOLDING_DEPOSIT

  CONFIRM_HOLDING_DEPOSIT_REQUEST: "CONFIRM_HOLDING_DEPOSIT_REQUEST",
  CONFIRM_HOLDING_DEPOSIT_SUCCESS: "CONFIRM_HOLDING_DEPOSIT_SUCCESS",
  CONFIRM_HOLDING_DEPOSIT_FAILURE: "CONFIRM_HOLDING_DEPOSIT_FAILURE",

  //BUY_CER_FAILURE

  BUY_CER_REQUEST: "BUY_CER_REQUEST",
  BUY_CER_SUCCESS: "BUY_CER_SUCCESS",
  BUY_CER_FAILURE: "BUY_CER_FAILURE",

  //BUY_CER_FAILURE

  DEL_CER_REQUEST: "DEL_CER_REQUEST",
  DEL_CER_SUCCESS: "DEL_CER_SUCCESS",
  DEL_CER_FAILURE: "DEL_CER_FAILURE",

  //BUY_CER_FAILURE

  SAVE_CER_REQUEST: "SAVE_CER_REQUEST",
  SAVE_CER_SUCCESS: "SAVE_CER_SUCCESS",
  SAVE_CER_FAILURE: "SAVE_CER_FAILURE",

  //get iden

  GET_IDEN_REQUEST: "GET_IDEN_REQUEST",
  GET_IDEN_SUCCESS: "GET_IDEN_SUCCESS",
  GET_IDEN_FAILURE: "GET_IDEN_FAILURE",

  //BUY_CER_FAILURE

  SAVE_IDEN_REQUEST: "SAVE_IDEN_REQUEST",
  SAVE_IDEN_SUCCESS: "SAVE_IDEN_SUCCESS",
  SAVE_IDEN_FAILURE: "SAVE_IDEN_FAILURE",

  //Get Tds 
    GET_TDS_REQUEST: "GET_TDS_REQUEST",
    GET_TDS_SUCCESS: "GET_TDS_SUCCESS",
    GET_TDS_FAILURE: "GET_TDS_FAILURE",
    


      //BUY_CER_FAILURE

  SAVE_MOVEIN_REQUEST: "SAVE_MOVEIN_REQUEST",
  SAVE_MOVEIN_SUCCESS: "SAVE_MOVEIN_SUCCESS",
  SAVE_MOVEIN_FAILURE: "SAVE_MOVEIN_FAILURE",
    //BUY_CER_FAILURE

    SAVE_CHECKIN_REQUEST: "SAVE_CHECKIN_REQUEST",
    SAVE_CHECKIN_SUCCESS: "SAVE_CHECKIN_SUCCESS",
    SAVE_CHECKIN_FAILURE: "SAVE_CHECKIN_FAILURE",
    //Get
    SAVE_PROTECT_REQUEST: "SAVE_PROTECT_REQUEST",
    SAVE_PROTECT_SUCCESS: "SAVE_CHECKIN_SUCCESS",
    SAVE_PROTECT_FAILURE: "SAVE_CHECKIN_FAILURE",

    //save
    GET_PROTECT_REQUEST: "GET_PROTECT_REQUEST",
    GET_PROTECT_SUCCESS: "GET_PROTECT_SUCCESS",
    GET_PROTECT_FAILURE: "GET_PROTECT_FAILURE",

      //save
      UPDATE_ISSEEN_REQUEST: "UPDATE_ISSEEN_REQUEST",
      UPDATE_ISSEEN_SUCCESS: "UPDATE_ISSEEN_SUCCESS",
      UPDATE_ISSEEN_FAILURE: "UPDATE_ISSEEN_FAILURE",
      //confirmGaurantor
      CONFIRM_GAURANTOR_REQUEST: "CONFIRM_GAURANTOR_REQUEST",
      CONFIRM_GAURANTOR_SUCCESS: "CONFIRM_GAURANTOR_SUCCESS",
      CONFIRM_GAURANTOR_FAILURE: "CONFIRM_GAURANTOR_FAILURE",

       //MOVEINapI
       ACCEPT_MOVEIN_REQUEST: "ACCEPT_MOVEIN_REQUEST",
       ACCEPT_MOVEIN_SUCCESS: "ACCEPT_MOVEIN_SUCCESS",
       ACCEPT_MOVEIN_FAILURE: "ACCEPT_MOVEIN_FAILURE",
      //delete
      DELETE_DOCS: "DELETE_DOCS",
      DELETE_DOCS_SUCCESS: "DELETE_DOCS_SUCCESS",
      DELETE_DOCSFAILURE: "DELETE_DOCSFAILURE",

      GET_FCC_REQ_REF_DOC_REQUEST:'GET_FCC_REQ_REF_DOC_REQUEST',
      GET_FCC_REQ_REF_DOC_SUCCESS:'GET_FCC_REQ_REF_DOC_SUCCESS',
      GET_FCC_REQ_REF_DOC_FAILURE:'GET_FCC_REQ_REF_DOC_FAILURE',

      UPLOAD_REF_DOC_REQUEST:'UPLOAD_REF_DOC_REQUEST',
      UPLOAD_REF_DOC_SUCCESS:'UPLOAD_REF_DOC_SUCCESS',
      UPLOAD_REF_DOC_FAILURE:'UPLOAD_REF_DOC_FAILURE',
      DELETE_REF_DOC_REQUEST:'DELETE_REF_DOC_REQUEST',
      DELETE_REF_DOC_SUCCESS:'DELETE_REF_DOC_SUCCESS',
      DELETE_REF_DOC_FAILURE:'DELETE_REF_DOC_FAILURE',
      UPLOAD_TENANT_REF_DOC_REQUEST:'UPLOAD_TENANT_REF_DOC_REQUEST',
      UPLOAD_TENANT_REF_DOC_SUCCESS:'UPLOAD_TENANT_REF_DOC_SUCCESS',
      UPLOAD_TENANT_REF_DOC_FAILURE:'UPLOAD_TENANT_REF_DOC_FAILURE',

      SEARCH_ADDRESS_FAILURE: 'SEARCH_ADDRESS_FAILURE',
      SEARCH_ADDRESS_REQUEST: 'SEARCH_ADDRESS_REQUEST',
      SEARCH_ADDRESS_SUCCESS: 'SEARCH_ADDRESS_SUCCESS',

      REQUEST_LOAN_FAILURE: 'REQUEST_LOAN_FAILURE',
      REQUEST_LOAN_REQUEST: 'REQUEST_LOAN_REQUEST',
      REQUEST_LOAN_SUCCESS: 'REQUEST_LOAN_SUCCESS',

      FOLLOWUP_REQUEST:'FOLLOWUP_REQUEST',
      FOLLOWUP_SUCCESS:'FOLLOWUP_SUCCESS',
      FOLLOWUP_ERROR:'FOLLOWUP_ERROR',

      GET_PACK: "GET_PACK",
      GET_PACK_SUCCESS: "GET_PACK_SUCCESS",
      GET_PACK_FAILURE: "GET_PACK_FAILURE",

      MAKE_PAYMENTS: "MAKE_PAYMENTS",
      MAKE_PAYMENTSSUCCESS: "MAKE_PAYMENTSSUCCESS",
      MAKE_PAYMENTSFAILURE: "MAKE_PAYMENTSFAILURE",

      GET_Prequalification: "GET_Prequalification",
      GET_Prequalification_success: "GET_Prequalification_success",
      GET_Prequalification_failure: "GET_Prequalification_failure",

      GET_CODES: "GET_CODES",
      GET_CODES_SUCCESS: "GET_CODES_SUCCESS",
      GET_CODES_FAILURE: "GET_CODES_FAILURE",
      GET_REWARDS_SUBS_STATUS: "GET_REWARDS_SUBS_STATUS",
      GET_REWARDS_SUBS_STATUS_SUCCESS: "GET_REWARDS_SUBS_STATUS_SUCCESS",
      GET_REWARDS_SUBS_STATUS_FAILURE: "GET_REWARDS_SUBS_STATUS_FAILURE",
      POST_CODES: "POST_CODES",
      POST_CODES_SUCCESS: "POST_CODES_SUCCESS",
      POST_CODES_FAILURE: "POST_CODES_FAILURE",

      GET_INSURANCE_CLICKER: "GET_INSURANCE_CLICKER",
      GET_INSURANCE_CLICKER_SUCCESS: "GET_INSURANCE_CLICKER_SUCCESS",
      GET_INSURANCE_CLICKER_FAILURE: "GET_INSURANCE_CLICKER_FAILURE",

      SET_POSTCODE_ACTION:"SET_POSTCODE_ACTION",

      GET_BILLING_BETTER_IFRAME_REQUEST: "GET_BILLING_BETTER_IFRAME_REQUEST",
      GET_BILLING_BETTER_IFRAME_SUCCESS: "GET_BILLING_BETTER_IFRAME_SUCCESS",
      GET_BILLING_BETTER_IFRAME_FAILURE: "GET_BILLING_BETTER_IFRAME_FAILURE",
  SAVE_TENANT_REQUEST: "SAVE_TENANT_REQUEST",
  SAVE_TENANT_SUCCESS: "SAVE_TENANT_SUCCESS",
  SAVE_TENANT_FAILED: "SAVE_TENANT_FAILED",
  GET_TENANT_REQUEST: "GET_TENANT_REQUEST",
  GET_TENANT_SUCCESS: "GET_TENANT_SUCCESS",
  GET_TENANT_FAILED: "GET_TENANT_FAILED",
};
