import React, { useEffect, useState } from 'react';
import { StyleSheet,View, Platform } from 'react-native';
import { ODSInput } from '../../../atoms/ODSInput';
import ODSButton from '../../../atoms/ODSButton';
import DatePickerComponent from '../../../atoms/ODSDatepicker/ODSDatepicker';
import moment from 'moment';

const MyRentalInfoModal = ({
  onPress,
  type,
  modalData,
  onEditPress,
}) => {
  const {

  } = modalData;

  const [chekForValidate, setCheckForValidate] = useState(false);
  const [form, setForm] = useState({
    property_address: "",
    property_post_code: "",
    rental_start_date: "",
    expected_end_date: "",
    rental_amount: "",
    deposit_amount: "",
    rentalState: "",
    rental_profile_id: "",
    property_name: ""
  });
  const [errors, setErrors] = useState({
    property_address: "",
    property_post_code: "",
    rental_start_date: "",
    expected_end_date: "",
    rental_amount: "",
    deposit_amount: "",
    rentalState: "",
    rental_profile_id: "",
    property_name: ""
  });


  useEffect(() => {
    if (chekForValidate) {
      validateForm()
    }
  }, [form]);
  useEffect(() => {
    console.log("modalData", modalData)
    if (modalData) {

      setForm({ form, ...modalData })
    }
  }, [modalData]);

  function isEndDateGreater(startDate, endDate) {
    // Use moment to parse the dates with flexible format handling
    const start = moment(startDate);
    const end = moment(endDate);

    // Check if the dates are valid
    if (!start.isValid() || !end.isValid()) {
      return 'Invalid date format';
    }

    // Check if the end date is after the start date
    return end.isAfter(start);
  }
  const validateForm = () => {
    let valid = true;
    let errors = {
      property_address: "",
      property_post_code: "",
      rental_start_date: "",
      expected_end_date: "",
      rental_amount: "",
      deposit_amount: "",
      rentalState: "",
      rental_profile_id: "",
      property_name: ""
    };
    console.log("formformform", form?.property_name)
    if (!form?.property_name) {
      errors.property_name = 'Invalid property Name ';
      valid = false;
    }
    if (!form?.property_address) {
      errors.property_address = 'Invalid property address.';
      valid = false;
    }
    if (!form?.property_post_code) {
      errors.property_post_code = 'Please enter valid post code address.';
      valid = false;
    }
    if (!form?.rental_start_date) {
      errors.rental_start_date = 'Please enter valid rental start date.';
      valid = false;
    }
    if (!form.rental_amount) {
      errors.rental_amount = 'Please enter the rental amount.';
      valid = false;
    }
    if (!form.deposit_amount) {
      errors.deposit_amount = 'Please enter the deposit amount.';
      valid = false;
    }
    if (!form?.expected_end_date) {
      errors.expected_end_date = 'Please enter valid expected end date.';
      valid = false;
    } else {
      const result = isEndDateGreater(form?.rental_start_date, form?.expected_end_date);
      if (result === false) {
        errors.expected_end_date = 'End date should be greater than start date.';
        valid = false;
      }
    }
    console.log("errorserrorserrors", errors)
    setErrors(errors);
    return valid;
  };

  const _onSave = () => {
    setCheckForValidate(true)
    console.log("Form", form)
    console.log("validateForm", validateForm())


    if (validateForm()) {
      console.log("type", type)
      if (type == "edit") {
        onEditPress(form)
      } else {
        onPress(form)
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <View style={{

    }}>
      <ODSInput
        value={form.property_name}
        disabled={false}
        label={"Property Name"}
        maxLength={20}
        onChange={(e) => {

          setForm({ ...form, property_name: e })
        }}
        hints={errors.property_name ? [
          { state: "default", hint: "Give this rental entry a unique name for easy reference" },
          { state: "error", hint: errors.property_name }
        ] : [{ state: "default", hint: "Give this rental entry a unique name for easy reference" }]}
      />
      <ODSInput
        value={form.property_address}
        disabled={false}
        label={"Property Address"}
        maxLength={60}
        placeholder=''
        onChange={(e) => {

          setForm({ ...form, property_address: e })

        }}
        hints={errors.property_address ? [{ state: 'error', hint: errors.property_address }] : []}
      />
      <ODSInput
        value={form.property_post_code}
        disabled={false}
        label={"Property Postcode"}
        maxLength={8}
        placeholder=''
        onChange={(e) => {
          setForm({ ...form, property_post_code: e })

        }}
        hints={errors.property_post_code ? [{ state: 'error', hint: errors.property_post_code }] : []}
      />
      <DatePickerComponent
        label={"Rental Start Date"}
        value={form.rental_start_date ? new Date(form.rental_start_date) : null}
        onUpdateInput={(value) => {
          setForm({ ...form, rental_start_date: value })

        }}
        hints={errors.rental_start_date ? [{ state: 'error', hint: errors.rental_start_date }] : []}
      />
      <DatePickerComponent
        label={"Expected End date"}
        value={form.expected_end_date ? new Date(form.expected_end_date) : ""}
        onUpdateInput={(value) => {
          setForm({ ...form, expected_end_date: value })
        }}
        hints={errors.expected_end_date ? [{ state: 'error', hint: errors.expected_end_date }] : []}

      />
      <ODSInput
        value={form.rental_amount}
        disabled={false}
        label={"Rental Amount £"}
        maxLength={8}
        onChange={(e) => {
          setForm({ ...form, rental_amount: e.replace(/[^\d.-]/g, "") })
        }}
        hints={errors.rental_amount ? [{ state: 'error', hint: errors.rental_amount }] : []}
      />
      <ODSInput
        value={form.deposit_amount}
        disabled={false}
        label={"Deposit Amount £"}
        maxLength={8}
        onChange={(e) => {
          setForm({ ...form, deposit_amount: e.replace(/[^\d.-]/g, "") })
        }}
        hints={errors.deposit_amount ? [{ state: 'error', hint: errors.deposit_amount }] : []}
      />
      <ODSButton onPress={() => _onSave()} type='primary'
        restStyle={{ marginTop: 32, zIndex: -10, alignSelf: 'flex-end', width: 240 }}>Save Information</ODSButton>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default MyRentalInfoModal;
