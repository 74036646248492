import React from 'react';
import {
  StyleSheet,
  View,
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import ODSKnob from '../ODSKnob';

const ODSOpenflowBadge = ({ children, restStyle, iconName, iconColor, lIcon, type, knobCount }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
      <View
        style={[styles(iconColor).secondary, restStyle]}
      >
        {iconName ? <ODSIcon style={{ marginRight: 10 }} color={iconColor} icon={iconName} size={20} /> : null}
        <ODSText marginLeft={6} color={"black"} type={'xs'}>
          {children}
        </ODSText>
      </View>
      <ODSKnob
        size={55}
        bgColor={iconColor}
        pgColor={"white"}
        strokeWidth={3}
        progressPercent={knobCount}
        text={knobCount + "%"}
        color={iconColor}
        restStyle={{ margin: 0 }}
      />
    </View>
  );
};

const styles = (bcolor) =>
  StyleSheet.create({
    secondary: {
      backgroundColor: '#FFF',
      borderWidth: 1,
      borderColor: bcolor ? bcolor : "black",
      borderRadius: 40,
      paddingVertical: 6,
      paddingHorizontal: 10,
      alignItems: 'center',
      flexDirection: 'row',
      width: 110,
      height: 30,
      marginRight: 10
    },
    text: {
      color: '#FFFFFF',
    },
  });

export default ODSOpenflowBadge;
