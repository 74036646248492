import React from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';
import getScreenType from '../../../utils/getScreenType';
import { typographyStyles } from '../../../design-tokens/base-tokens';

interface TextProps {
  type?:
  | 'h1'
  | 'h2'
  | 'h2-light'
  | 'h3'
  | 'h4'
  | 'h4-light'
  | 'h5'
  | 'h6'
  | 'button'
  | 'sm'
  | 'md'
  | 'xs'
  | 'sm-bold'
  | 'md-bold'
  | 'xs-bold';
  color?: string;
  children?: any;
  size?: object;
  marginTop: any;
}

export const ODSText = ({ children, color, type, size, ...props }: TextProps) => {
  const screenType = getScreenType();

  const fontSize = {
    h1: {
      web: typographyStyles({ fontSize: 60 }).bold,
      tab: typographyStyles({ fontSize: 54 }).bold,
      phone: typographyStyles({ fontSize: 48 }).bold,
    },
    h2: {
      web: typographyStyles({ fontSize: 48 }).bold,
      tab: typographyStyles({ fontSize: 42 }).bold,
      phone: typographyStyles({ fontSize: 36 }).bold,
    },
    'h2-light': {
      web: typographyStyles({ fontSize: 36 }).regular,
      tab: typographyStyles({ fontSize: 36 }).regular,
      phone: typographyStyles({ fontSize: 36 }).regular,
    },
    h3: {
      web: typographyStyles({ fontSize: 36 }).bold,
      tab: typographyStyles({ fontSize: 32 }).bold,
      phone: typographyStyles({ fontSize: 28 }).bold,
    },
    h4: {
      web: typographyStyles({ fontSize: 28 }).bold,
      tab: typographyStyles({ fontSize: 24 }).bold,
      phone: typographyStyles({ fontSize: 24 }).bold,
    },
    'h4-light': {
      web: typographyStyles({ fontSize: 28 }).regular,
      tab: typographyStyles({ fontSize: 24 }).regular,
      phone: typographyStyles({ fontSize: 24 }).regular,
    },
    h5: {
      web: typographyStyles({ fontSize: 24 }).bold,
      tab: typographyStyles({ fontSize: 20 }).bold,
      phone: typographyStyles({ fontSize: 20 }).bold,
    },
    h6: {
      web: typographyStyles({ fontSize: 22 }).bold,
      tab: typographyStyles({ fontSize: 18 }).bold,
      phone: typographyStyles({ fontSize: 18 }).bold,
    },
    'h6-light': {
      web: typographyStyles({ fontSize: 22 }).regular,
      tab: typographyStyles({ fontSize: 18 }).regular,
      phone: typographyStyles({ fontSize: 18 }).regular,
    },
    button: {
      web: typographyStyles({ fontSize: 18 }).bold,
      tab: typographyStyles({ fontSize: 16 }).bold,
      phone: typographyStyles({ fontSize: 16 }).bold,
    },
    md: {
      web: typographyStyles({ fontSize: 18 }).regular,
      tab: typographyStyles({ fontSize: 16 }).regular,
      phone: typographyStyles({ fontSize: 16 }).regular,
    },
    sm: {
      web: typographyStyles({ fontSize: 16 }).regular,
      tab: typographyStyles({ fontSize: 14 }).regular,
      phone: typographyStyles({ fontSize: 14 }).regular,
    },
    xs: {
      web: typographyStyles({ fontSize: 14 }).regular,
      tab: typographyStyles({ fontSize: 12 }).regular,
      phone: typographyStyles({ fontSize: 12 }).regular,
    },
    'md-bold': {
      web: typographyStyles({ fontSize: 18 }).bold,
      tab: typographyStyles({ fontSize: 16 }).bold,
      phone: typographyStyles({ fontSize: 16 }).bold,
    },
    'sm-bold': {
      web: typographyStyles({ fontSize: 16 }).bold,
      tab: typographyStyles({ fontSize: 14 }).bold,
      phone: typographyStyles({ fontSize: 14 }).bold,
    },
    'xs-bold': {
      web: typographyStyles({ fontSize: 14 }).bold,
      tab: typographyStyles({ fontSize: 12 }).bold,
      phone: typographyStyles({ fontSize: 12 }).bold,
    },
  };

  const getSize = (type: string) => {
    if (fontSize[type]) {
      return fontSize[type][screenType];
    }
    return 'inherit';
  };

  const getColor = () => {
    return color || 'black';
  };

  const getFontSize = () => {
    return size ? { ...size } : getSize(type);
  };

  const textStyle: StyleProp<TextStyle> = {
    color: getColor(),
    ...getFontSize(),
    ...props,
  };
  return <Text style={textStyle} numberOfLines={props?.numberOfLines}>{children}</Text>;
};
