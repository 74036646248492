import React, { useState } from "react";
import { ImageBackground, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import styled from "styled-components";

import Hyperlink from "react-native-hyperlink";
import {
  Asterick,
  Bullet,
  BulletInlineRowText,
  BulletInlineText,
  BulletText,
  Container,
  PrivacyGrid,
  PrivacyGridBody,
  PrivacyGridHeader,
  PrivacyLeftBox,
  PrivacyRightBox,
  SectionInnerTitle,
  SectionPageTitle,
  SectionText,
  SectionTitle,
  SectionWrapper,
  TeamsSection,
} from "./styles";
import getPlatformType from "../../helpers/getPlatform";
import TripleTabs from "../../commonComponents/tripleTabs/tripleTabs";
import Privacy from "./privacy";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { backgroundSearchArr } from "../LandingPages/images/openFlo/searchArrayOpenflo";
import Terms from "./tnc";
import ODSTabs from "../../../openbrix-design-system/ui/atoms/ODSTabs";
import { useTheme } from "@emotion/react";
import { replaceAction } from "../../helpers/navigationHelpers";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton";

const Content = styled.div``;

const layoutType = getPlatformType();

const LegalScreen = (props) => {
  const linkStyle = {
    color: "#2980b9",
    fontSize: 16,
  };
  const openLink = (url) => {
    window.open(url);
  };


  const [state, setState] = useState({
    to: "",
  });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  React.useEffect(() => {
    if (props?.route?.params?.to) {
      window.scrollTo(0, null);
      setState({
        ...state,
        to: props?.route?.params?.to ? props?.route?.params?.to : "",
      });
    }
   setCurrentTabIndex(props?.route?.params?.to=="policy"?1:0)
  }, [props?.route?.params?.to]);


  const tab3 = (
    <SectionWrapper>
      <Hyperlink linkStyle={linkStyle} onPress={(url) => openLink(url)}>
        <SectionPageTitle>Cookies Policy</SectionPageTitle>
        <SectionText>Openbrix.co.uk/*  and tlyfe.co.uk {'\n'}Version 2.3. Updated: 14/8/2024</SectionText>

        <SectionTitle>What are Cookies?</SectionTitle>
        <SectionText>
        Cookies are small files which are stored on a user's (“you” or “your”) computer and hold data relating to a specific website. Cookies are placed on your computer by that website and accessed each time you browse the site.
        </SectionText>
        <SectionText>
        The cookies used by Openbrix.co.uk/* and tlyfe.co.uk - (the Sites) collect data to help us improve our Sites by seeing which parts of it are being used, how users are navigating to each page and what items on each page they prefer to click on.
        </SectionText>
        <SectionText>
          We have listed below each of the cookies that our Site uses, with detail as to why we use them. We have also
          detailed the time each cookie is kept on your system. This can be persistent (the cookie remains after you
          leave the site, to be used when you return) or session (the cookie is deleted once your visit is complete).
        </SectionText>
        <SectionText>
          You can opt-out of certain types of cookies via the Site. Opting out will apply only to the browser or device
          that you are using at the time, so you may need to change these settings again if you use other browsers or
          devices to access the Site.
        </SectionText>
        <SectionText>
          If you use your browser settings to block all cookies (including essential cookies) you may not be able to
          access all or parts of our Site. If you wish to restrict or block web browser cookies that are set on your
          device, you can do this through your browser settings; the help function within your browser should tell you
          how to achieve this. Alternatively, you may wish to visit www.aboutcookies.org, which contains comprehensive
          information on how to control browser cookies on a wide variety of desktop browsers.
        </SectionText>

        <SectionText>We use the following types of cookies:</SectionText>
        {/* <BulletInlineText>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>•Strictly necessary cookies :</Text>
          These are cookies that are required for the operation of our Site. They include, for example, cookies that enable you to log into secure areas of our Site, use a shopping cart or make use of payment services. Without these cookies, we will not be able to connect you to our Site properly.  
        </BulletInlineText>
        <BulletInlineText>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>
            •Analytical or performance cookies :
          </Text>
          These allow us to recognise and count the number of visitors and to see how visitors move
          around our website when they are using it. This helps us to improve the way our website
          works, for example, by ensuring that users are finding what they are looking for easily.
        </BulletInlineText>
        <BulletInlineText>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>•Functionality cookies :</Text>
          These are used to recognise you when you return to our website. This enables us to
          personalise our content for you, greet you by name and remember your preferences (for
          example, your choice of language or region).
        </BulletInlineText>
        <BulletInlineText>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>•Targeting cookies :</Text>
          These cookies record your visit to our website, the pages you have visited and the links
          you have followed. We will use this information to make our website and the advertising
          displayed on it more relevant to your interests. We may also share this information with
          third parties for this purpose.
        </BulletInlineText>
        <SectionText>
          You can find more information about the individual cookies we use and the purposes for
          which we use them in the table below. If you have any queries or require more information
          on this please email corporate@OpenBrix.co.uk
        </SectionText> */}
        <SectionTitle>Error Tracking Cookies </SectionTitle>
        <SectionText>These cookies help us to measure our Site traffic and analyse user behaviour with the goal of
          improving our service. </SectionText>
        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie amp ba62b8 and stripe mid</Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            these cookies are used to measure performance and log errors.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Sentry
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> 1 year.
          </BulletInlineRowText>
        </BulletInlineText>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie stripe sid </Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            these cookies are used to measure performance and log errors.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Sentry
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> 1 day.
          </BulletInlineRowText>
        </BulletInlineText>
        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie gsID and anonid </Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            these cookies are used to measure performance and log errors.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Sentry
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> Session.
          </BulletInlineRowText>
        </BulletInlineText>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie sentry-sc </Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            this cookie is used to track errors on the Site. A user ID is stored to track errors for a particular user.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Sentry
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> 1 day.
          </BulletInlineRowText>
        </BulletInlineText>
        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie session</Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            this cookie is used by sentry for error tracking or performance monitoring
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Sentry
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> 1 month.
          </BulletInlineRowText>
        </BulletInlineText>
        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie AMP_* </Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            these cookies are used to measure performance and log errors.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Sentry
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> 1 year.
          </BulletInlineRowText>
        </BulletInlineText>

        <SectionTitle>Strictly Necessary Cookies</SectionTitle>
        <SectionText>
          Some cookies are essential in order to enable you to move around the website and use its
          features. Without these cookies, we will not be able to connect you to our site properly.
        </SectionText>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie stripe mid</Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description</Text>
            this cookie stores user attributes to support the processing of payments and provide fraud prevention
            capabilities.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Stripe
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> 1 year.
          </BulletInlineRowText>
        </BulletInlineText>

        <SectionTitle>Analytical or Performance Cookies </SectionTitle>
        <SectionText>
          These cookies collect information about how visitors use our Site, for instance which page is viewed most
          often, and if error messages are seen. These cookies don't collect information that identifies a visitor. All
          information that these cookies collect is aggregated and therefore anonymous. It is only used to improve how
          our Site works.
        </SectionText>
        <SectionText>
          Like most websites, we use analytics software, such as Google Analytics. This software gathers anonymous data
          relating to how our Site is being used and then provides us with aggregated visitor statistics, number of page
          views etc. We supplement this data with our own analytics, which allows us to do more in-depth analysis of how
          people use our Site.
        </SectionText>
        <SectionText>
          We regularly add new features and change existing features to make the Site work as well as possible, and to
          make the experience as helpful as possible to all parties. To ensure that the changes we make are useful, we
          test variants of these changes across our Site and review data from our analytics system to see what works
          best. The cookies below allow us to do that.
        </SectionText>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie ga</Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            this cookie is associated with Google Universal Analytics. It is used to distinguish unique users by
            assigning randomly generated numbers as client identifiers. It is included in each page request and used to
            calculate visitors and data from site analytics reports.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text> Google Analytics
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> 2 Year(s)
          </BulletInlineRowText>
        </BulletInlineText>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie gat</Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            It is used to throttle the request rate, limiting the collection of data on high traffic
            sites.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text>
            Google Analytics
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text>
            10 minutes.
          </BulletInlineRowText>
        </BulletInlineText>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie gid</Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description </Text>
            this cookie distinguishes users.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text>
            Google Analytics
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text>1 day(s)
          </BulletInlineRowText>
        </BulletInlineText>

        <SectionTitle>Targeting Cookies and Online Behavioural Advertising</SectionTitle>
        <SectionText>
          These cookies record your visit to our Site, the pages you have visited and the links you have followed. We
          will use this information to make our Site and the advertising displayed on it more relevant to your
          interests. We may also share this information with third parties for this purpose.
        </SectionText>

        <SectionTitle style={{
          fontWeight: "bold",
          marginBottom: 5,
        }}>Cookie Google</SectionTitle>
        <SectionText>
          You can learn how Google handles information from sites or apps that use their services by visiting their
          website. If you don't want Google to use information based on your OpenBrix activity for the purpose of
          showing you more relevant advertisements, you can opt out by following the instructions specified on their
          website. For reference, Google operates the Google Display Network (comprising partner websites and some
          Google properties like YouTube), DoubleClick online ad serving products, the DoubleClick Ad Exchange, and
          Invite Media. Because Google uses DoubleClick and Invite technology, cookies placed by Google for behavioural
          advertising, including the opt-out cookie, will appear as DoubleClick and Invite cookies.
        </SectionText>


        <SectionTitle>Local Storage</SectionTitle>
        <SectionText>
          In addition to cookies, local storage allows us to use a small amount of space on your device to store
          information, in much the same way as cookies. We use this to store information which, we feel, enhances your
          journey through the Site by remembering previous actions and making them default for the future.
        </SectionText>
        <SectionInnerTitle>
          Strictly Necessary Local Storage Items.
        </SectionInnerTitle>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie: persist:root </Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description</Text> this stores user authentication parameters as well
            as cookie preference information and search optimization data.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text>
            Openbrix
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text> Session
          </BulletInlineRowText>
        </BulletInlineText>

        <BulletInlineText>
          <Text style={{
            fontWeight: "bold",
            marginBottom: 5,
          }}>Cookie: cookies_status </Text>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Description</Text> this stores cookie preference information.
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Application place </Text>
            Openbrix
          </BulletInlineRowText>
          <BulletInlineRowText>
            <Text style={{ fontWeight: "bold" }}>Persistent or Session </Text>Persistent
          </BulletInlineRowText>
        </BulletInlineText>

        <SectionTitle>If you have any queries or require more information regarding this policy, please contact us by
          email at: customerservices@OpenBrix.co.uk. </SectionTitle>
      </Hyperlink>
    </SectionWrapper>
  );
  const tabList = [
    {
      label: "Terms and Conditions",
      target: <Terms/>,
      targetfooter: null,
      id: "T/C",
    },
    {
      label: "Privacy Policy",
      targetfooter: null,
      target: <Privacy/>,
      id: "LEAGAL_POLICIY",
    },
    {
      label: "Cookies",
      target: tab3,
      targetfooter: null,
      id: "LEAGAL_COOKIES",
    },
  ];
  const setCurrentTab = (index) => {
    setCurrentTabIndex(index);
  };
  let arr = JSON.parse(JSON.stringify(backgroundSearchArr));
  const {colors}=useTheme();
  return (
    <BackGroundGenerator  props={props} hideMiddleLogo isLanding>
      <View style={{ flex:1 }}>
        {/* <TripleTabs
          isFullWidth={false}
          tabList={tabList}
          title=""
          activeTab={state?.to=="policy"?1:0}
          setCurrentTabIndex={setCurrentTab}
        /> */}
         <ImageBackground
                source={require('../../../openbrix-design-system/images/banner/web/my-account.png')}
                style={{
                    width: '100%',
                    height: '246px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
                resizeMode="cover"
            >
                <ODSButton
                    rIcon={'TB-Arrow-Left'}
                    restStyle={{ marginTop: 20, marginLeft: layoutType == "web" ? 60 : 20 }}
                    type="secondary"
                    disabled={false}
                    onPress={() => replaceAction(props?.navigation, 'Dashboard')}
                >
                    Back
                </ODSButton>
            </ImageBackground>
      <View style={{padding:16,width:layoutType=="phone"?'100%':'70%',alignSelf:'center',top:-80,zIndex:100}}>
        <ODSTabs
          items={['Terms & Conditions', 'Privacy Policy','Cookies']}
          selectedColor={colors.main.blue[700]}
          color={colors.main.blue[500]}
          activeTab={state?.to=="policy"?1:0}
          onTabChange={(index)=>setCurrentTab(index)}
        />


        <View style={styles.shadowBox}>
          {tabList[currentTabIndex].target}
        </View>
      </View>
      </View>
      {currentTabIndex == 0 ? (
        <TeamsSection>
          {/*

            <MemberHeader>
              <TeamHeader>Meet our team</TeamHeader>
            </MemberHeader>
            <Container>
              <MemberBoxWrapper>
                <MemberBox>
                  <MemberImage
                    style={{
                      borderTopRightRadius: 100,
                      borderTopLeftRadius: 100,
                      borderBottomRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  >
                    <Image
                      source={require("../../assets/images/Adam.jpg")}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "cover",
                      }}
                    ></Image>
                  </MemberImage>
                  <MemberName>
                    <MemberNameText>Adam Pigott</MemberNameText>
                  </MemberName>
                  <MemberDetails>
                    <MemberDetailsText>
                      Co-Founder / Chief Commercial Officer
                    </MemberDetailsText>
                  </MemberDetails>
                </MemberBox>
                <MemberBox>
                  <MemberImage
                    style={{
                      borderTopRightRadius: 100,
                      borderTopLeftRadius: 100,
                      borderBottomRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  >
                    <Image
                      source={require("../../assets/images/Shahad.jpg")}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "cover",
                      }}
                    ></Image>
                  </MemberImage>
                  <MemberName>
                    <MemberNameText>Shahad Choudhury</MemberNameText>
                  </MemberName>
                  <MemberDetails>
                    <MemberDetailsText>
                      Founder /  Chief Innovation Officer
                    </MemberDetailsText>
                  </MemberDetails>
                </MemberBox>
                <MemberBox>
                  <MemberImage
                    style={{
                      borderTopRightRadius: 100,
                      borderTopLeftRadius: 100,
                      borderBottomRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  >
                    <Image
                      source={require("../../assets/images/Jhon.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "cover",
                      }}
                    ></Image>
                  </MemberImage>
                  <MemberName>
                    <MemberNameText>John Hards</MemberNameText>
                  </MemberName>
                  <MemberDetails>
                    <MemberDetailsText>
                      Senior Strategic Advisor and Chair of Governance
                    </MemberDetailsText>
                  </MemberDetails>
                </MemberBox>

                {layoutType == "phone" ? (





                    <MemberBox>
                    <MemberImage
                      style={{
                        borderTopRightRadius: 100,
                        borderTopLeftRadius: 100,
                        borderBottomRightRadius: 100,
                        borderBottomLeftRadius: 100,
                      }}
                    >
                      <Image
                        source={{
                          uri:
                            "https://www.jagranimages.com/images/newimg/09122019/09_12_2019-house_pexels_19829618.jpg",
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                        }}
                      ></Image>
                    </MemberImage>


                    <MemberName>
                      <MemberNameText>Adam Pigott</MemberNameText>
                    </MemberName>
                    <MemberDetails>
                      <MemberDetailsText>
                        beatae vitae dicta sunt explicabo. Nemo enim ipsam
                        voluptatem quia voluptas sit aspernatur aut odit aut
                        fugit, sed quia consequuntur magni dolores eos qui
                        ratione voluptatem sequi
                      </MemberDetailsText>
                    </MemberDetails>



                  </MemberBox>

                ) : null}


              </MemberBoxWrapper>
            </Container>  */}
        </TeamsSection>
      ) : null}
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    padding: 32,
    top:-8,
    zIndex:1000,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius:12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default LegalScreen;
