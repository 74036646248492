import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View, Text, TouchableOpacity, ImageBackground, Image } from 'react-native';



import { useTheme } from '@emotion/react';
import getPlatformType from '../../helpers/getPlatform';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import ODSTabs from '../../../openbrix-design-system/ui/atoms/ODSTabs';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { MainSection } from '../openFlow/style';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { replaceAction } from '../../helpers/navigationHelpers';
import { BoxShadow } from './styles';
import UploadRef from './Progression';
import IdVerification from '../idVerification/idverificationNew';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { StatusButton } from '../openFlow/atoms/statusbutton';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import { ODSModal } from '../../../openbrix-design-system/ui/atoms/ODSModal/ODSModal.web';

const FccProgression = (props) => {
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [teanants, setUser] = React.useState(null);
  const [flowId, setFlowId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openFlow, setOpenFlowData] = React.useState({});
  const [reference, setReference] = React.useState(null);
  const [requestedDocs, setRequestDoc] = React.useState([]);
  const [applicant_id,setApplicantId]=useState('');
  const [latestUpdate, setLatestUpdate] = React.useState(null);
  const [openFlowGuarantor, setOpenFlowGuarantor] = React.useState({});
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const [showModal,setShowModal]=useState(false);
  const [openbankingURL,setOpenBankingURL] = React.useState('');
  const [ffcVerified,setFccVerified] = React.useState(false);
  const iFrameParams = useSelector((state) => state.authReducer?.iFrameParams);



  useEffect((_) => {
    window.scrollTo(11400, null);
  }, []);

  useEffect(() => {
    getFccReference();
  }, []);

  const getFccReference=()=>{
    let payload={
      branch:props?.route?.params?.branch,
      ref_id:props?.route?.params?.ref_id
    }
    if (true) {
      dispatch(
        tenantActions.getReferenceFromId(payload, (openflowData) => {
          if (openflowData) {
            const requestedDocumentIds = openflowData?.data?.documents.application_documents!="" && openflowData?.data?.documents.application_documents.split(",");
          
            const requestedObject = requestedDocumentIds
            ? requestedDocumentIds.map(requestedId => {
                const match = openflowData?.data.docs_names.find(doc => doc.document_id === requestedId);
                return {
                  document_id: requestedId,
                  document_name: match ? match.document_name : requestedId
                };
              })
            : [];
            setRequestDoc(requestedObject);
            setApplicantId(openflowData?.data?.applicant?.applicant_id);
            setOpenBankingURL(openflowData?.data?.documents?.openbankingrequest)
            setFccVerified(openFlowData?.data?.applicant?.idtaken);
  
            const latestUpdate1 = {
              handler: openflowData?.data?.handler,
              progress: openflowData?.data?.progress,
              documents: openflowData?.data?.documents,
            };

            if (latestUpdate1.handler ) {
              const handler = latestUpdate1.handler;
              latestUpdate1.handlerName = `${handler.title} ${handler.first_name} ${handler.last_name}`;
              latestUpdate1.handlerContactNumber = `${handler.phone_number}`;
            }
            if (latestUpdate1.progress ) {
              latestUpdate1.currentEmployment = {
                comments: latestUpdate1.progress.current_employment,
                status: latestUpdate1.progress.current_employment_status,
                last_updated: latestUpdate1.progress.current_employment_date_last_updated,
              };
              latestUpdate1.creditSearch = {
                comments: latestUpdate1.progress.search_details,
                status: latestUpdate1.progress.search_details_status,
                last_updated: latestUpdate1.progress.search_details_date_last_updated,
              };
              latestUpdate1.currentLiving = {
                comments: latestUpdate1.progress.current_living,
                status: latestUpdate1.progress.current_living_status,
                last_updated: latestUpdate1.progress.current_living_date_last_updated,
              };
              latestUpdate1.tenantSearch = {
                comments: latestUpdate1.progress.tenant_database,
                status: latestUpdate1.progress.tenant_database_status,
                last_updated: latestUpdate1.progress.tenant_database_date_last_updated,
              };
            }
            setLatestUpdate(latestUpdate1);
          } else {
            setReference(null);
            setLatestUpdate(null);
          }
        })
      );
      // dispatch(
      //   tenantActions.getFccRequestedReferenceDocs(props?.route?.params?.id, (documents) => {
      //     setRequestDoc(documents.data);
      //   })
      // );
    }
  }
  
  const themeObj = useTheme().colors;
  const {colors}=useTheme();
  const TabView = () => {
    return (
      <ODSTabs
        items={['Progression', 'ID Verification', 'Utilites']}
        selectedColor={themeObj.main.blue[700]}
        color={themeObj.main.blue[500]}
        activeTab={selectedIndex}
        onTabChange={(val) => setSelectedIndex(val)}
        restStyle={{
          borderRadius: 16,
          minHeight: 52,
          marginBottom: 20,
        }}
      />
     
    );
  };
  
  const FccVerified=()=>{
    return(  
      <View style={{backgroundColor:'white', shadowColor: '#101828',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2,padding:30,borderRadius:12}}>
      <ODSBadges restStyle={{ alignSelf: 'flex-end' }} type={'Completed'}>
           Completed
              </ODSBadges>
        <ODSText color={colors.text.primary} style={{ alignSelf: 'center' }} type={'h5'}>
        ID Verification
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={14}
          style={{ alignSelf: 'center' }}
          type={'md'}
        >
         {`
Your ID has been verified by Yoti via FCC Paragon.
You can use various documents to verify your ID but we recommend using a passport. If you have a British or Irish passport this will confirm your ‘right to rent’ in England.
If you wish to have access to the documents used for identity verification, please contact info@openbrix.co.uk

`}
        </ODSText>

        <Image source={require('../../../openbrix-design-system/images/logos/tick.png')}
        style={{width:48,height:48,marginTop:20,alignSelf:'center'}}
        resizeMode='contain'
        />
         <Image source={require('../../../openbrix-design-system/images/logos/verified.png')}
        style={{width:218,height:18,marginTop:10,alignSelf:'center'}}
        resizeMode='contain'
        />
         <Image source={require('../../../openbrix-design-system/images/logos/ukgov.png')}
        style={{width:250,height:55,marginTop:30,alignSelf:'center'}}
        resizeMode='contain'
        />
      </View>
  )
  }
  const renderId=()=>{
    return ffcVerified?
   FccVerified()
    :<IdVerification reference={true}/>;
  }


  return (
    <BackGroundGenerator props={props} isTLYF>
          <ImageBackground
          source={require('../../../openbrix-design-system/images/fcc/fcc-desktop.png')}
          style={{ width: '100%', height: 300 }}
          resizeMode={'cover'}
        >
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            restStyle={{ marginTop: 20, marginLeft: 20,width: 200 }}
            type="secondary"
          
            disabled={false}
            onPress={() => replaceAction(props?.navigation, 'FccChooseAddress')}
          >
           FCC Paragon
          </ODSButton>
        </ImageBackground>
      <MainSection style={{top:layoutType=='phone'?-20:-70}}>
        <View
          style={
            layoutType == 'web'
              ? { alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: 838 }
              : { alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '95%' }
          }
        >

          <TabView/>
        </View>

        <View
          style={
            layoutType == 'web'
              ? { width: "90%", alignSelf: 'center'}
              : { width: '95%',alignSelf:'center'}
          }
        >
          {selectedIndex == 1 && <View style={{width:layoutType=="phone"?'95%':layoutType=='tab'?'auto':'60%',alignSelf:'center'}}>{renderId()}</View>}
          {selectedIndex == 0 && (
            <UploadRef
              openFlow={openFlow}
              reference={reference}
              requestedDocs={requestedDocs}
              latestUpdate={latestUpdate}
              currentTenant={teanants}
              navigation={props.navigation}
              onRefresh={() => getFccReference()}
              teanant={teanants}
              status={props?.route?.params?.status}
              ref_id={props?.route?.params?.ref_id}
              applicant_id={applicant_id}
              branch={props?.route?.params?.branch}
              openbankingURL={openbankingURL}
            />
          )}
          {selectedIndex == 2 && 
           <View style={{ flexDirection: 'column',width:'100%' ,alignSelf:'center'}}>
         <BoxShadow wWidth={'600px'} tWidth={'494px'} style={{height:500,alignSelf:'center'}}>
                    <iframe width="100%"
                        frameBorder="0"
                        hspace="0" vspace="0"
                        height="100%"
                        src=
                        {generatePartnerUrl('TILE',iFrameParams)}
                        title="Home box" >
                    </iframe>
                    </BoxShadow>
                    </View>
            }
        </View>
       
      </MainSection>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({});
export default FccProgression;
