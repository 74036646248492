import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { View, Image, TouchableOpacity } from "react-native";
import { Link } from "@react-navigation/native";
import { ODSText } from "../../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../../openbrix-design-system/utils/getScreenType";
import { ODSIcon } from "../../../../openbrix-design-system/ui/atoms/ODSIcon";
import { Container } from "./styles";
import Drawer from "../Drawer";
const Header = ({ bgColor,onDrawerClick ,tlyfeProMenu,isOpenBrixLogo}) => {
    const { colors } = useTheme();
    const layout = getScreenType();
    const [drawerVisible,setDrawerVisible]=useState(false);
    return (
        <Container style={{ backgroundColor: bgColor }}>
                
                  <Drawer tlyfeProMenu={tlyfeProMenu} visible={drawerVisible} setVisible={(value)=>setDrawerVisible(value)} theme={bgColor} />
            <Image source={isOpenBrixLogo? require("./../../../assets/images/obxWhite.png"): require('../../../../openbrix-design-system/images/logos/tlyfe_logo_white.png')}
             style={isOpenBrixLogo?{width:150,height:22}: { width: 91, height: 42,resizeMode:'contain' }}
             />
            { layout == "web" ?
            tlyfeProMenu?
            <View style={{ flexDirection: 'row', gap: 40 }}>
                <Link to="/landing/home">
                <ODSText type='sm-bold' color={colors.text.inverted}>home</ODSText>
            </Link>
            <Link to="/landing/tlyfe">
                <ODSText type='sm-bold' color={colors.text.inverted}>tlyfe</ODSText>
            </Link>
            <Link to="/landing/tlyfePRO">
                <ODSText type='sm-bold' color={colors.text.inverted}>tlyfePRO</ODSText>
            </Link>
            
            
        </View>:
                <View style={{ flexDirection: 'row', gap: 40 }}>
                    <Link to="/introduction">
                        <ODSText type='sm-bold' color={colors.text.inverted} >Introduction</ODSText>
                    </Link>
                    <Link to="/rent-ready">
                        <ODSText type='sm-bold' color={colors.text.inverted} >Rent Ready</ODSText>
                    </Link>
                    <Link to="/tenant-insurance">
                        <ODSText type='sm-bold' color={colors.text.inverted} >Tenant Insurance</ODSText>
                    </Link>
                    <Link to="/creditbuilder">
                        <ODSText type='sm-bold' color={colors.text.inverted} >Credit Builder</ODSText>
                    </Link>
                    <Link to="/media-hub">
                        <ODSText type='sm-bold' color={colors.text.inverted} >Info Hub</ODSText>
                    </Link>
                    
                    
                    <Link to="/sign-in">
                        <ODSText type='sm-bold' color={colors.text.inverted} >Login</ODSText>
                    </Link>
                </View> : <TouchableOpacity onPress={()=>setDrawerVisible(true)}>
                    <ODSIcon icon="TB-Menu" size={30} color={colors.elements.invert} />
                </TouchableOpacity>
            }

        </Container>
    )
}
export default Header;