import moment from "moment";
import { View } from "react-native";
import { Entypo } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";

import SearchBox from "./searchbox";
import { Typography } from "../../style";
import { COLORS } from "../../../../constants/colorPallete";
import getPlatformType from "../../../../helpers/getPlatform";
import WideButton from "../../../../commonComponents/wideButton";

const OpenFlowDocs = (props) => {

  const layoutType = getPlatformType();
  const [documentArray, setDocuments] = useState([]);

  useEffect(() => {
    if (props?.allData) {
      setDocuments(props?.allData);
    }
  }, [props?.allData]);

  const DetailCard = ({ item, index }) => {
    let { name, url, date } = item;
    return (
      <View style={{  borderBottomColor: "#D9D9D9", borderBottomWidth: 1, width: '100%', margin: layoutType == "phone" ? 0 : 10 }}>
        <View key={index} style={{ flexDirection: "row", justifyContent: "space-between", width: "100%",marginVertical:15}}>
          <View style={{ flexDirection: "column", flex: 0.5,marginRight:5 }}>
            <Typography underline={true} align={"left"} color={COLORS.LGREEN} size={"16px"} bold={"700"}>
              {name}
            </Typography>
            <Typography top={"6px"} align={"left"} color={COLORS.BLACK} size={"14px"} bold={"400"}>
              {date && moment(date).format("DD/MM/YYYY")}
            </Typography>
          </View>
          <View style={{ flexDirection: "column", flex: 0.5, justifyContent: "center", alignItems: "end" }}>
            <WideButton
              childText={"View"}
              buttonType={"solid"}
              isLGreen
              backgroundColor={COLORS.LGREEN}
              gradiant
              stripetextColor={"black"}
              textSize={12}
              width={"138px"}
              height={"29px"}
              alignSelf={"end"}
              ricon={
                <Entypo
                  style={{
                    marginRight: 4,
                    marginLeft: 5,
                    marginTop: 2,
                  }}
                  name={"chevron-right"}
                  size={16}
                  color={"white"}
                />
              }
              onPress={() => {
                window.open(url, "_blank");
              }}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ marginTop: 20, flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
        {
          documentArray && documentArray.length ?
            <SearchBox data={props?.allData} result={(e) => setDocuments(e)} searchKey={"name"}/>
          :
            <Typography raj bold={"300"} color={"#1C1C1C"} size={"18px"} top={"10px"} bottom={"30px"} align={"center"}>
              Find all documents associated with your rental property.
            </Typography>
        }
        {
          documentArray && documentArray.length
            ? documentArray.map((item, index) => {
              return <DetailCard item={item} index={index} key={index}/>;
            })
          : null
        }
      </View>
    </View>
  );
};

export default OpenFlowDocs;
