import React, { useEffect, useState } from 'react';
import {  StyleSheet, TouchableOpacity, View, Platform } from 'react-native';
import { useTheme } from '@emotion/react';
import getScreenType from '../../../../utils/getScreenType';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import { ODSInput } from '../../../atoms/ODSInput';
import ODSBadges from '../../../atoms/ODSBadges';
import ODSCombobox from '../../../atoms/ODSComboBox';
import ODSButton from '../../../atoms/ODSButton';

const titles = [
  {
    value: 'Mr',
    label: 'Mr',
  },
  {
    value: 'Mrs',
    label: 'Mrs',
  },
  {
    value: 'Miss',
    label: 'Miss',
  },
  {
    value: 'Ms',
    label: 'Ms',
  },
  {
    value: 'Dr',
    label: 'Dr',
  },
  {
    value: 'Mx',
    label: 'Mx',
  },
  {
    value: 'N/A',
    label: 'N/A',
  },
];

const MyAccountCard = ({
  onPress,
  data,
  idStatus,
  onCopyPress
}) => {
  const { colors } = useTheme();
  const {
    title,
    firstName,
    lastName,
    contact,
    email
  } = data;

  const [isEdit, setEdit] = useState(false);
  const layout = getScreenType();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});


  useEffect(() => {
    setForm({
      title: title,
      firstName: firstName,
      lastName: lastName,
      contact: contact,
      email: email
    });
  }, [data]);

  const validateForm = () => {
    let valid = true;
    let errors = {};

 

    if (form.contact && (!/^\d{8,}$/.test(form.contact))) {
      errors.contact = 'Invalid contact number.';
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!form.email || !emailRegex.test(form.email)) {
      errors.email = 'Please enter a valid email address.';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const _onSave = () => {
    if (validateForm()) {
      onPress(form);
      setEdit(false);
      return true;
    }
    return false;
  };

  return (
    <View style={{ flex: 1, ...styles.shadowBox }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 32 }}>
        <ODSIcon icon={'TB-Profile-Info'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>My Profile</ODSText>
      </View>
      <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: layout === "phone" ? 'column' : 'row' }}>
        <View style={{ width: layout === "phone" && '100%' }}>
          <TouchableOpacity onPress={() => onCopyPress(data.opbrix_id)}>
            <ODSInput label='tlyfe ID' value={data.opbrix_id} editable={false} rightIcon='TB-Copy' />
          </TouchableOpacity>
        </View>
        <View style={{ width: layout === "phone" && '100%', flex: 1, marginLeft: layout === "phone" ? 0 : 20, marginTop: layout === "phone" ? 20 : 0 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <ODSText type='sm-bold' marginLeft={12} color={colors.text.primary}>ID Verification</ODSText>
            <ODSBadges type={idStatus}>{idStatus}</ODSBadges>
          </View>
        </View>
      </View>
      <View style={{ width: '100%', height: 1, backgroundColor: '#D0D6DD', marginVertical: 32 }} />
      <ODSText color={colors.text.primary} type={'sm-bold'}>Keep Your Profile Updated</ODSText>
      <ODSText width={'100%'} marginTop={4} color={colors.text.secondary} type={'sm'}>Update your personal details and check your ID status</ODSText>
      <View style={{ flexDirection: layout === "phone" ? 'column' : 'row', justifyContent: 'space-between', marginTop: 32 }}>
        <View style={{ flex: 1 }}>
          <ODSText color={colors.text.primary} type={'sm-bold'}>Title</ODSText>
          {isEdit ? (
            <ODSCombobox
              placeholder={'Select One'}
              onChange={(e) => setForm({ ...form, title: e.value })}
              width={'100%'}
              disabled={data.idverificationStatus === 2}
              value={{ value: form?.title, label: form?.title }}
             
              height={44}
              data={titles}
            />
            
          ) : (
            <ODSText width={'100%'} marginTop={4} color={colors.text.secondary} type={'sm'}>{form.title}</ODSText>
          )}
        </View>
        <View style={{ flex: 1, marginLeft: layout !== "phone" ? 16 : 0, zIndex: -10, marginTop: layout === "phone" ? 16 : 0 }}>
          <ODSText color={colors.text.primary} type={'sm-bold'}>First Name</ODSText>
          {isEdit ? (
            <ODSInput
              value={form.firstName}
              disabled={data.idverificationStatus === 2}
              placeholder='First Name'
              onChange={(e) => {
                if (/^[^\d]+$/.test(e)) {
                  setForm({ ...form, firstName: e })
                }
              }}
              hints={errors.firstName ? [{ state: 'error', hint: errors.firstName }] : []}
              style={{ marginTop: -6 }}
            />
          ) : (
            <ODSText width={'100%'} marginTop={4} color={colors.text.secondary} type={'sm'}>{form.firstName}</ODSText>
          )}
        </View>
      </View>
      <View style={{ flexDirection: layout === "phone" ? 'column' : 'row', justifyContent: 'space-between', marginTop: 32, zIndex: -10 }}>
        <View style={{ flex: 1 }}>
          <ODSText color={colors.text.primary} type={'sm-bold'}>Last Name</ODSText>
          {isEdit ? (
            <ODSInput
              value={form.lastName}
              placeholder='Last Name'
              onChange={(e) => {
                if (/^[^\d]+$/.test(e)) {
                  setForm({ ...form, lastName: e })
                }
              }}
              hints={errors.lastName ? [{ state: 'error', hint: errors.lastName }] : []}
              style={{ marginTop: -6 }}
              disabled={data.idverificationStatus === 2}
            />
          ) : (
            <ODSText width={'100%'} marginTop={4} color={colors.text.secondary} type={'sm'}>{form.lastName}</ODSText>
          )}
        </View>
        <View style={{ flex: 1, marginLeft: layout !== "phone" ? 16 : 0, marginTop: layout === "phone" ? 24 : 0 }}>
          <ODSText color={colors.text.primary} type={'sm-bold'}>Contact Number</ODSText>
          {isEdit ? (
            <ODSInput
              maxLength={10}
              value={form.contact}
              placeholder='Contact Number'

              onChange={(e) => {

                if (/^\d*$/.test(e)) {
                  setForm({ ...form, contact: e.replace(/[^0-9+]/g) });
                }
              }}
              hints={errors.contact ? [{ state: 'error', hint: errors.contact }] : []}
              style={{ marginTop: -6 }}
            />
          ) : (
            <ODSText width={'100%'} marginTop={4} color={colors.text.secondary} type={'sm'}>{form.contact}</ODSText>
          )}
        </View>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 32, zIndex: -10 }}>
        <View style={{ flex: 1 }}>
          <ODSText color={colors.text.primary} type={'sm-bold'}>Email Address</ODSText>
          {isEdit ? (
            <ODSInput
              value={form.email}
              placeholder='Email Address'
              disabled
              onChange={(e) => setForm({ ...form, email: e })}
              hints={errors.email ? [{ state: 'error', hint: errors.email }] : []}
              style={{ marginTop: -6 }}
            />
          ) : (
            <ODSText width={'100%'} marginTop={4} color={colors.text.secondary} type={'sm'}>{form.email}</ODSText>
          )}
        </View>
      </View>
      {isEdit ? (
        <ODSButton onPress={_onSave} restStyle={{ marginTop: 32, zIndex: -10, alignSelf: 'flex-end', width: 300 }}>Save Information</ODSButton>
      ) : (
        <ODSButton onPress={() => setEdit(true)} type='secondary' rIcon={'TB-Edit'} restStyle={{ marginTop: 32, zIndex: -10, alignSelf: 'flex-end', width: 200 }}>Edit Profile</ODSButton>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default MyAccountCard;
