import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import ODSTabs from '../../atoms/ODSTabs';
import { ODSText } from '../../atoms/ODSText';
import { useTheme } from '@emotion/react';
import MyDocs from './MyDocs';
import getScreenType from '../../../utils/getScreenType';
import PropertyDocs from './PropertyDocs';
import ShareCenter from './ShareCenter';
const DocNShare = ({
  DocumentData,
  onDelete,
  addDoc,
  propertyDocs,
  globalState,
  onShareSave,
  activeIndex = 0,
  navigation,
  onArchive
}) => {
  const { colors } = useTheme();
  console.log("DOCCCC",DocumentData)
  const layout = getScreenType();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    if (activeIndex) {
      setActiveTab(activeIndex);
    }
  }, [activeIndex]);
  return (
    <>
      <View style={{ flex: 1, padding: 16 }}>
        <View
          style={{
            width: layout == 'phone' ? '100%' : layout == 'web' ? 840 : 621,
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: layout == 'web' ? 60 : 8,
          }}>
          <ODSText type="h3" color={colors.text.primary} textAlign="center">
            Welcome to tlyfe Document Share Centre
          </ODSText>
          <ODSText type="md-bold" marginTop={20} textAlign="center" color={colors.text.primary}>
            With tlyfe you are in control of what data you choose to share with your agents.
          </ODSText>
          <ODSText type="sm" marginTop={24} textAlign="center" color={colors.text.primary}>
            Explore the tabs below to view, upload and share your documents safely with a temporary
            share code.
          </ODSText>
          <View
            style={{
              width: layout == 'phone' ? '100%' : layout == 'web' ? 630 : '100%',
              marginTop: layout == 'web' ? 64 : 20,
            }}>
            <ODSTabs activeTab={activeTab} onTabChange={setActiveTab} />
          </View>
          {activeTab == 0 && <MyDocs  data={[{hideDelete:true, file_path:"https://assets.publishing.service.gov.uk/media/65159e567c2c4a001395e1ea/DLUHC_How_to_rent_Oct2023.pdf",filename:"How to Rent Guide"},...DocumentData]} 
          onDelete={onDelete} 
          addDoc={addDoc} 
          navigation={navigation} 
          onArchive={onArchive} />}
          
          {activeTab == 1 && <PropertyDocs data={propertyDocs} />}
          {activeTab == 2 && (
            <ShareCenter
              globalState={globalState}
              DocumentData={DocumentData}
              onShareSave={onShareSave}
            />
          )}
        </View>
      </View>
    </>
  );
};
export default DocNShare;
