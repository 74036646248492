import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTheme } from '@emotion/react';
import { ODSText } from '../ODSText';
import ODSRadioButton from '../ODSRadioButton';

const ODSRadioGroup = ({ width = '100%', checked, onChange, label, color, disabled }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState(checked);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);
  const onChecked = (value) => {
    setSelected(value);
    onChange && onChange(value);
  };

  return (
    <View
      style={{
        width: width,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <View style={{ flex: 0.6, justifyContent: 'flex-end' }}>
        <ODSText flexWrap={'wrap'} type="sm-bold" color={theme.colors.text.primary}>
          {label}
        </ODSText>
      </View>

      <View style={{ flex: 0.4, flexDirection: 'row', alignItems: 'center' }}>
        <ODSRadioButton
          label={'Yes'}
          checked={selected == 'yes' ? true : false}
          onPress={() => onChecked('yes')}
        />
        <ODSRadioButton
          style={{ marginLeft: 20 }}
          label={'No'}
          checked={selected == 'no' ? true : false}
          onPress={() => onChecked('no')}
        />
      </View>
    </View>
  );
};

export default ODSRadioGroup;
