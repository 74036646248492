/* eslint-env browser */
export const registerServiceWorker=()=>{
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker
      .register('/firebase-messaging-sw.js', { scope: '/' })
      .then(function (registration) {
          return registration.scope;
      })
      .catch(function (err) {
          return err;
      });
    
    });
    navigator.serviceWorker
.register('/expo-service-worker.js', { scope: '/' })
.then(function (info) {
  navigator.serviceWorker.register('/OneSignalSDKWorker.js', { scope: '/' }).then(resp=>{

  }).catch(err=>{
    
  
    
  });
})
.catch(function (error) {
  console.info('Failed to register service-worker', error);
});
  }
}

