import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import { useTheme } from '@emotion/react';
import getScreenType from '../../../utils/getScreenType';
import RenderArchiveDocument from './RenderArchiveDocs';
const ArchiveDocs = ({
  DocumentData,
  onDelete,
  onUnArchive
}) => {
  const { colors } = useTheme();
  const layout = getScreenType();

  return (
    <>
      <View style={{ flex: 1, padding: 16 }}>
        <View
          style={{
            width: layout == 'phone' ? '100%' : layout == 'web' ? 840 : 621,
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: layout == 'web' ? 60 : 8,
          }}>
          <ODSText type="h3" color={colors.text.primary} textAlign="center">
          Documents Archive
          </ODSText>
          <ODSText type="md" marginTop={20} textAlign="center" color={colors.text.primary}>
          You can find all your past or deleted documents here. You can restore them or delete them permanently.
          </ODSText>

        <RenderArchiveDocument data={DocumentData} onDelete={onDelete} onUnArchive={onUnArchive}  />
        
        </View>
      </View>
    </>
  );
};
export default ArchiveDocs;
