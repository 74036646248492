import React, { useState } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { getTokens } from '../../../design-tokens/color-tokens';
import ODSBadges from '../../atoms/ODSBadges';
import { ODSText } from '../../atoms/ODSText';
import { ODSIcon } from '../../atoms/ODSIcon';


const OpenflowCard = ({
  message,
  title,
  restStyle,
  onPress,
  type,
  description,
  listIcon,
}) => {
  const themeObj = getTokens('light').colors;
  const StatusButton = (type) => {
    if (type === 1) {
      return 'Completed';
    } else if (type == 0) {
      return 'Not Started';
    } else if (type == 4) {
      return 'In Progress';
    } else if (type == 5) {
      return 'Action Required';
    } else if (type == 6) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  };

  return (
    <TouchableOpacity onPress={onPress} style={[styles(themeObj).containerStyle, restStyle]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.6 }}>
          <ODSIcon color={themeObj.main.blue[400]} icon={listIcon} size={26} />
          <ODSText width={'85%'} marginLeft={10} color="black" type={'button'}>
            {title}
          </ODSText>
        </View>
        <View
          style={{ flexDirection: 'row', flex: 0.4, justifyContent: 'flex-end', marginLeft: 6 }}>
          <ODSBadges restStyle={{ alignSelf: 'flex-end' }} type={StatusButton(type)}>
            {StatusButton(type)}
          </ODSBadges>
        </View>
      </View>
      <ODSText marginTop={10} color="black" type={'sm'}>
        {description}
      </ODSText>
      <ODSText marginTop={10} color="black" type={'sm'}>
        {message}
      </ODSText>
    </TouchableOpacity>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    containerStyle: {
      width: '100%',
      borderRadius: 12,
      shadowColor: '#101828',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 2,
      backgroundColor: theme.neutral['white'],
      padding: 20,
      borderWidth: 1,
      borderColor: '#D0D6DD',
    },
  });

export default OpenflowCard;
