import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  ImageBackground,
  Image,
  TouchableOpacity,
} from 'react-native';
import { ODSText } from '../ODSText';
import ODSButton from '../ODSButton';
import ODSBadges from '../ODSBadges';
import getScreenType from '../../../utils/getScreenType';
import { getTokens } from '../../../design-tokens/color-tokens';
import ODSOpenflowBadge from '../ODSOpenflowBadge';

const ODSTiles = ({
  subtitle,
  title,
  status,
  restStyle,
  source,
  btnTheme,
  onPress,
  isRight,
  topImage,
  btnTitle,
  subtitle2,
  textColor,
  subtitleType,
  btnCOlor,
  resize,
  imageWidth,
  logosource,
  ODSOpenflow,
  ODSOpenflowColor,
  knobCount,
  iconName,
  onCardClick
}) => {
  const themeObj = getTokens('light').colors;
  const Wrapper = onCardClick ? TouchableOpacity : View
  return (
    <Wrapper
      onPress={() => {
        onCardClick()
      }}
      style={[styles(themeObj, isRight).containerStyle, restStyle]}>
      <ImageBackground
        source={source ? source : require('./Affordablity_Hero.png')}
        style={{ width: imageWidth ? imageWidth : 140 }}
        resizeMode={resize ? resize : "cover"}
        imageStyle={
          isRight
            ? { borderTopRightRadius: 20, borderBottomRightRadius: 12 }
            : { borderTopLeftRadius: 20, borderBottomLeftRadius: 12 }
        }
      />
      <View style={{ marginHorizontal: 12, marginVertical: 10, flex: 1 }}>
        {topImage && <Image source={topImage} style={{ width: 120, height: 60, resizeMode: 'contain' }} />}
        {status ? (
          <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={status}>
            {status}
          </ODSBadges>
        ) : null}
        {ODSOpenflow ?
          <ODSOpenflowBadge
            iconColor={ODSOpenflowColor}
            iconName={iconName}
            knobCount={knobCount}
          >
            {ODSOpenflow}
          </ODSOpenflowBadge> : null

        }
        <View style={{}}>
          <ODSText marginTop={6} color={textColor ? textColor : "black"} type={'h5'}>
            {title}
          </ODSText>
          {subtitle ? <ODSText marginTop={8} color={textColor ? textColor : themeObj?.text.secondary} type={subtitleType ? subtitleType : 'sm'}>
            {subtitle}
          </ODSText> : null}
          {subtitle2 ?
            <ODSText marginBottom={12} color={textColor ? textColor : themeObj?.text.secondary} type={'sm'}>
              {subtitle2}
            </ODSText> : null
          }
        </View>
        {ODSOpenflow ? null : <View style={{ marginTop: 20, marginBottom: logosource ? 0 : 24 }}>
          <ODSButton
            restStyle={{ width: '100%' }}
            type="primary"
            disabled={false}
            themeColor={btnTheme}
            btnCOlor={btnCOlor ? btnCOlor : ""}
            onPress={onPress}>
            {btnTitle ? btnTitle : "Go"}
          </ODSButton>
        </View>}

        {logosource ?
          <View style={{ width: "100%", marginTop: 20 }}>
            <Image source={logosource}
              style={{ height: 49, width: "160%" }}
              resizeMode='cover'
            />
          </View>
          : null}

      </View>
    </Wrapper>
  );
};

const styles = (theme, isRight) =>
  StyleSheet.create({
    containerStyle: {
      display: 'flex',
      width: getScreenType() == 'phone' ? '100%' : 360,
      borderRadius: 20,
      shadowColor: '#000',
      shadowOffset: { width: 1, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 8,
      elevation: 2,
      backgroundColor: 'white',
      flexDirection: isRight ? 'row-reverse' : 'row',
    },
  });

export default ODSTiles;
