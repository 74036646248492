import React, { useEffect } from 'react';
import {
    StyleSheet,
    View,
    Platform,
    Linking,
} from 'react-native';
import getScreenType from '../../../utils/getScreenType';
import { useTheme } from '@emotion/react';
import WebView from 'react-native-webview';
import generatePartnerUrl from '../../../utils/functions';
const ODSHomeBoxTile = ({
    fullWidth = false,
    navigation,
    iFrameParams
}) => {

    const theme = useTheme();
    const handleWebViewMessage = (event: any) => {
        const { data } = event.nativeEvent;
        const fromWeb = JSON.parse(data);
        console.log('fromWeb', fromWeb);
        if (fromWeb?.val?.type && fromWeb?.val?.type === 'doctoopen_inapp') {
            if (fromWeb?.val?.Url == "https://partners.staging.home.cc/tlyfe/external/services") {
                navigation?.navigate('Marketplace');
            } else {
                Linking.openURL(fromWeb?.val?.Url);
            }

        }
    };
    const handleIframeMessage = (event: { origin: string; data: string; }) => {
        if (event.origin === 'https://partners.staging.home.cc') {
            try {
                const fromIframe = JSON.parse(event.data);
                console.log('fromIframe', fromIframe);
                if (fromIframe?.val?.type === 'doctoopen_inapp') {
                    if (fromIframe?.val?.Url === 'https://partners.staging.home.cc/tlyfe/external/services') {
                        navigation?.navigate('Marketplace');
                    } else {
                        Linking.openURL(fromIframe?.val?.Url);
                    }
                }
            } catch (error) {
                console.error('Error parsing message from iframe', error);
            }
        }
    };

    useEffect(() => {
        if (Platform.OS == "web") {
            window.addEventListener('message', handleIframeMessage);
        }
        return () => {
            if (Platform.OS == "web") {
                window.removeEventListener('message', handleIframeMessage);
            }
        };
    }, []);
    return (
        <View style={[styles(theme.colors, fullWidth).containerStyle]}>
            {Platform.OS == "web" ?
                <div className="rounded">
                    <iframe width="100%"
                        frameBorder="0"
                        hspace="0" vspace="0"
                        height="100%"
                        src={generatePartnerUrl("TILE", iFrameParams)}
                        title="Home box" >
                    </iframe>
                </div> : <WebView
                    scalesPageToFit={true}
                    bounces={false}
                    javaScriptEnabled
                    style={{ height: "100%", width: '100%', resizeMode: 'cover', flex: 1, borderRadius: 8, opacity: 0.99 }}
                    injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
                    source={{ uri: generatePartnerUrl("TILE", iFrameParams) }}
                    automaticallyAdjustContentInsets={false}
                    onMessage={handleWebViewMessage}
                />
            }

        </View>
    );
};

const styles = (theme, fullWidth) =>
    StyleSheet.create({
        containerStyle: {
            width: getScreenType() == "phone" ? "96%" : fullWidth ? 628 : 405,
            borderRadius: 12,
            shadowColor: '#101828',
            height: 427,
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
            backgroundColor: theme.neutral[100],
            alignSelf: 'center'
        },
    });

export default ODSHomeBoxTile;
