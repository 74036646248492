import React from 'react';
import {StyleSheet, View,  Platform } from 'react-native';
import { useTheme } from '@emotion/react';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';


const SavedPropertiesCard = ({
}) => {
  const { colors } = useTheme();

  return (
    <View style={{ flex:1,...styles.shadowBox}}>
        <View style={{justifyContent:'center',alignItems:'center',flexDirection:'row',marginBottom:12}}>  
           <ODSIcon icon={'TB-House-Fav'} size={24} color={colors.button.main} />
             <ODSText  type='h5' marginLeft={12} color={colors.text.primary}>Saved Properties</ODSText>
        </View>
        <ODSText textAlign='center'  type='sm' marginTop={12} color={colors.text.primary}>Here you can find the properties you saved</ODSText>
        <ODSText textAlign='center'  type='md' marginTop={48} color={colors.text.primary}>Coming soon!</ODSText>
    </View>
  );
};
const styles = StyleSheet.create({
    shadowBox: {
      backgroundColor: '#fff',
      marginHorizontal:20,
      padding:32,
      borderRadius:12,
      ...Platform.select({
        ios: {
          shadowColor: '#000',
          shadowOffset: { width: 1, height: 3 },
          shadowOpacity: 0.15,
          shadowRadius: 8,
        },
        android: {
          elevation: 4,
        },
        web :{
            shadowColor: '#000',
            shadowOffset: { width: 1, height: 3 },
            shadowOpacity: 0.15,
            shadowRadius: 8,
        }
      }),
    },
  });


export default SavedPropertiesCard;
