import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import ODSButton from '../../atoms/ODSButton';
import { getTokens } from '../../../design-tokens/color-tokens';
import ODSTiles from '../../atoms/ODSTiles';
import getScreenType from '../../../utils/getScreenType';

const BoxItem = ({ restStyle, uri, onOpenflowClick, data }) => {
    const themeObj = getTokens('light').colors;

    // Fallback for image source
    const imageSource = uri
        ? { uri }
        : require('../../../images/Bitmap.png');

    // Reusable function for ODSTiles
    const renderTile = (flowId, title, subtitle2, openFlowText, flowColor, knobCount, iconName, postcode) => (
        <ODSTiles
            source={{ uri: 'http://assets.openbrix.co.uk/certificate-doc-oWHno-1663268711051-0.png' }}
            title={title}
            imageWidth={80}
            subtitle={postcode}
            subtitle2={subtitle2}
            ODSOpenflow={openFlowText}
            ODSOpenflowColor={flowColor}
            iconName={iconName}
            knobCount={knobCount}
            onCardClick={() => onOpenflowClick(flowId)}
            restStyle={getScreenType() == 'web' ?
                {
                    width: 378,
                    borderWidth: 0.001,
                    borderColor: themeObj.disabled.element,
                    margin: 10,
                } : {
                    width: '100%',
                    borderWidth: 0.001,
                    borderColor: themeObj.disabled.element,
                    marginVertical: 10,
                }}
        />
    );

    return (
        <View style={[styles(themeObj).containerStyle, restStyle]}>
            <ODSText
                textAlign="center"
                marginBottom={20}
                color="black"
                type="h5"
            >
                My Tenancies
            </ODSText>
            <View style={getScreenType() !== 'phone' ? styles(themeObj).tilesContainerWeb : styles(themeObj).tilesContainerMobile}>
                {data?.length > 0 ? <FlatList
                    numColumns={getScreenType() == 'web' && 2}
                    data={data}
                    nestedScrollEnabled={true}
                    contentContainerStyle={{ paddingRight: 5 }}
                    keyExtractor={(item) => item.openflow_id.toString()}
                    renderItem={({ item }) => renderTile(item?.openflow_id, item?.property?.property_name, "Agent: " + item?.branch?.name, item?.is_renewed ? "Renewal" : "Move In", item?.is_renewed ? themeObj?.theme.pink : themeObj?.button.main, item?.percentage_completed, 'Frame-1', item?.property?.postcode)}
                /> : <ODSText type="md" color={themeObj.text.primary} textAlign="center">No Tenancies found</ODSText>}
            </View>
        </View>
    );
};

const styles = (theme) =>
    StyleSheet.create({
        containerStyle: {
            width: getScreenType() == 'web' ? 840 : getScreenType() == 'phone' ? "100%" : 500,
            borderRadius: 12,
            shadowColor: '#101828',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 2,
            elevation: 2,
            paddingVertical: 32,
            paddingHorizontal: 24,
            backgroundColor: theme.neutral['white'],
            borderWidth: 1,
            borderColor: '#D0D6DD',
            alignSelf: 'center',
            marginBottom: 100,
            top: getScreenType() == 'web' ? 0 : getScreenType() == 'phone' ? -50 : -50
        },
        tilesContainerWeb: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
        tilesContainerMobile: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
    });

export default BoxItem;
