import React from 'react';
import { Dimensions, Image, ImageBackground, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import getPlatformType from '../../helpers/getPlatform';
import { BoxShadow, Wrapper } from './styles';
import { replaceAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
const WIN_HEIGHT = Dimensions.get('window').height;


const FccHome = (props) => {
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [page, setPage] = React.useState(1);
 
  const themeObj = getTokens('light').colors;
  

  const renderPage1 = () => {
    return (
      <View style={{ flexDirection: 'column',top:-100 }}>
        <BoxShadow style={{ alignSelf: 'center' }} tWidth={'70%'} wWidth={'620px'} bottom={'10px'} right={'0px'}>
            <>
            <Image source={require('../../../openbrix-design-system/images/logos/fcc-logo.png')} style={{width:190,height:60,resizeMode:'contain',alignSelf:'center',margin:30}}/>
              <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              Welcome to the FCC Paragon Tenant Portal
              </ODSText>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={14}
                style={{ alignSelf: 'center' }}
                type={'md'}
              >
               {`Your Letting Agent has selected FCC Paragon to conduct your referencing.

FCC Paragon has partners with tlyfe to provide all our tenants a tenant lifecycle app that brings innovation and efficiency to your rental experience.

In addition to providing you real time data on your tenant reference progression, tlyfe also provides the following benefits:

• Credit History reporting
• Secure data storage
• Rental Passport
• Pre-verification and qualification
• ID Verification & Right-to-Rent`}
              </ODSText>
           
              <View
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'space-evenly',
                }}
              >
                <ODSButton
                  lIcon={'TB-Arrow-Right'}
                  themeColor={themeObj.main.green}
                  restStyle={{ marginTop: 20, alignSelf:'right' }}
                  type="primary"
                  disabled={false}
                  onPress={() => replaceAction(props.navigation,'FccChooseAddress')}
                >
                 Next
                </ODSButton>
              </View>
             
            </>
        </BoxShadow>
         
      </View>
    );
  };

  

  return (
    <>
     <BackGroundGenerator props={props} isTLYF>
    <View style={{ minHeight: WIN_HEIGHT - 300 }}>
      <View style={{ width: '100%' }}>
        <ImageBackground
          source={require('../../../openbrix-design-system/images/fcc/fcc-desktop.png')}
          style={{ width: '100%', height: 300 }}
          resizeMode={'cover'}
        >
          <ODSButton
            rIcon={'TB-Arrow-Left'}
            restStyle={{ marginTop: 20, marginLeft: 20,width: 200 }}
            type="secondary"
          
            disabled={false}
            onPress={() => replaceAction(props?.navigation, 'Dashboard')}
          >
           Dashboard
          </ODSButton>
        </ImageBackground>
      {renderPage1()}
      </View>
    </View>
  </BackGroundGenerator>

    </>
  );
};

export default FccHome;
