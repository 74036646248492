import React, { useEffect } from 'react';
import { Image, Platform, Text, View } from 'react-native';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import moment from 'moment';
import Modal  from "react-native-modal";
import { useDispatch, useSelector } from 'react-redux';
import DatePickerComponent from '../../commonComponents/datepicker/DatePickerComponent';
import getPlatformType from '../../helpers/getPlatform';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { Button } from '../openFlow/atoms/button';
import { RTR_URL } from '../../helpers/connectionHelpers';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { BoxShadow, Wrapper } from './styles';
import { getProfile, noRtrRequired, updateIsBritishPassword } from '../../redux/actions/auth';
import { COLORS } from '../../constants/colorPallete';
import { Typography } from '../openFlow/style';
import WideButton from '../../commonComponents/wideButton';
import { CustomButton } from '../identifyAndFinance/stylesNew';
import { navigateAction } from '../../helpers/navigationHelpers';
import { showToaster } from '../../redux/actions/toast';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { navigate } from '../../navigation/RootNavigation';
import { LineSaperator } from '../tds/style';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import { ODSInput } from '../../../openbrix-design-system/ui/atoms/ODSInput';
import Portal from '../../../openbrix-design-system/ui/atoms/ODSModal/potral';

const layoutType = getPlatformType();

const RightToRent = (props) => {
  const themeObj = getTokens('light').colors;
  const dispatch = useDispatch();
  const { showDocsOnSingleSection } = props;
  const data = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const isBritishPassword = useSelector((state) => state.authReducer?.isBritishPassword);

  const [showModal, setShowModal] = React.useState();
  const [rtr_code, setRtr_code] = React.useState('');
  const [rtr_dob, setRtr_dob] = React.useState('');
  const [isProcessCompleted, setIsProcessCompleted] = React.useState(false);

  const [isBritishPassowrd, setIsBritishPassport] = React.useState('');

  const [teanants, setUser] = React.useState(null);

  useEffect(() => {
    if (data) {
      console.log("propsRightToRent",data?.right_to_rent_dob)
      setUser(data);
      if (data?.right_to_rent_dob=="N/A") {
        setIsBritishPassport('yes');
        dispatch(updateIsBritishPassword('yes'));
      }
      else if (data?.right_to_rent_dob) {
        setIsProcessCompleted(true);
        setIsBritishPassport('no');
        dispatch(updateIsBritishPassword('no'));
      }
    }
  }, [data?.right_to_rent_dob]);
  console.log('teanantsteanants', teanants);
  const openLinkRTR = async (id) => {
    let u = RTR_URL;

    if (Platform.OS === 'web') {
      window.open(
        u,
        '_blank' // <- This is what makes it open in a new window.
      );
    } else {
      Linking.openURL(u).catch((err) => console.error('An error occurred', err));
    }
  };

  const getRtrCode = () => {
    if (teanants) {
      if (teanants.right_to_rent && teanants.right_to_rent !="N/A") {
        return teanants.right_to_rent;
      } else {
        return "-";
      }
    } else {
      return 'N/A';
    }
  };
  const getRtrCodeDob = () => {
    if (teanants) {
      if (teanants.right_to_rent_dob && teanants.right_to_rent_dob !="N/A" ) {
        return moment(teanants.right_to_rent_dob).format('DD-MM-YYYY');
      } else {
        return '-';
      }
    } else {
      return 'N/A';
    }
  };

  const renderRtr = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <BoxShadow wWidth={400}>
          <View
            style={{
              marginBottom: '24px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ flexDirection: 'column' }}>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'button'}
              >
                Date of Birth
              </ODSText>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={5}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                {getRtrCodeDob()}
              </ODSText>
            </View>
            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'button'}
              >
                Right-to-Rent code
              </ODSText>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={5}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                {getRtrCode()}
              </ODSText>
            </View>
          </View>
          <ODSButton
            rIcon={'TB-Edit'}
            restStyle={{ alignSelf: 'flex-end' }}
            type="secondary"
            themeColor={themeObj.main.green}
            disabled={false}
            onPress={() => setShowModal(true)}
          >
            Edit
          </ODSButton>
        </BoxShadow>
        <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'phone' ? '100%' : 400,
            alignItems: 'center',
          }}
        >
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            textAlign={'center'}
            type={'sm'}
          >
            Congratulations, you have verified your “right-to-rent”. If you wish to verify your
            Identity and have a verified, digital copy available to share with whomever you want,
            when you want, please click below.
          </ODSText>
          <ODSButton
            rIcon={'TB-Share'}
            restStyle={{ marginTop: 20, alignSelf: 'center', width: 300 }}
            type="secondary"
            disabled={false}
            onPress={() => navigate('Document Share Center',{activeIndex: 2 })}
          >
            My Share Centre
          </ODSButton>
          <ODSButton
            lIcon={'TB-Arrow-Right'}
            restStyle={{ marginTop: 20, alignSelf: 'center', width: 300 }}
            type="primary"
            disabled={false}
            onPress={() => navigate('PreQualificationIdAndVerification')}
          >
            Verify your ID digitally
          </ODSButton>
        </View>
      </View>
    );
  };
  const renderCompleteSection = () => {
    return (
      <BoxShadow style={{marginBottom:20}} wWidth={400} bottom={'10px'} right={'19px'}>
        <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Completed'}>
          Completed
        </ODSBadges>
        <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
          Right-to-Rent
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={10}
          style={{ alignSelf: 'center' }}
          type={'md'}
        >
          If you do not hold a British or Irish passport Passport, you are required by law to verify
          your "right to rent" in the UK.
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={10}
          style={{ alignSelf: 'center' }}
          type={'md'}
        >
          Please click "start" to begin the process.
        </ODSText>
        <LineSaperator
          style={{ marginVertical: 20, opacity: 0.4, backgroundColor: themeObj.elements.neutral }}
        />
        <ODSButton
          rIcon={'TB-Rotating'}
          restStyle={{ alignSelf: 'flex-end' }}
          type="secondary"
          themeColor={themeObj.main.green}
          disabled={false}
          onPress={() => {
            setIsProcessCompleted(false);
            setIsBritishPassport('');
            dispatch(updateIsBritishPassword(''));
            setUser(null);
          }}
        >
          Renew
        </ODSButton>
      </BoxShadow>
    );
  };
  const renderInprogressState = () => {
    return (
      <>
        {teanants?.idverification != 1 && (
          <BoxShadow wWidth={400} style={{ marginBottom: 20 }} bottom={'10px'} right={'19px'}>
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              Do you hold a British or Irish passport?
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={10}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              If you do not hold a British or Irish passport Passport, you are required by law to
              verify your "right to rent" in the UK.
            </ODSText>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 25,
              }}
            >
              <ODSButton
                restStyle={{ alignSelf: 'flex-end',width:"40%" }}
                type={isBritishPassword == 'yes' ? 'primary' : 'secondary'}
                themeColor={themeObj.main.green}
                disabled={false}
                
                onPress={() => {
                  setIsBritishPassport('yes');
                  dispatch(updateIsBritishPassword('yes'));
                  dispatch(noRtrRequired((resss)=>{
                    dispatch(getProfile())
                  }))
                }}
              >
                Yes
              </ODSButton>
              <ODSButton
                restStyle={{ alignSelf: 'flex-end',width:"40%" }}
                type={isBritishPassword == 'no' ? 'primary' : 'secondary'}
                themeColor={themeObj.main.salmon}
                disabled={false}
                onPress={() => {
                  setIsBritishPassport('no');
                  dispatch(updateIsBritishPassword('no'));
                }}
              >
                No
              </ODSButton>
            </View>
          </BoxShadow>
        )}

        {isBritishPassword == 'no' ? (
          <BoxShadow wWidth={400} bottom={'20px'} right={'19px'}>
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              Verify your “right-to-rent” in the UK
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={10}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              Please click "start" to begin the process.
            </ODSText>

            <View style={{ marginTop: 30 }}>
              <ODSButton
                lIcon={'TB-Arrow-Right'}
                restStyle={{}}
                type={'primary'}
                themeColor={themeObj.main.purple}
                disabled={false}
                onPress={() => openLinkRTR()}
              >
                Get Started
              </ODSButton>
            </View>
          </BoxShadow>
        ) : null}
      </>
    );
  };

  if (data?.right_to_rent == 'N/A' && teanants?.idverification == 2) {
    return (
      <Wrapper>
        <View style={{ flexDirection: 'column' }}>
          <BoxShadow wWidth={'710px'} bottom={'10px'} right={'19px'}>
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              Right-to-Rent
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={10}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              Your identity has been verified by Yoti, a UK Govt approved Identity Service Provider
              with a British or Irish passport, therefore right to rent is satisfied. You should
              share the verified document with your agent or landlord.
            </ODSText>
          </BoxShadow>
          <BoxShadow
            wWidth={'710px'}
            style={{ marginTop: 10, justifyContent: 'center', alignItems: 'center' }}
          >
            <Image
              source={require('../../assets/images/Progress.svg')}
              resizeMode="contain"
              style={{
                height: 64,
                width: 64,
                marginBottom: 20,
                alignSelf: 'center',
              }}
            />
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              Your ID is verified
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={10}
              style={{ alignSelf: 'center' }}
              type={'md'}
              textAlign={'center'}
            >
              Your ID has been verified. If you used your British or Irish passport then you do not
              need to do anything further.{' '}
            </ODSText>

            <ODSButton
              lIcon={'TB-Arrow-Right'}
              restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
              type="primary"
              themeColor={themeObj.main.purple}
              disabled={false}
              onPress={() => navigate('PreQualificationPreReference')}
            >
              Go to ID Verification
            </ODSButton>
          </BoxShadow>
        </View>
      </Wrapper>
    );
  } else {
    return (
      <>
        <Wrapper>
          <View style={{ flexDirection: 'column' }}>
            {isProcessCompleted ? renderCompleteSection() : renderInprogressState()}
          </View>
          {isBritishPassword == 'yes' || teanants?.idverification == 1 ? (
            teanants?.idverification == 1 ? (
              <BoxShadow style={{ marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                
                <MaterialCommunityIcons
                  name="progress-clock"
                  size={50}
                  color="#FFC059"
                  style={{ marginBottom: 15 }}
                />
         <ODSText textAlign="center" style={{ alignSelf: 'center' }} type={'h5'}>
                 In Progress
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={10}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
               Your ID Verification is in progress. Please come back later to check the
                  verification status.
            </ODSText>
              </BoxShadow>
            ) : (
              <BoxShadow  wWidth={'344px'} bottom={'10px'} right={'19px'}>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  {`Your British or Irish passport proves you have a ‘right to rent’ in England. Letting agents and landlords are required to retain proof of your ‘right to rent’.

You can either take your original passport, in person, to your agent before the tenancy commences or you can verify it digitally through tlyfe and provide the agent with a share code so they may download a verified copy.`}
                </ODSText>

                <View style={{ marginTop: 20 }}>
                  <ODSButton
                    lIcon={'TB-Arrow-Right'}
                    restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
                    type="primary"
                    themeColor={themeObj.main.purple}
                    disabled={false}
                    onPress={() =>
                      navigateAction(props.navigation, 'PreQualificationIdAndVerification')
                    }
                  >
                    Verify your ID digitally
                  </ODSButton>
                </View>
              </BoxShadow>
            )
          ) : isBritishPassword == 'no' ? (
            <View style={{ flexDirection: 'column' }}>
              {teanants?.right_to_rent_dob ? (
                renderRtr()
              ) : (
                <BoxShadow right={'19px'} wWidth={'344px'}>
                  <ODSText
                    color={themeObj.neutral[700]}
                    style={{ alignSelf: 'center' }}
                    type={'md'}
                    marginBottom={20}
                  >
                    Once you complete the process, you can then add your government issued share
                    code, which will be shared with your letting agent.
                  </ODSText>

                  <DatePickerComponent
                    key="datepicker"
                    testID={'rtr-date'}
                    placeholder={'Date of birth'}
                    inputLabel={{
                      fontSize: 16,
                      color: '#000',
                      fontWeight: 'bold',
                    }}
                    value={rtr_dob}
                    disabled={status == 'review'}
                    style={{ marginBottom: 22 }}
                    is18yrs
                    onUpdateInput={(value) => {
                      setRtr_dob(value);
                    }}
                  />
                  <ODSInput
                    placeholder="Right-to-Rent code"
                    label="Right-to-Rent code"
                    top={20}
                    onChange={(value) => setRtr_code(value)}
                    value={rtr_code}
                  />

                  <ODSButton
                    restStyle={{ marginTop: 20 }}
                    type={'primary'}
                    themeColor={themeObj.main.purple}
                    disabled={false}
                    onPress={() => {
                      if (!rtr_dob) {
                        return dispatch(showToaster('error', 'Please fill in the date of birth'));
                      }
                      if (!rtr_code) {
                        return dispatch(
                          showToaster('error', 'Please fill in the right to rent code')
                        );
                      }
                      dispatch(
                        tenantActions.postRtrCode(
                          {
                            right_to_rent_code: rtr_code,
                            right_to_rent_dob: rtr_dob,
                          },
                          (data) => {
                            setShowModal(false);
                            setRtr_code('');
                            setRtr_dob('');
                          },
                          getProfile
                        )
                      );
                    }}
                  >
                    Save
                  </ODSButton>
                </BoxShadow>
              )}
            </View>
          ) : null}
        </Wrapper>
<Portal>
        <Modal
          isVisible={showModal}
          transparent={true}
          coverScreen={true}
          hasBackdrop={true}
          backdropOpacity={0}
          onBackdropPress={() => setShowModal(false)}
          style={{
            margin: 0,
            width: '100%',
            flex: 1,
            backgroundColor: '#00000080',
            paddingVertical: layoutType == 'phone' ? '5%' : '1%',
            paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
          }}
        >
          <View
            style={{
              width: layoutType == 'phone' ? '365px' : '600px',
              backgroundColor: '#FAFAFA',
              borderRadius: 20,
              overflow: 'hidden',
              alignSelf: 'center',
              padding: layoutType == 'phone' ? '5%' : '2%',
            }}
          >
                    <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
          Right-to-Rent
        </ODSText>

        <ODSText marginTop={10} style={{ alignSelf: 'center' ,marginTop:10}} type={'sm'}>
        Have you completed your right to rent process via gov.uk site?
        </ODSText>

        <ODSText marginTop={10} style={{ alignSelf: 'center' ,}} type={'sm'}>
        If you have not, please close this pop up and click "start" on the right to rent
        section.
        </ODSText>
        <ODSText marginTop={10}  marginBottom={30}  style={{ alignSelf: 'center' ,marginTop:10,marginBottom:30}} type={'sm'}>
        If you have completed the process and have you share code please add it below.
        </ODSText>
           
            <CustomTextInput
              textSize={18}
              width={'100%'}
              testID={'rtr-code'}
              title="Enter Share code below *"
              onChangeText={(value) => setRtr_code(value)}
              name="rtr_code"
              prefilledValue={rtr_code}
              bColor={COLORS.LGREEN}
            />

            <DatePickerComponent
              key="datepicker"
              testID={'rtr-date'}
              is18yrs
              placeholder={'Enter Date *'}
              inputLabel={{
                fontSize: 16,
                color: '#000',
                fontWeight: 'bold',
              }}
              value={rtr_dob}
              disabled={status == 'review'}
              onUpdateInput={(value) => {
                setRtr_dob(value);
              }}
            />
            <View
              style={{
                width: '100%',
                marginTop: 30,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
                 <ODSButton
                    restStyle={{ marginTop: 20,width:220 }}
                    type={'primary'}
                  
                    disabled={false}
                    onPress={() => {
                      if (!rtr_dob) {
                        return dispatch(showToaster('error', 'Please fill in the date of birth'));
                      } else if (!rtr_code) {
                        return dispatch(showToaster('error', 'Please fill in the right to rent code'));
                      } else {
                        dispatch(
                          tenantActions.postRtrCode(
                            {
                              right_to_rent_code: rtr_code,
                              right_to_rent_dob: rtr_dob,
                            },
                            (data) => {
                              setShowModal(false);
                              setRtr_code('');
                              setRtr_dob('');
                            },
                            getProfile
                          )
                        );
                      }
                    }}
                  >
                    Save
                  </ODSButton>
                  <ODSButton
                    restStyle={{ marginTop: 20 ,width:220}}
                    type={'secondary'}
                  
                    disabled={false}
                    onPress={() => {
                      setShowModal(false);
                      setRtr_code('');
                      setRtr_dob('');
                    }}
                  >
                    Close
                  </ODSButton>
            
            </View>
          </View>
        </Modal>
        </Portal>
      </>
    );
  }
};

export default RightToRent;
