import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { Image, ImageBackground, Platform, TouchableOpacity, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { CheckoutForm } from './checkoutForm';
import { Typography } from '../openFlow/style';
import { COLORS } from '../../constants/colorPallete';
import { showToaster } from '../../redux/actions/toast';
import getPlatformType, { getImage } from '../../helpers/getPlatform';
import { BoxShadow, Wrapper } from './styles';
import WideButton from '../../commonComponents/wideButton';
import { STRIPEKEY } from '../../helpers/connectionHelpers';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';

const BASIC_REWARD_URL = 'https://www.youtube.com/';
const PREMIUM_REWARD_URL =
  'https://dev.azure.com/openbrix/Openbrix/_backlogs/backlog/Operations/Features?workitem=6828';
const RewardsComponent = (props) => {
  const hasUserSavedCookies = useSelector(
    (state) => state?.cookiesReducer?.userHasSavedCookiesPreferences
  );
  const authDataFromMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const themeObj = getTokens('light').colors;

  let stripePromise = null;
  if (hasUserSavedCookies) {
    stripePromise = loadStripe(STRIPEKEY);
  }
  if (authDataFromMobile) {
    stripePromise = loadStripe(STRIPEKEY);
  }
  const layoutType = getPlatformType();
  const [page, setPage] = React.useState(1);
  const [url, setUrl] = React.useState(null);
  const [rewardsData, setRewardsData] = useState(null);
  const [rewardsUrl, setRewardsUrl] = useState(null);

  const [clientSecret, setClientSecret] = React.useState('');
  const globalState = useSelector((state) => state?.tenant);
  const isSuscribed = rewardsData?.data?.status === 'active';
  const [reload, setReload] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      tenantActions.getRewardsSubsStatus((data) => {
        setRewardsData(data);
      })
    );
  }, [reload]);
  console.log('rewardsUrl', rewardsUrl);
  useEffect(() => {
    dispatch(
      tenantActions.getRewardsSubsStatus((data) => {
        setRewardsData(data);
      })
    );
  }, []);
  console.log('rewardsDatarewardsDatarewardsData', rewardsData);
  const openPreRefUrl = async (URLS) => {
    let u = URLS;
    if (Platform.OS === 'web') {
      await window.open(
        u,
        '_blank' // <- This is what makes it open in a new window.
      );
    } else {
      await Linking.openURL(u).catch((err) => console.error('An error occurred', err));
    }
    setReload(1);
  };

  const openrewardsUrl = () => {
    dispatch(
      tenantActions.getRewardsUrl((data) => {
        if (data) {
          openPreRefUrl(data);
        }
      })
    );
  };

  const startPayment = () => {
    let payload = {
      productId: 1001,
    };
    console.log('payloadpayload', payload);
    dispatch(
      tenantActions.startPayment(
        payload,
        (data) => {
          console.log('startPayment datadatadata', data);
          if (data?.status) {
            setClientSecret(data?.data?.clientSecret);
            setPage(3);
          } else {
            dispatch(showToaster('error', 'Failed to start payment'));
          }
        },
        true
      )
    );
  };

  const renderPage1 = () => {
    return (
      <>
        {isSuscribed ? null : (
          <BoxShadow Width={'344px'} bottom={'10px'} right={'19px'} style={{ padding: 0 }}>
            <ImageBackground
              source={getImage('rewards_premium.png', true)}
              style={{
                height: 109,
                width: '100%',
                borderTopRightRadius: 8,
              }}
              imageStyle={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
              resizeMode={'cover'}
            />
            <View style={{ padding: 24 }}>
              <ODSText
                textAlign={'center'}
                color={themeObj.neutral[700]}
                style={{ alignSelf: 'center' }}
                type={'button'}
              >
                Basic rewards
              </ODSText>
              <ODSText
                textAlign={'center'}
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                Access great savings at over 110 outlets, start saving online today!
              </ODSText>
              <View style={{ marginTop: 20 }}>
                <ODSButton
                  lIcon={'TB-Arrow-Right'}
                  restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
                  type="secondary"
                  themeColor={themeObj.main.purple}
                  disabled={false}
                  onPress={() => openrewardsUrl(rewardsUrl)}
                >
                  Access for free
                </ODSButton>
              </View>
            </View>
          </BoxShadow>
        )}

        <>
          <BoxShadow wWidth={'344px'} bottom={'10px'} right={'19px'} style={{ padding: 0 }}>
            {!isSuscribed ? (
              <>
                <ImageBackground
                  source={getImage('rewards_basic.png', true)}
                  imageStyle={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
                  style={{
                    height: 109,
                    width: '100%',
                  }}
                  resizeMode={'cover'}
                />
                <View style={{ padding: 24 }}>
                  <ODSText
                    textAlign={'center'}
                    color={themeObj.neutral[700]}
                    style={{ alignSelf: 'center' }}
                    type={'button'}
                  >
                    Premium rewards
                  </ODSText>
                  <ODSText
                    textAlign={'center'}
                    color={themeObj.neutral[700]}
                    marginTop={10}
                    style={{ alignSelf: 'center' }}
                    type={'sm'}
                  >
                    Subscribe to access
                  </ODSText>
                  <ODSText
                    textAlign={'center'}
                    color={themeObj.neutral[700]}
                    marginTop={10}
                    style={{ alignSelf: 'center' }}
                    type={'sm'}
                  >
                    Unlock even bigger and better digital reward cards, stored in your mobile
                    wallet, to be redeemed at over 100 outlets, including M&S, Tesco, Tui, BooHoo,
                    John Lewis, Costa, Uber, Airbnb, Pizza Express, Ikea and many more!
                  </ODSText>

                  <ODSButton
                    lIcon={'TB-Arrow-Right'}
                    restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
                    type="primary"
                    themeColor={themeObj.main.purple}
                    disabled={false}
                    onPress={() => startPayment()}
                  >
                    Subscribe
                  </ODSButton>
                  <ODSText
                    textAlign={'center'}
                    color={themeObj.main.purple[700]}
                    marginTop={10}
                    style={{ alignSelf: 'center' }}
                    type={'sm'}
                  >
                    £1.00 per month
                  </ODSText>
                </View>
              </>
            ) : (
              <>
                <TouchableOpacity onPress={() => openrewardsUrl(rewardsUrl)}>
                  <ImageBackground
                    source={getImage('rewards_premium_suscribed.png', true)}
                    style={{
                      height: 109,
                      width: '100%',
                      borderTopRightRadius: 8,
                    }}
                    imageStyle={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
                    resizeMode={'cover'}
                  />
                </TouchableOpacity>
                <View style={{ padding: 24 }}>
                  <ODSText
                    textAlign={'center'}
                    color={themeObj.neutral[700]}
                    style={{ alignSelf: 'center' }}
                    type={'button'}
                  >
                    Premium rewards
                  </ODSText>
                  <ODSText
                    textAlign={'center'}
                    color={themeObj.neutral[700]}
                    marginTop={10}
                    style={{ alignSelf: 'center' }}
                    type={'sm'}
                  >
                    Your journey to saving thousands starts now. Shop smarter, live better, and
                    enjoy the perks of being a tlyfe Rewards member!
                  </ODSText>
                  <ODSButton
                    restStyle={{ width: 200, marginTop: 20, alignSelf: 'center' }}
                    type="primary"
                    themeColor={themeObj.main.pink}
                    disabled={false}
                    onPress={() => openrewardsUrl(rewardsUrl)}
                  >
                    Go
                  </ODSButton>
                  <ODSText alignSelf='center' type='md' marginTop={12} color={themeObj.text.primary}>Subscription renews: {
                          new Date(rewardsData?.data?.current_period_end * 1000)
                            .toISOString()
                            .split('T')[0]
                        }</ODSText>
                  <ODSText alignSelf='center' type='md' marginTop={4} color={themeObj.text.primary}>Payment Method: Card</ODSText>
                  <ODSButton
          restStyle={{ marginTop:12 }}
          themeColor={themeObj.main.pink}
          type="primary"
          disabled={false}
        //   onPress={onPress}
        >Update payment method</ODSButton>
     <ODSButton
          restStyle={{marginTop:12 }}
          themeColor={themeObj.main.salmon}
          type="secondary"
          disabled={false}
        //   onPress={onPress}
        >Cancel Subscription</ODSButton>
                </View>
              </>
            )}
          </BoxShadow>
        </>
        {isSuscribed ? (
          <BoxShadow wWidth={'344px'} bottom={'10px'} right={'19px'} style={{ padding: 0 }}>
            <ImageBackground
              source={getImage('rewards_premium_suscribed.png', true)}
              style={{
                height: 109,
                width: '100%',
                borderTopRightRadius: 8,
              }}
              imageStyle={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
              resizeMode={'cover'}
            />
            <View style={{ padding: 24 }}>
              <ODSText
                textAlign={'center'}
                color={themeObj.neutral[700]}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                If your premium plan expires, you’ll still have access to basic rewards
              </ODSText>
            </View>
          </BoxShadow>
        ) : null}
      </>
    );
  };

  const renderPage3 = () => {
    return (
      <View style={{ flexDirection: 'column', marginTop: 60 }}>
        <ODSText color={themeObj.neutral[700]} type={'button'} marginTop={20}>
          Checkout
        </ODSText>
        <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'phone' ? '100%' : '344px',
            marginTop: 20,
          }}
        >
          <BoxShadow
            wWidth={'344px'}
            bottom={'10px'}
            right={'19px'}
            style={{
              marginBottom: 24,
              padding: 25,
              borderColor: COLORS.LGREEN,
              borderWidth: 1,
            }}
          >
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <ODSText color={themeObj.neutral[700]} type={'sm'}>
                Premium rewards monthly subscription
              </ODSText>
              <ODSText color={themeObj.neutral[700]} type={'sm'}>
                £1.00 (inc. VAT)
              </ODSText>
            </View>

            <ODSText
              textAlign={'flex-start'}
              color={themeObj.neutral[700]}
              style={{ marginTop: 10 }}
              marginTop={20}
              type={'button'}
            >
              Total cost: £1
            </ODSText>
          </BoxShadow>
          {authDataFromMobile ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
          {hasUserSavedCookies ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
        </View>
      </View>
    );
  };

  const renderPage6 = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: layoutType == 'phone' ? '100%' : '344px',
        }}
      >
        <Image
          source={require('./../../assets/images/failed.png')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
          marginTop={20}
        >
          Payment Failed!
        </ODSText>

        <View
          style={{
            marginTop: 23,
            width: 42,
            height: 1.5,
            alignSelf: 'center',
            backgroundColor: COLORS.LGREEN,
          }}
        />
        <TouchableOpacity
          onPress={() => {
            setPage(1);
            setReload(5);
          }}
        >
          <ODSText
           
            textAlign={'center'}
            color={themeObj.main.purple[700]}
            style={{ alignSelf: 'center' }}
            type={'button'}
            marginTop={20}
          >
            Back{' '}
            <AntDesign
              style={{
                justifyContent: 'flex-end',
                marginLeft: 10,
              }}
              name="arrowright"
              size={20}
              color={themeObj.main.purple[700]}
            />
          </ODSText>
        </TouchableOpacity>
      </View>
    );
  };

  const renderPage4 = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: layoutType == 'phone' ? '100%' : '344px',
        }}
      >
        <Image
          source={require('./../../assets/images/Progress.svg')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
          marginTop={20}
        >
          Payment successful!
        </ODSText>

        <View
          style={{
            marginTop: 23,
            width: 42,
            height: 1.5,
            alignSelf: 'center',
            backgroundColor: COLORS.LGREEN,
          }}
        />
        <TouchableOpacity
          onPress={() => {
            setPage(1);
            setReload(6);
          }}
        >
          <ODSText
            textAlign={'center'}
            color={themeObj.main.purple[700]}
            style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}
            type={'button'}
            marginTop={20}
          >
            Premium rewards{' '}
            <AntDesign
              style={{
                justifyContent: 'flex-end',
                marginLeft: 10,
              }}
              name="arrowright"
              size={20}
              color={themeObj.main.purple[700]}
            />
          </ODSText>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={{ marginBottom: 20}}>
      {page === 1 ? (
        <View
          style={{
            width: layoutType != 'web' ? '94%' : 648,
            alignSelf: 'center',
            alignItems: 'center',
            marginBottom: 20,
            padding: 20,
          }}
        >
          <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
            tlyfe Rewards
          </ODSText>

          {isSuscribed ? (
            <View
              style={{
                alignSelf: 'center',
              }}
            >
              <ODSText
                textAlign={'center'}
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'button'}
              >
                Access huge savings, every day!
              </ODSText>
              <ODSText
                textAlign={'center'}
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                Access everyday savings, for free, from hundreds of High Street retailers with Basic
                rewards or sign up to Premium rewards for just £1.00p per month to unlock some
                serious offers!
              </ODSText>
            </View>
          ) : (
            <View
              style={{
                alignSelf: 'center',
              }}
            >
              <ODSText
                textAlign={'center'}
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'button'}
              >
                Welcome to tlyfe Rewards!
              </ODSText>
              <ODSText
                textAlign={'center'}
                color={themeObj.neutral[700]}
                marginTop={10}
                style={{ alignSelf: 'center' }}
                type={'sm'}
              >
                We are thrilled to have you on board and help you start saving big on huge
                discounts, from Fashion to Food & Drink, from Travel to Groceries and everything
                in-between! Your tlyfe Rewards membership is your golden ticket to savings galore.
              </ODSText>
            </View>
          )}
        </View>
      ) : null}
      <Wrapper>
        {page == 1
          ? renderPage1()
          : page == 3
          ? renderPage3()
          : page == 4
          ? renderPage4()
          : page == 6
          ? renderPage6()
          : null}
      </Wrapper>
    </View>
  );
};

export default RewardsComponent;
