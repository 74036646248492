import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageBackground, Text, View, Image } from 'react-native';

import { ListItem } from '../atoms/listitem';
import RentalPortal from '../../rentalPortal';
import { COLORS } from '../../../constants/colorPallete';
import { getProfile } from '../../../redux/actions/auth';
import getPlatformType, { getImage } from '../../../helpers/getPlatform';
import BackButton from '../../../commonComponents/BackButton';
import CircularProgress from '../../../commonComponents/circle';
import { tenantActions } from './../openFlowRedux/tenant.actions';
import { navigateAction } from '../../../helpers/navigationHelpers';
import { BoxShadow, CircularWrapper } from '../../identifyAndFinance/stylesNew';
import { MainContainer, PropertyDetailsSection, Wrapper } from './home.styles';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import HomeBoxCard from '../../identifyAndFinance/HomeBox';
import { StyleSheet } from 'react-native';
import { Typography } from '../../tds/style';
import IdRef from '../../../assets/images/idRef.png';
import WideButton from '../../../commonComponents/wideButton';
import { callIfAvailable, sendEmail } from '../../../helpers/callEmailHelper';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';
import { ChildrenView } from '../../idVerification/styles';
import { Dimensions } from 'react-native';
import PropertyBox from '../../../../openbrix-design-system/ui/molecules/propertyBox';
import RentSetting from '../../../../openbrix-design-system/ui/molecules/rentSetting';
import OpenflowCard from '../../../../openbrix-design-system/ui/molecules/propertyBox/openflowCard';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import BoxItem from '../../../../openbrix-design-system/ui/molecules/openflowHome/boxItem';

const layoutType = getPlatformType();
const WIN_HEIGHT = Dimensions.get('window').height;

const OpenFlow = (props) => {
  const { navigation } = props;
  const dispatch = useDispatch();

  const globalState = useSelector((state) => state?.tenant);


  useEffect(() => {
    dispatch(tenantActions.getTenantFlow());
  }, []);

  return (
    <BackGroundGenerator
      openFlowData={{
        title: '',
        desc: '',
        buttonText: '',
        message: '',
      }}
      isOpenflowContainer
      props={props}
      isTLYF
      navigation={navigation}
      isOpenflowHome={'Dashboard'}
    >
      <BoxItem 
      data={globalState?.flowData}
      onOpenflowClick={(e)=>{
        navigateAction(navigation,"My Tenancy",{id:e})
      }}
      uri="http://assets.openbrix.co.uk/certificate-doc-oWHno-1663268711051-0.png"
      />
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  cardStyle: {
    display: 'flex',
    backgroundColor: '#ffffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRadius: 10,
    marginHorizontal: 5,
  },
  contentContainer: {
    backgroundColor: '#FFF',
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
  },
  commonContainerStyles: {
    borderRadius: 16,
    paddingHorizontal: 10,
    paddingVertical: 6,
    flexDirection: 'row',
  },
  floIcon: {
    width: 30,
    height: 30,
    elevation: 5,
    display: 'flex',
    shadowRadius: 3.84,
    shadowOpacity: 0.25,
    shadowColor: '#000',
    tintColor: COLORS.LGREEN,
    shadowOffset: { width: 0, height: 2 },
    marginRight: 8,
  },
  progress: {},
  floBackg: {
    width: 100,
    height: 127,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  imageBackgroundContentContainer: {
    marginTop: 10,
    marginBottom: 30,
    width: layoutType == 'phone' ? '90%' : 449,
  },
});
export default OpenFlow;
