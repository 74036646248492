import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { getAffordStatus } from "./../../redux/actions/preReferencing";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "../../constants/colorPallete";
import { Typography } from "../openFlow/style";
import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import WideButton from "../../commonComponents/wideButton";
import { getTokens } from "../../../openbrix-design-system/design-tokens/color-tokens";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";

const AffordNew = (props) => {
  const {
    onCloseClick,
    showClose,
    isGuarantor
  } = props;
  const themeObj = getTokens('light').colors;
  const dispatch = useDispatch();
  const preFilledData = useSelector((state) => state?.preReferencing?.ccqData?.affordibilityCheck);
  const [monthlyIncome, setMonthlyIncome] = useState(null);
  useEffect(() => {
    if (preFilledData) {
      if (preFilledData?.monthly_rent > 1) {
        setMonthlyIncome(preFilledData?.salary);
      } else if (preFilledData?.monthly_rent == 1) {
        setMonthlyIncome(preFilledData?.salary);
      }
    }
  }, [preFilledData]);
  const checkFieldDataYearly = (val) => {
    setMonthlyIncome(val.replace(/[^\d.-]/g, ""));
  };

  return (
    <View style={{
      padding: 24,
      justifyContent:"center",
      alignItems:"center",
      
    }}>
       <ODSText style={{ alignSelf: 'center' }} type={'button'}>
       {isGuarantor? "Guarantor Affordability": "Affordability"}
            </ODSText>
            <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                  Income may include salary, pension and savings
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                  Please "self certify" your Yearly gross income (£) *
                </ODSText>
      
      <CustomTextInput
        height={41}
        width="100%"
        borderWidth={1}
        bColor={COLORS.GREEN}
        title={""}
        id={""}
        prefilledValue={monthlyIncome}
        onChangeText={(val) => {
          checkFieldDataYearly(val);
        }}
      />
      <ODSButton
          restStyle={{ width: 300, marginTop: 20,alignSelf:"center" }}
          disabled={!monthlyIncome || monthlyIncome?.length==0}
          onPress={() => {
            let data = {
              salary: monthlyIncome,
              monthly_rent: 1,
            };
            dispatch(getAffordStatus(data, false,isGuarantor));
            if (showClose) {
              onCloseClick();
            }
          }}
        >
        Submit
        </ODSButton>
        <ODSButton
          restStyle={{ width: 300, marginTop :20,alignSelf:"center"  }}
          type="secondary"
          disabled={false}
          onPress={() => {
            onCloseClick();
          }}
        >
        Close
        </ODSButton>
    

    </View>
  );
};
export default AffordNew;
