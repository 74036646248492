import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ImageBackground, View, TouchableOpacity, Text } from "react-native";

import ShareCode from './shareCode';
import OpenFlowDocs from './openFlowDocs';
import ShareDocument from './shareDocument';
import { Typography } from "../../../openFlow/style";
import { deleteDoc, fetchDoc, getArchiveDocuments, unArchiveDocument } from '../../../../redux/actions/auth';
import { COLORS } from '../../../../constants/colorPallete';
import { TabContainer } from '../../../idVerification/styles';
import getPlatformType from "../../../../helpers/getPlatform";
import { Wrapper } from "../../../identifyAndFinance/stylesNew";
import BackButton from "../../../../commonComponents/BackButton";
import { tenantActions } from "../../openFlowRedux/tenant.actions";
import BackGroundGenerator from "../../../LandingPages/common/BackGroundGenerator";
import { Feather } from '@expo/vector-icons';
import { documentPopup } from "../../../../redux/actions/statusPopup";
import { navigateAction, replaceAction } from "../../../../helpers/navigationHelpers";
import AddNewDocument from "../../../addNewDocument";
import { getImage } from "../../../../../openbrix-design-system/utils/getScreenType";
import ODSButton from "../../../../../openbrix-design-system/ui/atoms/ODSButton";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../../../openbrix-design-system/ui/atoms/ODSText";
import ODSTabs from "../../../../../openbrix-design-system/ui/atoms/ODSTabs";
import DocNShare from "../../../../../openbrix-design-system/ui/molecules/DocNShare";
import CustomPopUp from "../../../../commonComponents/customPopUp/customPopUp.web";
import ArchiveDocs from "../../../../../openbrix-design-system/ui/molecules/DocNShare/ArchiveDocs";
const 
ArchiveDocuments = (props) => {

  const dispatch = useDispatch();
  const layout = getPlatformType();
  const [flowId, setFlowId] = React.useState(null);
  const [teanants, setUser] = React.useState(null);
  const [myDocuments, setMyDocuments] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const globalState = useSelector((state) => state?.tenant);
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  const docs = useSelector((state) => state?.authReducer?.docs);
  const archive_doc = useSelector((state) => state?.authReducer?.archiveDocs);
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const [myDocOpen,setMyDocOpen]=useState(true);
  const [propOpen,setPropOpen]=useState(false);
  const [docToRemove, setRemoveDoc] = useState("");
  const [documentArray, setDocuments] = useState([]);
  const [popUpIsVisible, setShowPopup] = useState(false);
  const [archiveShowPopup, setArchiveShowPopup] = useState(false);
  const [docToArchive, setArchiveDoc] = useState("");

  const deleteDocument = (data) => {
    dispatch(deleteDoc(data?.document_id));
    setRemoveDoc(null);
  };
  useEffect(() => {
    if (archive_doc) {
      if (archive_doc?.status) {
        setMyDocuments(archive_doc?.data);
        setPropertyDocuments(archive_doc?.data);
      }
    }
  }, [archive_doc]);


  useEffect(() => {
    dispatch(getArchiveDocuments());
  }, []);
  const updateArchiveDocument=(data)=>{
    dispatch(unArchiveDocument(data?.document_id));
    setArchiveDoc(null);
  }

  const [showModal, setShowModal] = React.useState(false);
  const {colors}=useTheme();

  return (
    <BackGroundGenerator isTLYF props={props}>
     <ImageBackground
        source={require('../../../../../openbrix-design-system/images/banner/web/document.png')}
        style={{
          width: '100%',
          height: '271px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ marginTop: 20, marginLeft: 20 }}
          type="secondary"
          disabled={false}
          themeColor={colors.main.purple}
          onPress={() => replaceAction(props?.navigation, 'Document Share Center')}
        >
          My Docs
        </ODSButton>
      </ImageBackground>
    <ArchiveDocs
    DocumentData={myDocuments}
    onDelete={(item)=>{
      setRemoveDoc(item);
      setShowPopup(true);
     }}
     onUnArchive={(e)=>{
      setArchiveDoc(e);
      setArchiveShowPopup(true);
     }}
    
    />
           
     
       <AddNewDocument
        showModal={showModal}
        setShowModal={setShowModal}
        navigation={props.navigation}
        />
      <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={"Sure"}
        secondaryButtonText={"cancel"}
        primaryText={"Remove Doc?"}
        secondaryText={" Are you sure you want to remove Doc?"}
        onPrimaryButtonPress={() => {
          setShowPopup(false);
          // dispatch(documentPopup(false))
          deleteDocument(docToRemove);
        }}
        onSecondaryButtonPress={() => {
          setShowPopup(false);
          // dispatch(documentPopup(false))
        }}
      />
      <CustomPopUp
        loading={archiveShowPopup}
        primaryButtonText={"Sure"}
        secondaryButtonText={"cancel"}
        primaryText={"Unarchive Doc?"}
        secondaryText={" Are you sure you want to unarchive the document?"}
        onPrimaryButtonPress={() => {
          setArchiveShowPopup(false);
           updateArchiveDocument(docToArchive);
        }}
        onSecondaryButtonPress={() => {
          setArchiveShowPopup(false);
          // dispatch(documentPopup(false))
        }}
      />
    </BackGroundGenerator>
  );
};

export default ArchiveDocuments;
