import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import { Entypo } from "@expo/vector-icons";
import { navigateAction, popAction } from "../../helpers/navigationHelpers";

import ImageResizeMode from "react-native/Libraries/Image/ImageResizeMode";
import WideButton from "../../commonComponents/wideButton";
import getPlatformType from "../../helpers/getPlatform";
import {
  InnerContainer,
  ServiceCard,
  ServiceDetailCard,
  BackBox,
} from "./myAccountStyles";

const OpenMarket = (props) => {
  const {
    data,
    isExpand,
    openMarketData,
    openMarketDetailData,
    navigation,
  } = props;
  const [showDetails, setShowDetails] = useState(false);
  const showPropertyDetails = (data) => {
    if (data.external && data.external_url.length) {
      let url = data.external_url;
      navigateAction(navigation, url);
    } else {
      setShowDetails(true);
    }
  };
  return (
    <InnerContainer>
      {showDetails && (
        <BackBox style={{ width: "100%" }}>
          <TouchableOpacity
            style={{ flexDirection: "row", alignItems: "center" }}
            onPress={() => setShowDetails(false)}
          >
            <Entypo name="chevron-left" size={18} color="#000" />
            <Text>Back</Text>
          </TouchableOpacity>
        </BackBox>
      )}
      {
        !showDetails ? (
          openMarketData.map((data,index) => {
            
            return (
              <ServiceCard 
              id={data?.id}
              key={index} onPress={() => showPropertyDetails(data)}>
                <Image source={data.image} style={{ height: 60, width: 60 }} />
                <Text style={{ marginTop: 10, fontWeight: "500" }}>
                  {data.name}
                </Text>
              </ServiceCard>
            );
          })
        ) : (
          <ServiceDetailCard>
            {logo}
            <View style={{ marginLeft: 15 }}>
              <Text style={{ fontWeight: "500" }}>Coming Soon</Text>
            </View>
          </ServiceDetailCard>
        )
        // openMarketDetailData.map((data) => {
        //     return (
        //       <ServiceDetailCard>
        //         <Image
        //           source={data.logo}
        //           resizeMode={ImageResizeMode.contain}
        //           style={{ width: 45, height: 45 }}
        //         ></Image>
        //         <View style={{ marginLeft: 15 }}>
        //           <Text style={{ fontWeight: 500 }}>{data.name}</Text>
        //           <Text style={{ marginTop: 10, fontWeight: 500 }}>
        //             {data.prduct_name}
        //           </Text>
        //         </View>
        //       </ServiceDetailCard>
        //     );
        //   })
      }
      {/* {!showDetails && (
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <WideButton
            onPress={() => 
            borderRadius={15}
            childText="Show more services"
            whiteButton={true}
            styles={{ marginTop: "2%" }}
            width={300}
          />
        </View> 
      )}*/}
    </InnerContainer>
  );
};

export default OpenMarket;
