import { BackendUrl,RECAPTCHA_KEY } from "@env"

const Constant = {
    TLYFE_URL:'https://vimeo.com/747992259',
    IMAGE_URL:  "https://assets.openbrix.co.uk/",
    API_URL: BackendUrl || "https://dev.api.openbrix.co.uk/v2.0",
    SERVER_URL: BackendUrl || "https://dev.api.openbrix.co.uk/v2.0",
    OPENFLO_URL:"https://www.youtube.com/watch?v=O18RoDaVYKA&ab_channel=OpenBrixNetwork",
    TDS_CONTACTUS:"https://www.tenancydepositscheme.com/learn-more/information-tds-lounge/contact-us/",
    RECAPTCHA_KEY: RECAPTCHA_KEY || "6LcNcZYpAAAAAJj7QikEaTjS9oKcWAy_UTSfEd1_"
};

export default Constant;
