import React, { useState } from 'react';
import {  StyleSheet,  View,  Platform } from 'react-native';

import { useTheme } from '@emotion/react';
import getScreenType from '../../../../utils/getScreenType';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import ODSTabs from '../../../atoms/ODSTabs';


const MyInvoiceCard = (props) => {
  const { colors } = useTheme();
  const [selectedIndex,setSelectedIndex]=useState(1);

  return (
    <View style={{ flex: 1, ...styles.shadowBox }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 32 }}>
        <ODSIcon icon={'TB-Book'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>My Invoices & Subscriptions</ODSText>
      </View>
      <ODSTabs
        items={['My Invoices', 'My Subscriptions']}
        selectedColor={colors.main.blue[700]}
        color={colors.main.blue[500]}
        activeTab={selectedIndex}
        onTabChange={(val) => setSelectedIndex(val)}
        restStyle={{
          minHeight: 50,
          marginBottom: 20,
        }}
      />
      {selectedIndex==0?props?.invoiceList:props?.subscriptionList}
      {/* {props.children} */}
    </View>
  );
};
const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});


export default MyInvoiceCard;
