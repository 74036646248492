import React, { useState } from "react";
import { Image, Linking, TouchableOpacity, View } from "react-native";
import Header from "./Header";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import { Body, Content, HeaderContent, HeaderImage, Picture1 } from "./styles";
import Footer from "./Footer";
import Drawer from "./Drawer";
import { useNavigation } from "@react-navigation/native";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton/index.native";
import { navigateAction } from "../../helpers/navigationHelpers";
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/cb-desktop.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/cb-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/cb-mobile.png');
const clouds = require('../../../openbrix-design-system/images/tlyfe-pages/hangingCloud.png');



const Wrapper = ({showClouds,isOpenBrixLogo, theme,headerImage,rightImage,footerImage,leftText,children,headerTitle,headerBody,type,drawerColor,imageStyle,headerBlacFont,bodyStyle,tlyfeProMenu}) => {
  const { colors } = useTheme();
  const layout = getScreenType();

  const navigation=useNavigation();
  return (
    <>
    <View style={{ flex: 1, backgroundColor: 'white'}}>
<Header isOpenBrixLogo={isOpenBrixLogo}  bgColor={theme} tlyfeProMenu={tlyfeProMenu}/>
{headerImage?<HeaderImage resizeMode="cover" style={imageStyle? imageStyle:{}} source={headerImage} isIntro={type=="intro"} type={type}>
{showClouds? layout == "phone" ? null : <>
            <Image
              style={layout === "phone" ? { height: 100, width: 73, position: "absolute", top: 0, right: 20 } : { height: 100, width: 73, position: "absolute", top: 0, right: 20 }}
              source={clouds}
            />
            <Image
              style={layout === "phone" ? { height: 52, width: 41, position: "absolute", top: 0, left: 40 } : { height: 131, width: 108, position: "absolute", top: 0, left: 40 }}
              source={clouds}
            />
          </>:null}
  {type=="intro"? <View style={{height:'100%',paddingBottom:20}}>
     <Image source={require('../../../openbrix-design-system/images/logos/tds-logo.png')} style={{width:174,height:80,marginTop:layout=="web"?100:layout=='tab'?0:100,resizeMode:'contain',marginLeft:-20}}/>
      <Image source={require('../../../openbrix-design-system/images/logos/tlyfe-rent-logo.png')} style={{width:layout=="phone"?'90%':260,height:195,resizeMode:'contain',alignSelf:layout=='phone'?'flex-start':'flex-end',marginRight:layout=="web"?'15%':layout=="tab"?'10%':0,marginTop:layout=='phone'?120:0}}/>
      </View>:<HeaderContent  style={bodyStyle?bodyStyle:{}}>
    <ODSText type={headerBlacFont?"h4":"h2"} color={headerBlacFont?"black":"#fff"} >{headerTitle}</ODSText>
    <ODSText marginTop={16} type={headerBlacFont?"md":'h4-light'} color={headerBlacFont?"black":"#fff"}>{headerBody}</ODSText>
  </HeaderContent>}
</HeaderImage>:null}
{type!="login" && <Body>
  <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', width: layout == "web" ? 1056 : '100%', alignSelf: 'center', gap: 24, alignItems: 'center' }}>
    <View style={{ flex: 1, paddingHorizontal: layout == "phone" ? 24 : 0, marginTop: layout == 'phone' ? 24 : 0 }}>
      <ODSText type='h4-light' marginBottom={layout == "phone" ? 20 : 40} color={colors.text.secondary} >{leftText}</ODSText>
     <View>
     <ODSButton onPress={()=>navigateAction(navigation, "Sign in")} restStyle={{width:270,marginBottom:32,marginTop:12,alignSelf:layout=="phone"?'center':'flex-start'}}>Web login / sign up</ODSButton>
     <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', gap: 16, alignItems: 'center' }}>
      <TouchableOpacity onPress={() => window.open('https://apps.apple.com/in/app/tlyfe/id6449941281')}> 
        <Image source={require('../../../openbrix-design-system/images/appstore.png')} style={{ width: 180, height: 52 }} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=com.tlyfe&hl=en_IN&gl=US')}>
        <Image source={require('../../../openbrix-design-system/images/playstore.png')} style={{ width: 180, height: 52 }} />
        </TouchableOpacity>
      </View>
     </View>

    </View>
    <Picture1 type={type} source={rightImage} />
  </View>
</Body>}
{children}

{type!="login" && <View style={{zIndex:-1000,width:'100%',height:layout=="phone"?228:240,backgroundColor:colors.other.tiles,justifyContent:'center',alignItems:'center',gap:24,padding:24}}>
  <ODSText type="h2-light" color={colors.text.primary} >Agents</ODSText>
  <ODSText type='md' color={colors.text.primary} textAlign='center'>To learn more about tlyfe and how it can support your business:</ODSText>
  {/* <ODSText type='md-bold' color={colors.text.secondary} >Contact us at <TouchableOpacity onPress={()=>{
    Linking.openURL(`mailto:info@tlyfe.co.uk?subject=Hello`);
  }}><ODSText type='md-bold' color={colors.text.secondary} textDecorationLine={'underline'}>info@tlyfe.co.uk
    </ODSText></TouchableOpacity>  </ODSText> */}
    <ODSButton onPress={()=>window.open('https://www.openbrix.co.uk/','_blank')} themeColor={colors.main.pink} lIcon={'TB-Arrow-Right'}>Go to Openbrix.co.uk</ODSButton>
</View>}
{type!=="login" && <Image source={footerImage} style={{width:'100%',height:layout=="phone"?500:layout=="web"?396:253}}/>}
<Footer bgColor={theme} />

</View>
    </>
   
  )
}
export default Wrapper;