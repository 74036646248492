import React from "react";
import { View } from "react-native";
import { Svg, Circle, Text as SVGText } from "react-native-svg";
import getScreenType from "../../../utils/getScreenType";
import { useTheme } from "@emotion/react";
const layoutType = getScreenType();
const ODSKnob = (props) => {
  const { size, strokeWidth, text, color, restStyle = {} } = props;
  const pointerRadius = props.pointerRadius ? props.pointerRadius : 5
  const radius = (size - strokeWidth) / 2 - pointerRadius;
  const circum = radius * 2 * Math.PI;
  const svgProgress = 100 - props.progressPercent;
  const theme = useTheme();

  return (
    <View style={[{ margin: 10, borderWidth: 0, backgroundColor: color, borderRadius: 100, }, restStyle]}>
      <Svg width={size} height={size}>
        <Circle
          stroke={props.bgColor ? props.bgColor : theme.colors.elements.invert}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          {...{ strokeWidth }}
        />
        <Circle
          stroke={props.pgColor ? props.pgColor : theme.colors.elements['color-light']}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap="round"
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
        <SVGText
          id="percentage"
          fontSize={layoutType == "phone" ? 14 : 14}
          x={size / 2}
          y={size / 1.8}
          textAnchor="middle"
          fill={props.textColor ? props.textColor : theme.colors.elements.invert}
          fontWeight={"bold"}
          fontFamily="Helvetica"
        >
          {props.progressPercent + "%"}
        </SVGText>


      </Svg>
    </View>
  );
};

export default ODSKnob;