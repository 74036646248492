/**Global Api call url */
import {store} from "../../src/redux/store/setup";
import {showToasterPopUp} from "../redux/actions/toast";
import idx from "idx";

export const SERVER_URL = "https://dev.api.openbrix.co.uk/v2.0"; // to be used primarily
export const STRIPEKEY = "pk_test_51Mbil9F5AMqQBw5sWIoccC64hA6jssORr4fUpkfB9R7QXEWoytm6Bp6YRVPZWowcl4o9mQkbhVwCmC4hy9u5eqCK00GfyDlOFa";
//  export const FACEBOOKAPPID = "872918700445098" //For facebook id for testing on local;
export const FACEBOOKAPPID = "2318620304874470";
export const GOOGELELOGIKEY = "591979047236-e59odqjt8qmn0vbf1v3fhndl17p4v93j.apps.googleusercontent.com";

export const BASE_URL = process.env.BASE_URL || "https://dev.api.openbrix.co.uk/";
// export const SERVER_URL = "https://tmp.api.openbrix.co.uk/v2.0";
export const YOTI_URL = process.env.yotiUrl || "https://dev.idverification.openbrix.co.uk/?uid=";
export const RTR_URL = "https://right-to-rent.service.gov.uk/prove/id-question";

export const YOTI_SIGN_URL = process.env.yoti_digi_sign_page || "https://www.yotisign.com/sign/";

export const MEDIA_ASSETS_BASE_URL = "https://dev.api.openbrix.co.uk/";
export const PROPERTY_DATA_URL = SERVER_URL + "/council-tax/";

export const INCOME_PROTECTION_ELEOS= 'https://tlyfe.app.stg.eleos.co.uk/insurance/income-protection';

export const CALENDLY_HOME_TENANT_INSURANCE ='https://calendly.com/home-runners/tenants-insurance'

export const URL = {
    //
    // ─── POST REQUESTS ──────────────────────────────────────────────────────────────
    //

    //
    // ─── PUT REQUESTS ───────────────────────────────────────────────────────────────
    //

    //
    // ─── GET REQUESTS ───────────────────────────────────────────────────────────────
    //
    getListFromReqRes: "/api/users?page=2", //dummy URL
    getsource: "/register_reference_list", //dummy URL
    getInvoices: "/tenant_invoices",
    getSubscription:'/subscriptions/get',
    getCompanyList: "/company/list", // Just an example of how the endpoint will look like
    loginURL: "/login",
    userTracking:"/app-user-tracking/navigation",
    userTrackingUserLanding:"/app-user-tracking/user-landing",
    appUserTrackingProductHover:"/app-user-tracking/product-hover",
    fbloginURL: "/authenticate/facebook",
    gloginURL: "/authenticate/oauth2_google",
    signupURL: "/register",
    searchURL: "/newSearch?limit=10",
    searchURLMAP: "/newSearch/map",
    propertyNearMeUrl: "/propertyNearMe",
    profile: "/profile",
    rental_profile: "/rental_profiles",
    rental_profile_add: "/rental_profile",
    rental_profile_add_tds: "/rental_profile/tds",
    updateProfile: "/profile",
    resetPasswordURL: "/reset-password",
    changePasswordURL: "/change-password",
    getProperty: "/property/",
    getPropertyCard: "/propertyCard/",
    saveProperty: "/save_unsave_property/",
    createAlert: "/alert/",
    uploaddoc: "/document/upload",
    getDoc: "/document/upload",
    archiveDoc: "/document/archived",
    updatesharestatus: "/document/upload",
    deleteDoc: "/document",
    archive_doc:'/document/archive',
    un_archive_doc:'/document/unarchive',
    getSavedProperties: "/saved_properties",
    logErrorURL: "/ledgeractions/maintenance",
    getLadgerURL: "/ledgeractions/1/10/all/0",
    getLadgerURLHistrory: "/creditLadder/credithistory/download",
    getCreditLink: "/provision_and_get_link",
    startCreditLadderPayment: '/creditLadder/subscription/start',
    updatePaymentMethod: '/subscriptions/stripe/renew',
    cancelCreditLadderSubscription: '/creditLadder/subscription/cancel',
    cancelVoucherSubscription:'/vaboo/cancel',
    getPayStatus: '/check_subs_status',
    getCLHistory: "/customSuppliers/creditLadder/getcredithistory",
    contactusURL: "/contactFormTenant",
    getArticles: "/media-hub/articles",
    getVideos: "/media-hub/tenant-hub",
    removeTenant: "/users/tenant/{opridId}",
    getSchool: "/nearBySchools/",
    getTransport: "/transport?",
    getSources:'/media-hub-source',
    getCategories:'/media-hub-category',
    noRtrRequired:'/openflow/no-rtr-required',
    referFriend:"/referToFriend",
    updateNotiSettings: "/notification-setting",

    affordibility: "/affordibility-check",
    guarantor_affordibility: "/guarantor-affordibility-check",
    ccqStatus: "/referencing-status",
    ccqURL: "/get-ccj-url",
    setccjPending: "/set-pending-ccj",
    skipCcj: "/skip-ccj",
    getMaintanenceDetails: "/maintenance_card?id=",
    removeAffordibility: "/affordibility-check",
    removeCcj: "/ccj",
    toggleLedgerhistory: "/toggleLedgerhistory",
    findPostal: "/aw/find_oprid?postCode=",
    logout:"/logout",
    homeIframe:'/home-iframe-params',

    //
    // ─── DELETE REQUESTS ────────────────────────────────────────────────────────────
    //
    deleteAccount:'/user/account/delete'

    //
    // ─── PATCH REQUEST ──────────────────────────────────────────────────────────────
    //
};

const catchErrorHandle = (e, error) => {
    // console.log("error : ", e, error);
    e.catch = true;
    if (e instanceof TypeError) {
        e.message = "No internet connection available!";
        e.type = "network";
    } else if (e instanceof SyntaxError) {
        e.message = "Internal server error!";
        e.type = "server";
    } else {
        e.message = "Something went wrong. Please try after some time!";
        e.type = "undefined";
    }
    return error(e);
};

const responseHandle = async (res, success, error, navigation) => {
    console.log("reseeeee", res);
    try {
        if (res.ok) {
            success(await res.json());
        } else if (res.status === 403) {
            console.log("in");
            // RootNavigation.navigate("Sign in");
            // store.dispatch(LogoutApi());
            // store.dispatch(
            //   showToaster("error", "Not signed in.Please sign in to continue.")
            // );
            store.dispatch(showToasterPopUp());
            error(await {message: "Not signed in.Please sign in to continue."});
        } else {
            error(await res.json());
        }
    } catch {

    }

};

const callAPI = (url, init, success, error, navigation, patch) => {
    // console.log("URRLL : ====>>>", SERVER_URL + url);
    if (patch && init && init.headers) {
        delete init.headers["Content-Type"];
    }
    // console.log("init", init);
    return fetch(url, init)
        .then((res) => {
            // console.log("res", res, init, url);

            // if (log) console.log("success : ", url, init, res);
            responseHandle(res, success, error, navigation);
        })
        .catch((e) => {
            // if (log) console.log("error : ", SERVER_URL + url, init, e);
            catchErrorHandle(e, error);
            // alert("Url : " + SERVER_URL + url + "error : " + e);
        });
};

// simple put request
export const _put = (
    url,
    headers = {},
    body = "",
    success,
    error,
    navigation,
) => {
    let token = idx(store, (_) => _.getState().appReducer.loginData.data.token);
    // console.log("Token", store);

    const init = {
        method: "PUT",
        body: body,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            ...headers,
        },
    };
    return callAPI(url, init, success, error, navigation);
};

// Patch
export const _patch = (
    url,
    headers = {},
    body = "",
    success,
    error,
    navigation,
) => {
    const init = {
        method: "PATCH",
        body: body,
        headers: {...headers},
    };
    return callAPI(url, init, success, error, navigation, true);
};

// simple Delete request
export const _delete = (
    url,
    headers = {},
    body,
    success,
    error,
    navigation,
) => {
    let token = idx(store, (_) => _.getState().appReducer.loginData.data.token);
    // console.log("Token", store);
    const init = {
        method: "DELETE",
        body: body,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            ...headers,
        },
    };
    return callAPI(url, init, success, error, navigation);
};

// simple GET request
export const _get = (
    url,
    headers = {},
    params = {},
    success,
    error,
    navigation,
) => {
    let token = idx(store, (_) => _.getState().appReducer.loginData.data.token);
    // console.log("Token", store);

    // console.log(token);
    const init = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            ...headers,
        },
    };

    // Manage params of url
    let url_tail = "";
    for (let key in params) url_tail = `${url_tail}${key}=${params[key]}&`;
    // console.log("url + url_tail-- >", url + url_tail);
    return callAPI(url + url_tail, init, success, error, navigation);
};

// Complex post
export const _post = (
    url,
    headers = {},
    body = "",
    success,
    error,
    navigation,
) => {
    let token = idx(store, (_) => _.getState().appReducer.loginData.data.token);
    // console.log("Token", token);

    const init = {
        method: "POST",
        body: body,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            ...headers,
        },
    };
    return callAPI(url, init, success, error, navigation);
};

export const futch = (url, opts = {}, onProgress) => {
    // console.log(url, opts);
    return new Promise((res, rej) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
        xhr.onload = (e) => res(e.target);
        xhr.onerror = rej;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
    });
};
