import React from 'react';
import { Linking, Platform, Text, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import variables from '../variables.json';
import { CardBox } from '../card/index.js';
import { Entypo,  MaterialCommunityIcons, MaterialIcons ,Ionicons} from '@expo/vector-icons';
import getPlatformType from '../../../../helpers/getPlatform';
import { AntDesign } from '@expo/vector-icons';

import { useDispatch } from 'react-redux';
import { tenantActions } from './../../openFlowRedux/tenant.actions';
import { COLORS, OPEN_FLO_THEME } from '../../../../constants/colorPallete';
import { LineSaperator, Typography } from '../../../tds/style';

export const ServiceCard = (props) => {
  const dispatch = useDispatch();
  const {
    isListView,
    date,
    title,
    marginTop,
    listIcon,
    showButton,
    type,
    description,
    showArrowIcon,
    isSeen,
    flowId,
    isTds,
    isDeposit,
    isInventory,
    isdelete,
    deleteApi,
    mobileOnly = false,
    isHideActions,
    isHideAST,
  } = props;
  const layoutType = mobileOnly ? 'phone' : getPlatformType();
  const Container = styled.View`
    flex-direction: ${layoutType == 'phone' ? 'row' : 'row'};
  `;

  const openUrl = () => {
    
    if (props?.url) {
      if (window?.ReactNativeWebView)  {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            val: {
              type: 'doctoopen',
              Url: props.url,
            },

          })
        );
      }
      else{
        Linking.canOpenURL(props?.url).then((supported) => {
          if (supported) {
            if (props?.url) {
  
              let ext = props?.url.split('.').reverse()[0];
              if (ext === 'pdf') {
                window.open(
                  // 'https://docs.google.com/viewerng/viewer?url='+ 
                  props?.url, '_blank');
              } else {
                window.open(props?.url, '_blank');
              }
            }
          } else {
            
          }
        });
      }
    
    }
  };
  const toggleSeen = () => {
    let data = {
      certificate_url: props?.url,
      is_seen: !props.isSeen,
    };
    if (isTds) {
      data = {
        tds: true,
        flow_id: flowId,
      };
    }
    if (isDeposit) {
      data = {
        deposit: true,
        flow_id: flowId,
        certificate_url: props?.url,
      };
    }
    if (isInventory) {
      data = {
        isInventory: true,
        flow_id: flowId,
      };
    }
    if (!isSeen) {
      dispatch(
        tenantActions.toggleSeen(data, null, (data) => {
          if (data && props?.refreshApi) {
            props.refreshApi();
          }
        })
      );
    }
  };
  const deleteDoc = () => {
    let data = {
      certificate_url: props?.url,
      flow_id: flowId,
    };
    if (deleteApi) {
      deleteApi();
      props?.refreshApi();
    } else {
      dispatch(
        tenantActions.deleteDoc(data, flowId, (data) => {
          if (data && props?.refreshApi) {
            props.refreshApi();
          }
        })
      );
    }
  };
  if (isListView) {
    return (
      <React.Fragment>
        <TouchableOpacity
         onPress={() => {

          openUrl();
        }}
        style={{ flexDirection: 'row', height: 70 }}>
          <View
            style={{
              flex: 0.2,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <MaterialCommunityIcons name="cloud-check" size={30}color={COLORS.GREEN}/>
    
            <Typography align={'center'} color={'#686868'} top={'3px'} size={'13px'} bold={'400'}>
              Uploaded
            </Typography>
          </View>
          <View style={{ flex: 0.6, justifyContent: 'center' ,marginLeft:20}}>
            <Typography underline={true} align={'left'} color={COLORS.LGREEN} size={'16px'} bold={'700'}>
              {title}
            </Typography>
            <Typography top={'3px'} align={'left'} color={COLORS.BLACK} size={'14px'} bold={'400'}>
              {date}
            </Typography>
          </View>
          <TouchableOpacity
            onPress={deleteDoc}
            style={{
              flex: 0.2,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <AntDesign name="delete" size={24} color={COLORS.SAFFRON} />
            <Typography align={'center'} color={'#686868'} top={'3px'} size={'12px'} bold={'400'}>
              Remove
            </Typography>
          </TouchableOpacity>
        </TouchableOpacity>
        <LineSaperator />
      </React.Fragment>
    );
  } else
    return (
      <CardBox
        style={{
          marginTop: props.marginTop ? props.marginTop : 0,
          marginBottom: props.marginBottom ? props.marginBottom : 0,
          width: props.width ? props.width : '100%',
          padding: 5,
        }}
        hasShadow={true}
        hasBorderRadius={true}
      >
        <Container>
          <View
            style={{
              flexDirection: layoutType == 'phone' ? 'column' : 'row',
              width: layoutType == 'phone' ? "48%": '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                width: layoutType == 'phone' ? '100%' : '50%',
                padding: 10,
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <Text id="documenTitle">{props.title}</Text>
            </View>
            {isHideActions ? null : layoutType != 'phone' ? (
              <View
                style={{
                  width: 1,
                  height: '70%',
                  backgroundColor: '#d8d8d8',
                }}
              />
            ) : null}

            {isHideActions ? null : (
              <View
                style={{
                  width: layoutType == 'phone' ? '100%' : '50%',
                  padding: 10,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MaterialIcons name="cloud-done" size={30} color={variables.green} />
                <Text
                  id="savedToDocumentStoarage"
                  style={{
                    textAlign: 'center',
                    marginLeft: 6,
                  }}
                >
                  {props.text ? props.text : 'Saved to Document storage'}
                </Text>
              </View>
            )}
            {isHideActions ? null : layoutType != 'phone' ? (
              <View
                style={{
                  width: 1,
                  height: '70%',
                  backgroundColor: '#d8d8d8',
                }}
              />
            ) : null}
          </View>
          {layoutType == 'phone' ? (
            <View
              style={{
                width: '100%',
                height: 1,
                backgroundColor: '#d8d8d8',
                position: 'absolute',
                top: 50,
              }}
            />
          ) : null}
          <View
            style={{
              flexDirection: layoutType == 'phone' ? 'column-reverse' : 'row',
              width: layoutType == 'phone' ? "52%": '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TouchableOpacity
              style={{
                width: layoutType == 'phone' ? '100%' : '50%',
                padding: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isHideActions ? null : isdelete ? (
                <Entypo onPress={deleteDoc} name="cross" size={22} color="#e71e82" />
              ) : isHideAST ? (
                <></>
              ) : (
                <MaterialCommunityIcons
                  id="fileIcon"
                  name="file-document-box-check-outline"
                  size={35}
                  color={isSeen ? OPEN_FLO_THEME.seen : OPEN_FLO_THEME.notSeen}
                  onPress={toggleSeen}
                />
              )}
            </TouchableOpacity>
            {layoutType != 'phone' ? (
              isHideActions ? null : (
                <View
                  style={{
                    width: 1,
                    height: '70%',
                    backgroundColor: '#d8d8d8',
                  }}
                />
              )
            ) : null}

            <View
              style={{
                width: layoutType == 'phone' ? '100%' : '50%',
                padding: 10,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                id="documentDate"
                onPress={() => {
                  toggleSeen();
                  openUrl();
                }}
                style={{
                  textAlign: 'center',
                  color: variables.primary,
                  marginRight: 20,
                }}
              >
                {props.date}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  toggleSeen();
                  openUrl();
                }}
                style={{}}
              >
                <Ionicons name="ios-arrow-forward" size={20} color={variables.primary} />
              </TouchableOpacity>
            </View>
          </View>
        </Container>
      </CardBox>
    );
};

{
  /* <ServiceCard title="Gas Cert" width="100%" onDatePress={()=>alert("Date Pressed")} date="22/07/2020" 
fileUrl=""
/> */
}
