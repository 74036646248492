import React, { useState } from 'react';
import {
  Image,
  Linking,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import { ODSIcon } from '../../atoms/ODSIcon';
import { useTheme } from '@emotion/react';
import ODSButton from '../../atoms/ODSButton';


const HousingGatewayCard = ({
  hasBorder = false
}) => {
  const { colors } = useTheme();


  return (
    <View style={hasBorder ? [{ ...styles(colors).containerStyle, ...styles(colors).hasBorder }] : styles(colors).containerStyle}>
      <ODSText type='md-bold' color={'#001B9C'}>My Housing Issue Gateway</ODSText>
      {!hasBorder && <Image source={require('../../../images/logos/tds-charity.png')} style={{ width: 102, height: 38, marginTop: 20 }} />}
      <ODSText type='h6' marginVertical={20} textAlign='center' color={colors.text.primary}>Need help resolving a tenancy related issue?</ODSText>
      <ODSText type='md' marginBottom={20} textAlign='center' color={colors.text.primary}>This free-to-use platform was developed by the TDS Charitable Foundation to help tenants navigate the complex solutions and organisations in place for resolving rental issues.</ODSText>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        {hasBorder && <Image source={require('../../../images/logos/tds-charity.png')} style={{ width: 86, height: 32, marginRight: 50 }} />}
        <ODSButton
          type="primary"
          disabled={false}
          themeColor={colors.main.pink}
          onPress={() => { Linking.openURL('https://www.myhousinggateway.org.uk/?utm_source=tlyfe&utm_medium=app&utm_campaign=tlyfe+-+referral+-+my+housing+gateway+-+website+link') }}>
          Access here
        </ODSButton>
      </View>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    containerStyle: {
      flex: 1, paddingVertical: 32, paddingHorizontal: 24, alignItems: 'center', justifyContent: 'center', backgroundColor: 'white'
    },
    hasBorder: {

      borderWidth: 1, borderRadius: 12,
      borderColor: '#001B9C'

    }
  });

export default HousingGatewayCard;
