import React from 'react';
import { View, FlatList, Linking } from 'react-native';
import { useTheme } from '@emotion/react';
import ODSHomeBoxTile from '../../atoms/ODSTiles/HomeboxTile';
import getScreenType from '../../../utils/getScreenType';
import ODSTiles from '../../atoms/ODSTiles';
import ODSInsuranceTile from '../../atoms/ODSTiles/InsuranceTile';
import ODSRentReadyTile from '../../atoms/ODSTiles/RentReadyTile';
import HousingGatewayCard from '../HousingGatewayCard/index.web';

const ODSDashboardTiles = ({
  tiles,
  navigationRoutes,
  navigation,
  rentReadyData,
  renderTenancyTile,
  isFccUser,
  isLoginTypeTds,
  iFrameParams
}) => {
  const { colors } = useTheme();
  const layout = getScreenType();

  const getItem = (id) => {
    const item = navigationRoutes.find((item) => item.id === id);
    return item;
  };

  const renderODSTiles = ({ item }) => {
    let componentToRender;

    switch (item.card) {
      case 'BillingBetter':
        componentToRender =
          null;
        break;
      case 'OpenFlow':
        break;
      case 'Insurance':
        componentToRender =
          null;
        break;
      case 'MoneyCenter':
        null;
        break;
      case 'MyDocuments':
        componentToRender = (
          <ODSTiles
            source={require('../../../images/dashboard/tiles/md.png')}
            title={'My Documents & Share Centre'}
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            subtitle="View and share your documents with agents and landlords in a secure way."
            btnTheme={colors.main.purple}
            onPress={() => {
              navigation.navigate(getItem('MyDocuments').route);
            }}
          />
        );
        break;
      case 'CreditBuilder':
        componentToRender = (
          <ODSTiles
            isRight
            source={require('../../../images/dashboard/tiles/cb.png')}
            title={'Credit Builder'}
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            subtitle="Report your rent payments to help boost your credit score."
            btnTheme={colors.main.green}
            onPress={() => {
              navigation.navigate(getItem('CreditBuilder').route);
            }}
          />
        );
        break;
      case 'TenancyDeposit':
        componentToRender = (
          <ODSTiles
            source={require('../../../images/dashboard/tiles/td.png')}
            title={'Tenancy Deposit'}
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            subtitle="View and Manage your tenancy deposit with the TDS."
            btnTheme={colors.main.pink}
            onPress={() => {
              navigation.navigate(getItem('TenancyDeposit').route);
            }}
          />
        );
        break;
      case 'PreQualification':
        componentToRender = (
          <View
            style={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}>
            <ODSRentReadyTile
              onPress={() => navigation.navigate(getItem('PreQualification').route)}
              rentReadyData={rentReadyData}
            />
          </View>
        );
        break;
      case 'IDVerification':
        componentToRender = (
          <ODSTiles
            isRight
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            source={require('../../../images/dashboard/tiles/id.png')}
            title={'ID Verification'}
            subtitle="Unlock a world of convenience with tlyfe’s verified digital ID, accepted at over 30,000 outlets across the UK."
            status={getItem('IDVerification').status}
            btnTheme={colors.main.purple}
            onPress={() => {
              navigation.navigate(getItem('IDVerification').route);
            }}
          />
        );
        break;
      case 'ReferenceProgression':
        componentToRender = (
          <ODSTiles
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            source={require('../../../images/dashboard/tiles/fcc.png')}
            title={'Reference Progression'}
            subtitle="The latest news & videos for tenants in the private rental sector."
            btnTheme={colors.main.green}
            onPress={() => {
              navigation.navigate('FccHome');
            }}
            topImage={require('../../../images/logos/fcc-logo.png')}
          />
        );
        break;
      case 'Rewards':
        componentToRender = (
          <ODSTiles
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            source={require('../../../images/dashboard/tiles/tr.png')}
            title={'tlyfe Rewards'}
            subtitle="Access hundreds of savings with National Retailers, including Waitrose, M&S, Ikea, Sainsburys, Pizza Express and many, many more!"
            btnTheme={colors.main.pink}
            onPress={() => {
              navigation.navigate(getItem('Rewards').route);
            }}
          />
        );
        break;
      case 'InfoHub':
        componentToRender = (
          <ODSTiles
            restStyle={{ marginVertical: 10 }}
            isRight
            source={require('../../../images/dashboard/tiles/ih.png')}
            title={'Info Hub'}
            subtitle="The latest news & videos for tenants in the private rental sector."
            btnTheme={colors.main.purple}
            onPress={() => {
            }}
          />
        );
        null;
        break;
      case 'TlfyeProtect':
        componentToRender = (
          <ODSTiles
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            isRight={true}
            source={require('../../../images/dashboard/tiles/tp.png')}
            title={'tlyfe Protect'}
            subtitle="Protect your tenancy with tlyfe protect"
            btnTheme={colors.main.purple}
            onPress={() => {
              navigation.navigate(getItem('TlyfeProtect').route);
            }}
          />
        );
        break;

      default:
        componentToRender = null;
        break;
    }
    return componentToRender;
  };
  const combinedData = [
    { card: 'ReferenceProgression' },
    ...tiles,
    { card: 'TlfyeProtect' }


  ];
  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      {tiles.length > 0 && tiles[0]['card'] != 'OpenFlow' ? (
        <View
          style={{
            flexDirection: layout == 'phone' ? 'column' : isLoginTypeTds ? "row-reverse" : 'row',
            gap: layout != 'phone' && 15,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {isLoginTypeTds ? <View style={{ paddingHorizontal: 10 }}>
            <ODSTiles
              restStyle={{ marginVertical: 16, backgroundColor: "#1C3F95", borderRadius: 12 }}
              source={require('../../../images/dashboard/tiles/Insu.png')}
              logosource={require('../../../images/dashboard/tiles/SHIlogo.png')}
              title={'Specialist Tenant Insurance'}
              subtitle2="covering possessions as well as your liabilities, including bangs or breakages."
              btnTheme={colors.main.gold}
              textColor={"white"}
              btnTitle={"Get a Quote"}
              isRight
              resize={"contain"}
              subtitleType={"button"}
              btnCOlor={"black"}
              onPress={() => {
                Linking.openURL("https://www.safehouse-getquote.co.uk/TCI", "_blank")
              }}
            />

          </View> : null}
          <ODSHomeBoxTile iFrameParams={iFrameParams} navigation={navigation} fullWidth={isFccUser ? false : true} />
        </View>
      ) : (
        <View
          style={{
            flexDirection: layout == 'phone' ? 'column' : 'row',
            gap: layout != 'phone' && 15,
            justifyContent: 'center',
          }}>
          <ODSHomeBoxTile iFrameParams={iFrameParams} navigation={navigation} />
          {layout == 'phone' && <View style={{ height: 15 }} />}
          {renderTenancyTile && renderTenancyTile}
        </View>
      )}
      <View style={{ height: 30 }} />
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.other['background-green'],
          width: '100%',
        }}>
        <FlatList
          data={combinedData}
          renderItem={renderODSTiles}
          keyExtractor={(item, index) => index}
          contentContainerStyle={{
            maxHeight: layout == 'web' ? 1000 : layout == 'tab' ? 1300 : 'auto',
            flexWrap: layout != 'phone' ? 'wrap' : 'nowrap',
            paddingVertical: layout == 'phone' ? 32 : 32,
            paddingBottom: 60,
            paddingHorizontal: layout == 'phone' ? 12 : layout == 'tab' ? 0 : 0,
          }}
        />
      </View>
      <View style={{ width: layout == "phone" ? '90%' : '45%', marginTop: 24, alignSelf: 'center' }}>
        <HousingGatewayCard hasBorder />
      </View>
      <ODSInsuranceTile onPress={() => navigation.navigate('Marketplace')} />
    </View>
  );
};

export default ODSDashboardTiles;
