import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import getPlatformType from './../../helpers/getPlatform';
import { useTheme } from '@emotion/react';
import { FlatList, View } from 'react-native';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { getSubscriptionList } from '../../redux/actions/app';
import moment from 'moment';
import CustomPopUp from '../../commonComponents/customPopUp/customPopUp.web';
import { cancelRewardSubscription, cancelSubscription, startPayment, updatePayment } from '../../redux/actions/Ladger';
import { navigateAction } from '../../helpers/navigationHelpers';
import { useNavigation } from '@react-navigation/native';
import { getServerURL } from '../../helpers/functionHelpers';

const layoutType = getPlatformType();

const SubscriptionList = (props) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading,setLoad]=useState(false);
  const {colors}=useTheme();
  const navigation=useNavigation();
  const [popUpIsVisible, setpopUpIsVisible] = useState(false);
  const [cancelVBpopup, setCancelVBpopup] = useState(false);


  useEffect(() => {
    getSubscriptionListData();
  }, []);
  const getSubscriptionListData=()=>{
    setLoad(true);
    dispatch(
        getSubscriptionList((data) => {
          if(data?.status){
              setList(data?.data)
          }
          setLoad(false)
        })
      );
  }
  const cancelSubscription1 = () => {
    setpopUpIsVisible(false);
    dispatch(
      cancelSubscription(() => {
        getSubscriptionListData();
      })
    );
  };
  const cancelVBSubscription = () => {
    setCancelVBpopup(false);
    dispatch(
      cancelRewardSubscription(() => {
        getSubscriptionListData();
      })
    );
  };
  const updateSubscription=()=>{
    let getBaseURL=getServerURL();
    let redirect_url = 
    `${getBaseURL}/${layoutType === "web" ? "my-account/subscriptions" : "myaccount/invoice"}`;
  
    dispatch(
        updatePayment(redirect_url,(response) => {
            console.log("___",response)
            window.open(response?.url,'_self');
        })
      );
  }
  
  return <View>
  {((list?.cl_data || list?.vb_data) && 
  !(
    (list?.cl_stripe_data?.status === "canceled" || list?.cl_stripe_data?.status === undefined) &&
    (list?.vb_stripe_data?.status === "canceled" || list?.vb_stripe_data?.status === undefined)
  )) && (
  <View style={{ padding: 32, borderWidth: 1, borderRadius: 12, marginVertical: 12, borderColor: colors.other['border-neutral'] }}>
    <ODSText type='h6' color={colors.text.primary} marginBottom={16}>Payment Method</ODSText>
    <ODSText type='md-bold' color={colors.text.primary} marginTop={12}>Card</ODSText>
    <ODSText type='md' color={colors.text.primary} marginTop={4}>
      xxxx xxxx xxxx{' '}
      {list?.cl_stripe_data?.status === "active"
        ? list?.cl_payment_method?.card?.last4
        : list?.vb_stripe_data?.status === "active"
        ? list?.vb_payment_method?.card?.last4
        : null}
    </ODSText> 
    <ODSText type={'sm-bold'} marginTop={18} color={colors.text.primary}>
      Please note:{' '}
      <ODSText type={'sm'} color={colors.text.secondary}>
        When you update your payment method it will update all your subscription payments to the same card.
      </ODSText>
    </ODSText> 
    <ODSButton
      restStyle={{ marginTop: 16 }}
      themeColor={colors.main.blue}
      type="primary"
      disabled={false}
      onPress={updateSubscription}
    >
      Update payment method
    </ODSButton>
  </View>
)}
    <ODSText type='h6' color={colors.text.primary} marginTop={8} marginBottom={8}>Subscriptions</ODSText>
    {loading && <ODSText alignSelf='center' marginTop={20} type='md' color={colors.text.primary}>Loading.....</ODSText>}

{!loading &&!list?.cl_data && !list?.vb_data?.stripe_subscription_status && <ODSText alignSelf='center' marginTop={20} type='h6' color={colors.text.primary}>You don't have any subscription history!</ODSText>}
  
   {list?.cl_data &&
    <View style={{padding:32,borderWidth:1,borderRadius:12,marginVertical:12,borderColor:colors.other['border-neutral']}}>
    <ODSText type='h6' color={colors.text.primary} marginBottom={16}>Credit Builder</ODSText>
    {list?.cl_stripe_data?.status=="canceled"?<>
        <ODSText type='md' color={colors.text.primary}>This subscription has been <ODSText type='md-bold' color={colors.feedback.error[500]}>cancelled.</ODSText> If you wish to reinstate it please go to Credit Builder section.</ODSText>
        <ODSButton
          restStyle={{ marginTop:32 }}
          themeColor={colors.main.blue}
          type="primary"
          disabled={false}
          onPress={()=>{
            navigateAction(navigation, "Credit Builder", { refresh: true });
          }}
        >Go to Credit Builder</ODSButton>
    </>
    :
<>
    <ODSText type='md-bold' color={colors.text.primary}>Subscription renews on</ODSText>
    <ODSText type='md' color={colors.text.primary} marginTop={4}>{moment(list?.cl_data?.current_period_end).format('DD/MM/YYYY')}</ODSText>
     <ODSButton
          restStyle={{marginTop:32 }}
          themeColor={colors.main.red}
          type="secondary"
          disabled={false}
          onPress={()=>setpopUpIsVisible(true)}
        >Cancel Subscription</ODSButton>
        </>}
  </View>}

  {list?.vb_data && list?.vb_data?.stripe_subscription_status &&
    <View style={{padding:32,borderWidth:1,borderRadius:12,marginVertical:12,borderColor:colors.other['border-neutral']}}>
    <ODSText type='h6' color={colors.text.primary} marginBottom={16}>tlyfe Rewards</ODSText>
    {list?.vb_stripe_data?.status=="canceled"?<>
        <ODSText type='md' color={colors.text.primary}>This subscription has been <ODSText type='md-bold' color={colors.feedback.error[500]}>cancelled.</ODSText> If you wish to reinstate it please go to Rewards section.</ODSText>
        <ODSButton
          restStyle={{ marginTop:32 }}
          themeColor={colors.main.blue}
          type="primary"
          disabled={false}
          onPress={()=>{
            navigateAction(navigation, "Rewards", { refresh: true });
          }}
          
        >Go to tlyfe Rewards</ODSButton>
    </>
    :
<>
    <ODSText type='md-bold' color={colors.text.primary}>Subscription renews on</ODSText>
    <ODSText type='md' color={colors.text.primary} marginTop={4}>{moment(list?.vb_data?.current_period_end).format('DD/MM/YYYY')}</ODSText>
     <ODSButton
          restStyle={{marginTop:32 }}
          themeColor={colors.main.red}
          type="secondary"
          disabled={false}
          onPress={()=>setCancelVBpopup(true)}
        >Cancel Subscription</ODSButton>
        </>}
  </View>}


  <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={'Sure'}
        secondaryButtonText={'Back'}
        primaryText={'Cancel Subscriptions ?'}
        secondaryText={' Are you sure you want to Cancel Subscriptions?'}
        onPrimaryButtonPress={() => {
          cancelSubscription1();
        }}
        note={
          'that by cancelling this product your monthly rent will not be recorded on your credit file with UK largest credit reference agencies. Many banks and financial institutions use these agencies when giving you credit and loans. This can materially impact your interest on credit cards and loans. Furthermore due to open banking regulation, once cancelled we would require 45 days to reconnect to your bank.'
        }
        onSecondaryButtonPress={() => {
          setpopUpIsVisible(false);
        }}
      />
       <CustomPopUp
        loading={cancelVBpopup}
        primaryButtonText={'Sure'}
        secondaryButtonText={'Back'}
        primaryText={'Cancel Subscriptions ?'}
        secondaryText={' Are you sure you want to Cancel Subscriptions?'}
        onPrimaryButtonPress={() => {
          cancelVBSubscription();
        }}
        onSecondaryButtonPress={() => {
          setCancelVBpopup(false);
        }}
      />
      
  </View>
};
export default SubscriptionList;


