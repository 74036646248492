import React, { useState } from 'react';
import { Dimensions, StyleSheet, View, Text, TouchableOpacity, Image, Platform, ActivityIndicator, ImageBackground } from 'react-native';
import { WebView } from 'react-native-webview';
const WIN_HEIGHT = Dimensions.get('window').height;
const WIN_WIDTH = Dimensions.get('window').width;
import BackGroundGenerator from '../../../LandingPages/common/BackGroundGenerator';
import { replaceAction } from '../../../../helpers/navigationHelpers';
import ODSButton from '../../../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { ODSText } from '../../../../../openbrix-design-system/ui/atoms/ODSText';
import getPlatformType, { getImage } from '../../../../helpers/getPlatform';
import { getTokens } from '../../../../../openbrix-design-system/design-tokens/color-tokens';
import ODSTiles from '../../../../../openbrix-design-system/ui/atoms/ODSTiles';
import { CALENDLY_HOME_TENANT_INSURANCE, INCOME_PROTECTION_ELEOS } from '../../../../helpers/connectionHelpers';
import { showToaster } from '../../../../redux/actions/toast';
import { useDispatch } from 'react-redux';

const TlyfeProtect = (props) => {
  const layout=getPlatformType();
  const themeObj = getTokens('light').colors;
  const dispatch = useDispatch();



  return (
    <BackGroundGenerator isTLYF props={props}>
      <ImageBackground
        source={getImage("tp.png")}
        style={{
          width: '100%',
          height: '289px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ marginTop: 20, marginLeft: 20 }}
          type="secondary"
          disabled={false}
          onPress={() => replaceAction(props?.navigation, 'Dashboard')}
        >
          My Dashboard
        </ODSButton>
      </ImageBackground>
      <View
        style={{
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
          paddingHorizontal: 20,
          maxWidth: layout == 'web' ? 843 : '100%',
        }}
      >
        <ODSText marginLeft={6} style={{ alignSelf: 'center' }} type={'h4'}>
          tlyfe Protect
        </ODSText>
        <ODSText textAlign="center" marginTop={20} type={'sm-bold'}>
        tlyfe Protect: Peace of Mind for Renters
        </ODSText>
        <ODSText textAlign="center" marginTop={10} type={'sm'}>
{`At tlyfe, we know that renting isn’t just about securing a home—it’s about protecting what matters most. That’s why we offer tlyfe Protect, a seamless way to ensure financial stability with Income Protection and safeguard your belongings with Contents Insurance. Whether it’s securing your income in case of unforeseen circumstances or covering your valuables against unexpected damage or theft, tlyfe Protect gives you confidence and control. No hidden fees, no complicated jargon—just simple, flexible protection designed for renters like you. Because your future and your home deserve the best care. \nClick the buttons below to get protected today!`}
        </ODSText>
      <View style={{flexDirection:layout=="phone"?'column':'row',paddingVertical:40,alignSelf:'center',gap:20}}>
      <ODSTiles
        source={require('../../../../../openbrix-design-system/images/ip.png')}
        title={'Income Protection'}
        subtitle="Continue building the home you love, uninterrupted with income protection insurance. By protecting your income, you will be able to stay on top of rent and bills when you’re too ill or injured to work."
        btnTheme={themeObj.main.pink}
        onPress={() => {
          window.open(INCOME_PROTECTION_ELEOS, '_blank');
        }}
      />
       <ODSTiles
      isRight
        source={require('../../../../../openbrix-design-system/images/ci.png')}
        title={'Home Contents Insurance'}
        subtitle="Protect your belongings from life’s surprises—get contents insurance and keep your valuables safe from theft, damage, or loss."
        btnTheme={themeObj.main.purple}
        onPress={() => {
          window.open(CALENDLY_HOME_TENANT_INSURANCE, '_blank');
        }}
      />
      </View>
      </View>

      <View
        style={{
          paddingHorizontal: 20,
          maxWidth:'100%',
          backgroundColor:themeObj.other["background-green"],
          alignItems:"center"
        }}

      >
        <View
          style={{
            padding: layout == 'phone' ? '5%' : 25,
          }}
        >
           <ODSText width={layout=="web"?800: "100%"} marginTop={10} type={'sm-bold'}>
           Legal disclaimer
           </ODSText>
           <ODSText width={layout=="web"?800: "100%"}  marginTop={10} type={'xs-bold'}>Income Protection:  <ODSText type={'xs'}>
            {`This information provides general guidance on income protection and should not be taken as personalised advice. Tlyfe markets Eleos Income Protection Insurance, provided by Eleos, which is authorised and regulated by the Financial Conduct Authority (FRN: 998550). Tlyfe may be remunerated by Eleos for successful sales resulting from its marketing activities. Terms and conditions apply. Policy exclusions and limitations vary. Full policy details, including waiting periods and coverage limitations, are available during the application process before purchase. Not all illnesses or injuries are covered.`}
          </ODSText></ODSText>
        </View>
      </View>
      </BackGroundGenerator>
  );
};

const styles = StyleSheet.create({
    cookieWarning: {
        position: 'absolute',
      top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.95)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        zIndex: 1000,
      },
      cookieWarningText: {
        fontSize: 16,
        color: '#333',
        textAlign: 'center',
        marginBottom: 20,
        lineHeight: 24,
      },
      cookieWarningButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 16,
      },
      cookieButton: {
        backgroundColor: '#007AFF',
        padding: 12,
        borderRadius: 6,
        marginHorizontal: 8,
      },
      cookieButtonText: {
        color: 'white',
        fontSize: 14,
        fontWeight: '600',
      },
  container: {
    minHeight: WIN_HEIGHT - 300,
  },
  contentWrapper: {
    width: '100%',
  },
  buttonStyle: {
    marginTop: 20,
    marginLeft: 20,
    width: 200,
  },
  webContainer: {
    width: WIN_WIDTH,
    height: WIN_HEIGHT - 100,
    borderRadius: 8,
    overflow: 'hidden',
  },
  webviewContainer: {
    flex: 1,
    height: WIN_HEIGHT - 150,
    width: WIN_WIDTH,
    overflow: 'hidden',
    borderRadius: 8,
  },
  webview: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.8)',
    zIndex: 1000,
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
  },
  errorContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    fontSize: 16,
    color: 'red',
    marginBottom: 20,
  },
  retryButton: {
    padding: 10,
    backgroundColor: '#007AFF',
    borderRadius: 5,
  },
  retryText: {
    color: 'white',
    fontSize: 16,
  }
});

export default TlyfeProtect;