import moment from "moment";
import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { Image, TouchableOpacity, View } from "react-native";

import SearchBox from "./searchbox";
import { Typography } from "../../style";
import { deleteDoc } from "../../../../redux/actions/auth";
import { COLORS } from "../../../../constants/colorPallete";
import getPlatformType from "../../../../helpers/getPlatform";
import WideButton from "../../../../commonComponents/wideButton";
import CustomPopUp from "../../../../commonComponents/customPopUp/customPopUp";
import { documentPopup } from "../../../../redux/actions/statusPopup";

const ShareCode = (props) => {

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const layoutType = getPlatformType();
  const [docToRemove, setRemoveDoc] = useState("");
  const [documentArray, setDocuments] = useState([]);
  const [popUpIsVisible, setShowPopup] = useState(false);
  // const popUpIsVisible=useSelector(state=>state.statusPopupReducer.documentPopup)

  const deleteDocument = (data) => {
    dispatch(deleteDoc(data?.document_id));
    setRemoveDoc(null);
  };

  useEffect(() => {
    if (props?.allData) {
      setDocuments(props?.allData);
    }
  }, [props?.allData]);

  const DetailCard = ({ item }) => {
    let { filename, createdAt, file_path } = item;
    return (
      <View
        style={{
          padding: 10,
          paddingVertical: 20,
          borderBottomColor: "#D9D9D9",
          borderBottomWidth: 1,
          width: '100%',
          margin: layoutType == "phone" ? 0 : 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Image
              source={require("../../../../assets/images/uploadsuccess.png")}
              style={{
                width: 32,
                height: 32,
                resizeMode: "contain",
              }}
            />
            <Typography bold={"300"} color={"#686868"} top={4} size={11}>
              Uploaded
            </Typography>
          </View>
          <View style={{ width: "60%" }}>

            <TouchableOpacity
              onPress={() => {
                window.open(file_path)
              }}
            >
              <Typography
                underline={true}
                align={"left"}
                color={COLORS.LGREEN}
                size={"16px"}
                bold={"700"}
              >
                {filename}
              </Typography>
            </TouchableOpacity>
            <Typography top={"6px"} align={"left"} color={COLORS.BLACK} size={"14px"} bold={"400"}>
              {createdAt && moment(createdAt)
              .format("DD/MM/YYYY")}
            </Typography>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => {
                setRemoveDoc(item);
                setShowPopup(true);
                // dispatch(documentPopup(true))
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesome name="trash" size={24} color={COLORS.SAFFRON}/>
              <Typography bold={"300"} color={"#686868"} top={4} size={11}>
                Remove
              </Typography>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <View style={{ marginTop: 20, flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
          {
            documentArray && documentArray.length ?
              <SearchBox data={props.allData} result={(e) => setDocuments(e)} searchKey={"filename"}/>
            : 
            <Typography raj bold={"300"} top={"10px"} bottom={"30px"} color={"#1C1C1C"} size={"18px"} align={"center"}>
             Click "Add Documents" to upload new documents.
            </Typography>
          }
          {documentArray && documentArray.length
            ? documentArray.map((item, index) => {
              return <DetailCard item={item} index={index}/>;
            })
            : null}
          {/* <View style={{
            width: layoutType == "phone" ? "100%" : 353,
            justifyContent: "center",
            alignItems: "center",
            marginTop: layoutType != "web" ? 30 : 0,
          }}>
            <WideButton
              isLGreen
              id={"ADD-DOCS"}
              width="171px"
              gradiant
              borderRadius={15}
              childText="+ Add Document"
              onPress={() => navigation.navigate("Add Documents")}
            />
          </View> */}
        </View>
      </View>


      <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={"Sure"}
        secondaryButtonText={"cancel"}
        primaryText={"Remove Doc?"}
        secondaryText={" Are you sure you want to remove Doc?"}
        onPrimaryButtonPress={() => {
          setShowPopup(false);
          // dispatch(documentPopup(false))
          deleteDocument(docToRemove);
        }}
        onSecondaryButtonPress={() => {
          setShowPopup(false);
          // dispatch(documentPopup(false))
        }}
      />
    </View>
  );
};

export default ShareCode;
