import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { useTheme } from '@emotion/react';
import getScreenType from '../../../../utils/getScreenType';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import { ODSInput } from '../../../atoms/ODSInput';
import { ODSTextArea } from '../../../atoms/ODSTextArea';
import ODSButton from '../../../atoms/ODSButton';

const ContactUsCard = ({ onSend, data }) => {
  const { colors } = useTheme();
  const layout = getScreenType();

  const [form, setForm] = useState({
    // firstName: data.firstName + " " + data.lastName,
    // email: data.email,
    // subject: '',
    // message: '',
  });
  useEffect(() => {
    setForm({
      firstName: data.firstName + " " + data.lastName,
      email: data.email,
      subject: '',
      message: '',
    })
  }, [])

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const tempErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Helper function for validation
    const validateField = (condition, field, errorMessage) => {
      if (condition) {
        tempErrors[field] = errorMessage;
      }
    };
  
    // Field validations
    validateField(!form.firstName || form.firstName.length < 3, 'firstName', 
      'Name is required and must be at least 3 characters long.');
    validateField(!emailRegex.test(form.email), 'email', 
      'Please enter a valid email address.');
    validateField(!form.subject, 'subject', 
      'Subject is required.');
    validateField(!form.message || form.message.length < 10, 'message', 
      'Message is required and must be at least 10 characters long.');
  
    setErrors(tempErrors);
  
    // Form is valid if no errors exist
    return Object.keys(tempErrors).length === 0;
  };
  
  const handleSend = () => {
    if (validateForm()) {
      onSend(form);
      setForm({
        ...form,
        subject: '',
        message: '',
      });
    }
  };

  return (
    <View style={{ flex: 1, ...styles.shadowBox }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 32 }}>
        <ODSIcon icon={'email'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>Contact Us</ODSText>
      </View>
      <View style={{ flexDirection: layout === 'phone' ? 'column' : 'row', flex: 1 }}>
        <View style={{ flex: 1 }}>
          <ODSInput
            label='Name'
            placeholder='Name'
            editable={false}
            value={form.firstName}
            onChange={(value) => setForm({ ...form, firstName: value })}
            hints={errors.firstName ? [{ state: 'error', hint: errors.firstName }] : []}
          />
        </View>
        <View style={{ flex: 1, marginLeft: layout === 'phone' ? 0 : 12, marginTop: layout === 'phone' ? 12 : 0 }}>
          <ODSInput
            label='Email'
            placeholder='Email'
            value={form.email}
            editable={false}
            onChange={(value) => setForm({ ...form, email: value })}
            hints={errors.email ? [{ state: 'error', hint: errors.email }] : []}
          />
        </View>
      </View>
      <View style={{ height: 12 }} />
      <ODSInput
        label='Subject'
        placeholder='Subject'
        value={form.subject}
        onChange={(value) => setForm({ ...form, subject: value })}
        hints={errors.subject ? [{ state: 'error', hint: errors.subject }] : []}
      />
      <View style={{ height: 12 }} />
      <ODSTextArea
        // width={} 
        height={120}
        label='Message'
        placeholder='Type here'
        value={form.message}
        onChange={(value) => setForm({ ...form, message: value })}
        hints={errors.message ? [{ state: 'error', hint: errors.message }] : []}
      />
      <ODSButton restStyle={{ marginTop: 32, width: 200, alignSelf: 'flex-end' }} onPress={() => handleSend()}>
        Send
      </ODSButton>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default ContactUsCard;
