import React from "react";
import { Image,View } from "react-native";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import Wrapper from "./Wrapper";
import YoutubePlayer from '../../commonComponents/youtubePlayer/youtubePlayer';
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/home-web.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/home-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/home-mobile.png');
const Picture = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/tlyfepro.png');
const FooterWeb = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-web.png');
const FooterTab = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-tab.png');
const FooterMobile = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-mobile.png');
const clouds = require('../../../openbrix-design-system/images/tlyfe-pages/hangingCloud.png');
const mockVideo = require('../../../openbrix-design-system/images/tlyfe-pages/tlyfe-video-mock.png');
const home = require('../../../openbrix-design-system/images/icons/home.png');
const smily = require('../../../openbrix-design-system/images/icons/smily.png');
const pound = require('../../../openbrix-design-system/images/icons/pound.png');

const BenefitItem = ({ icon, title, description }) => (
  <View style={{ marginTop: 20, flexDirection: "row" }}>
    <Image style={{ height: 30, width: 30, marginRight: 10 }} resizeMode="contain" source={icon} />
    <View style={{ flexDirection: "column", flex: 1 }}>
      <ODSText type="button">{title}</ODSText>
      <ODSText type="sm" style={{ marginTop: 14 }}>{description}</ODSText>
    </View>
  </View>
);

const TlyfeproLandingHome = () => {
  const { colors } = useTheme();
  const layout = getScreenType();

  const headerImage = layout === "web" ? HeaderWeb : layout === "tab" ? HeaderTab : HeaderMobile;
  const footerImage = layout === "web" ? FooterWeb : layout === "tab" ? FooterTab : FooterMobile;

  const sectionStyles = layout === "phone"
    ? { flexDirection: "column", alignItems: "center", paddingHorizontal: "6%", marginBottom: 30 }
    : { flexDirection: "row", justifyContent: "center", paddingVertical: 100 };

  return (
    <Wrapper
      theme={colors.transparency.purple}
      drawerColor={colors.theme.purple}
      type="login"
      isOpenBrixLogo
      imageStyle={layout=="phone"? {height:800,justifyContent:"flex-start",paddingTop:100}:{height: 500,justifyContent:"center",paddingTop:20}}
      headerTitle="Let Smarter With Our Home Onboarding Service"
      headerBody="tlyfePRO is the industry leading tenant onboarding solution for letting agents, transforming the tenant onboarding process to enhance efficiency, reduce costs, and boost tenant satisfaction."
      headerImage={headerImage}
      headerBlacFont
      rightImage={Picture}
      leftText={`Give yourself the best chance of securing your next rental property by using our Pre-qualification tool to become a ‘Rent Ready’ Tenant.\n\nShare the results with your Agent to put yourself ahead of the crowd!!`}
      footerImage={footerImage}
      tlyfeProMenu
    >
      <View style={{ position: "relative" }}>
        <Image source={require("../../../openbrix-design-system/images/tlyfe-pages/clouds.png")}
          style={{ width: "100%", height: 100, position: "absolute", top: -97 }} />
        <View style={sectionStyles}>
          <Image
            style={layout === "phone" ? { height: 100, width: 100 } : { height: 154, width: 154, marginRight: "2.2%" }}
            resizeMode="contain"
            source={Picture}
          />
          <View style={{ marginLeft: "2.2%" }}>
            <ODSText type="h3" color={colors.main.blue["500"]} textAlign={layout !== "phone" ? "flex-start" : "center"}>Key Benefits</ODSText>
            <BenefitItem icon={home} title="Streamlined Onboarding" description="Simplify and accelerate every step of the tenant onboarding process." />
            <BenefitItem icon={pound} title="Cost Reduction" description="Lower your operational costs with automated solutions." />
            <BenefitItem icon={smily} title="Increased Satisfaction" description="Provide a seamless experience that keeps tenants happy." />
          </View>
        </View>
        <View style={layout === "phone" ?
          { ...sectionStyles, paddingTop: 20, backgroundColor: colors.main.blue["500"], justifyContent: "center", alignItems: "center", flexDirection: "column-reverse" } :
          { ...sectionStyles, backgroundColor: colors.main.blue["500"], height: 422, justifyContent: "center", alignItems: "center" }}>
          {layout == "phone" ? null : <>
            <Image
              style={layout === "phone" ? { height: 100, width: 73, position: "absolute", top: 0, right: 20 } : { height: 100, width: 73, position: "absolute", top: 0, right: 20 }}
              source={clouds}
            />
            <Image
              style={layout === "phone" ? { height: 52, width: 41, position: "absolute", top: 0, left: 40 } : { height: 52, width: 41, position: "absolute", top: 0, left: 40 }}
              source={clouds}
            />
          </>}

          <View style={{ marginLeft: layout === "phone" ? "0%" : "2%", position: "relative" }}>
            <Image
              style={layout === "phone" ? { height: 241, width: 360, bottom: -30 } : { height: 422, width: 638, marginRight: "2%", bottom: -90 }}
              resizeMode="contain"
              source={mockVideo}
            />
          </View>
          <View style={layout === "phone" ? {} : { marginLeft: "2%", width: "31%" }}>
            <ODSText color="white" type="h3">Seamless Integration</ODSText>
            <ODSText color="white" marginTop={10} type="sm">tlyfePRO integrates effortlessly with tlyfe and existing CRM solutions, offering a complete solution that improves the rental experience for both tenants andagents.</ODSText>
          </View>
        </View>
        <View style={layout === "phone" ? { ...sectionStyles,  height: 260 } : { ...sectionStyles, marginTop: 100, height: 687 }}>
          <View style={{ marginLeft: "2%", width: 840 }}>

          </View>
        </View>
        <View style={layout === "phone" ?
          { ...sectionStyles, height: 142, paddingTop: 20, justifyContent: "center", alignItems: "center", flexDirection: "column-reverse", backgroundColor: colors.main.purple["400"], position: "relative", marginBottom: 0 } :
          { ...sectionStyles, flexDirection: "column", height: 322, justifyContent: "center", alignItems: "center", backgroundColor: colors.main.purple["400"], position: "relative", padding: layout == "tab" ? "10%" : "web" ? "16%" : "10%" }}>
          <View style={layout === "phone" ?{top: -147, width: "100%" }: { top: -360, width: "100%" }}>
            {layout === "phone" ?
              <>
                <ODSText type="h3" color={"black"} textAlign={layout !== "phone" ? "center" : "center"}>Transform Your Process</ODSText>
                <ODSText type="sm" color={"black"} textAlign={layout !== "phone" ? "center" : "center"} marginTop={10} marginBottom={20}>
                  Experience the future of property management with tlyfePRO.  Enhance yourefficiency, reduce costs, and ensure tenant  satisfaction, all through one powerfulplatform.
                </ODSText>
              </>

              : <>
                <ODSText type="h3" color={"black"} textAlign={layout !== "phone" ? "center" : "center"}>Transform Your Process</ODSText>
                <ODSText type="sm" color={"black"} textAlign={layout !== "phone" ? "center" : "center"} marginTop={20}>

                </ODSText>
                <ODSText type="sm" color={"black"}
                  marginTop={5}
                  textAlign={layout !== "phone" ? "center" : "center"}>
                  Enhance yourefficiency, reduce costs, and ensure tenant
                </ODSText>
                <ODSText
                  marginTop={5}
                  marginBottom={40}
                  type="sm" color={"black"} textAlign={layout !== "phone" ? "center" : "center"}>
                  satisfaction, all through one powerfulplatform.
                </ODSText>
              </>}


            <View style={layout == "phone" ? { height: 200, borderRadius: 20, paddingVertical: 20, paddingHorizontal: 10, backgroundColor: "black" } : { height: 600, borderRadius: 20, paddingVertical: 20, paddingHorizontal: 10, backgroundColor: "black" }}>
              <YoutubePlayer
                url={'https://www.youtube.com/watch?v=4T0oKyMI6wI&ab_channel=OpenBrix'}
                width="100%"
                height="100%"
                style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8, overflow: 'hidden' }}
              />

            </View>
          </View>
        </View>
      </View>
    </Wrapper>
  );
};

export default TlyfeproLandingHome;
